import MainStackLayout from '../layout/MainStackLayout';
import {
  LFLStockKey,
  ResumeFlowDataKey,
  deliveryAddressKey,
  deliveryTypeKey,
  processIncidentKey,
  serviceFeeKey,
  storeAddressKey,
  submitRequestKey,
} from '../modules/config/constants';
import { useCentralStore } from '../store/appContext';
import { clearSessionData, isBatteryReplacement, isDeviceRefresh, isDeviceRepair, isWalkIn, timeConvert24to12Hr, getDateAndDay, isMalfunction, isSwap, isReplacement, isDelivery as isDeliveryType, isPUR, getTime, isDeviceRefreshFree, isInWarranty, isInWarrantyOEM, isScreenRepair, isScreenRepairBatReplacement, isScreenRepairWarranty, isEWSClaim, getGTMData, getRedirectUrl } from '../utils/utills';
import {
  ConfirmationAfterSubmission,
  getTextScript,
  getTypeByDomain,
  useSessionStorage,
  useTextScript,
  getCarrierByDomain,
  useAccount,
  AccountInfo,
  Mixpanel
} from '@MGPD/myasurion-shared';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ClaimConfirmationScripts from '../Scripts/asurion-script.json';
import translationsKeys from '../Scripts/translationsKeys.json';
import { getCurrencySymbol, getGlobalDateFormat, getPerilText, getSwapLBL } from '../utils/localization-helper';
import { getFeeInclusiveTaxText } from '../utils/helper';
import { getReplaceScript, hasMultiDeviceSupport, hasSurveyFeature, isBackOrderPayFirst, isJBUpgradeFlow } from '../utils/featuresUtills';
import { GTM_EVENT, INCIDENT_TYPES } from '../utils/constant';
import { getOnlineSession, logGTMEvent } from '../services/api';
import ActionTypes from '../store/actionTypes';
import { findAgreementByMdn } from '../../services/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { loadingMessages, loadingWaitingMessages } from '../../modules/config/constants';
import Geocode from 'react-geocode';
import { checkIsApplLoadDisabled } from '../../utils/config';
import { JBConfirmationAfterSubmission } from './JBConfirmationAfterSubmission';

function ClaimConfirmation() {
  const globalState = useCentralStore();

  const { isTranslationsLoading, language } = useTextScript();

  const isTOM = globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === "Asurion_TechCare"
  const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode;
  const navigate = useNavigate();

  const agreementData1: any = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

  // const [account] = useAccount({ firstname: 'firstName', lastname: 'lastName', email: 'email' });
  const commonScripts = ClaimConfirmationScripts?.ClaimConfirmation;
  const swapScripts = ClaimConfirmationScripts?.ClaimConfirmation?.swap;
  const jbSwapScripts = ClaimConfirmationScripts?.ClaimConfirmation?.jbSwap;
  const isRepairScripts = ClaimConfirmationScripts?.ClaimConfirmation?.IsRepair;
  const BackOrderScripts = ClaimConfirmationScripts?.ClaimConfirmation?.IsBackorder
  const [LFLStock] = useSessionStorage<any>(LFLStockKey, null);
  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
  const isBackOrder = isBackOrderPayFirst(agreementData1?.ClientOffer?.ClientOfferName) && (R_ServiceRequests?.ServiceOrder?.ServiceOrderStatus?.toUpperCase() === 'BORD' || LFLStock === false);
  const R_IncidentType = R_ServiceRequests?.IncidentType;
  const R_IMEI = R_ServiceRequests?.EnrolledDevice?.IMEI;
  const [ServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
  const LFLSRFFees =
    ServiceFeeData &&
    ServiceFeeData?.filter((item: any) => item?.IncidentType === R_IncidentType?.toUpperCase())?.[0]
      ?.Fees?.TotalAmount;

  const [assetData] = useSessionStorage<any>('assetData', null);
  const [deliveryAddress] = useSessionStorage<any>(deliveryAddressKey, null);
  const [deliveryDate] = useSessionStorage<any>('deliveryDate', null);
  const [deliveryTime] = useSessionStorage<any>('deliveryTime', null);
   const [srDeliveryType] = useSessionStorage<any>(deliveryTypeKey, null);
  const [subheadingContent2Script, setSubheadingContent2Script] = useState('')
  const [walkingDate] = useSessionStorage<any>('storeDate', null);
  const [walkingTime] = useSessionStorage<any>('storeTime', null);
  const isDelivery = isDeliveryType(srDeliveryType);
  const isPickup = isPUR(srDeliveryType);
  const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
  const [deliveryAddLbl, setDeliveryAddressLbl] = useState<string>();
  const [deliveryDateTimeLbl, setDeliveryDateTimeLbl] = useState<string>();
  const [returnAddLbl, setReturnAddLbl] = useState<string>();
  const [returnDateTimeLbl, setReturnDateTimeLbl] = useState<string>();
  const [ , setPaymentBreakdownlbl] = useState<string>();
  const [subheadingContent1, setSubheadingContent1] = useState<string>();
  const [SRFLBLText,setSRFLBLText] = useState('')
  const [RepairSRFText, setRepairSRFtext] = useState('');

  const [PerilType, setPerilType] = useState('');
  const [FulFillmentOptionType, setFulFillmentOptionType] = useState('');
  const [SwapLBL, setSwapLBL] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [dateFormat, setDateFormat] = useState<string>('');

  const [walkingAddressarray] = useSessionStorage<any>(storeAddressKey, null);
  const [NighDeliverytFees] = useSessionStorage<any>('NightDeliveryFee', null);
  const walkingAddress = walkingAddressarray; //&& walkingAddressarray[0];

  const [, setLoadingMessage] = useState(
    loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
);
  const disableApplLoad = checkIsApplLoadDisabled(getCarrierByDomain()) //useFeatureIsOn('disableApplLoad');
  const [account, ] = useAccount<AccountInfo>({ profileId: '', userId: '' });
  const [sessionData, ] = useSessionStorage<any>('data', '');

  const R_SRF =
    ResumeFlowData?.ServiceFee?.ServiceFee &&
    parseFloat(ResumeFlowData?.ServiceFee?.ServiceFee)?.toFixed(2);
  const [totalSRFee, setTotalSRFee] = useState<any>(globalState?.state?.srf || R_SRF);

  useEffect(() => {
    setTotalSRFee(
      (
        Number(globalState?.state?.srf || R_SRF || LFLSRFFees) +
        Number(globalState?.state?.nightDeliveryFee) +
        Number(globalState?.state?.doorStepAddOnData.fee)
      )?.toFixed(2)
    );
  }, [
    globalState?.state?.nightDeliveryFee,
    R_SRF,
    LFLSRFFees,
    globalState?.state?.doorStepAddOnData?.fee,
  ]);

  const [SRSubmit, setSRSubmit] = useState('Yes');
  const [, setSRSubmits] = useSessionStorage<any>(submitRequestKey, null);
  const [ProcessIncidentResponse] = useSessionStorage<any>(processIncidentKey, null);
 
  const find_agreement = globalState?.state?.currentAgreementData;
  const returnDate = globalState?.state?.returnDate;
  const returnTime = globalState?.state?.returnTime;
  const clientOfferName = find_agreement?.ClientOffer?.ClientOfferName;
  const isUpgradeFlow = isJBUpgradeFlow(clientOfferName);

  const hasDTAddOnAcquired = false;

  useEffect(() => {
    setSRSubmit('Yes');
    setSRSubmits(SRSubmit);
    const GTMData = getGTMData(GTM_EVENT.CLAIM_CONFIRMATION_SCREEN_EVENT, globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0], 
      getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
      logGTMEvent(GTMData)
  }, []);

  // const SelectedDate = isDelivery ? deliveryDate : walkingDate;
  // const Time = isDelivery ? deliveryTime  : walkingTime;
  // const day = getDateAndDay(SelectedDate);
  // const DateTime = day.concat(' ', Time + '.');

  const incType = serviceRequestData?.ServiceRequest?.IncidentType || R_IncidentType;
  const PageName = 'ClaimConfirmation';

  const isWarranty =
    isInWarranty(ProcessIncidentResponse) ||
    isEWSClaim(serviceRequestData, incType) ||
    isDeviceRefreshFree(find_agreement, incType)
      ? true
      : false;

  useEffect(() => {
    if(!isTranslationsLoading){
      getDateFormat();
      getCurrency();
      getSRFLBL();
      getRepairSRFtext();
      getPerilLbl();
      getSwapLbl();
      getFullFillmentOption();    
      getSubheadingContent2Script();  
    }
  }, [language, isTranslationsLoading])

  useEffect(() => {
    if(PerilType && PerilType?.length > 0){
      getDeliveryAddLabel();
      getDeliveryDateTimeLabel();
      getReturnAddLabel();
      getReturnDateTimeLabel();
      getpaymentBreakdownlblText();
    }
  }, [PerilType]);

  useEffect(() => {
    getSubheadingcontent1()
  }, [SwapLBL, PerilType]);

  const getDateFormat = async () => {
    const format = await getGlobalDateFormat();
    setDateFormat(format);
  }

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  }

  const getPerilLbl = async () => {
    const incidentType = isInWarrantyOEM(serviceRequestData) ? INCIDENT_TYPES.MALFUNCTION_IW : isEWSClaim(serviceRequestData, incType) ? INCIDENT_TYPES.MALFUNCTION_EWS : incType;
    const defaultIncidentTypeLBL = isInWarrantyOEM(serviceRequestData) ? ClaimConfirmationScripts.Global.MalfunctionIW : isEWSClaim(serviceRequestData, incType) ? ClaimConfirmationScripts.Global.MalfunctionEWS : incType;

    const peril = await getPerilText(incidentType, defaultIncidentTypeLBL);
    setPerilType(peril);
  }

  const getFullFillmentOption = async () => {
    const fulFillmentType = await getPerilText(srDeliveryType?.toUpperCase(), srDeliveryType);
    setFulFillmentOptionType(fulFillmentType);
  };

  const getSwapLbl = async () => {
    const swapLBL = await getSwapLBL();
    setSwapLBL(swapLBL);
  }

  const getDeliveryAddLabel = async () => {
    if (isSwapReplacement() && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType)) {
      if (isDelivery) {
        const returnedText: string = await getTextScript(
          `${PageName}/deliveryAdd`,
          commonScripts?.DeliveryAddress,
          language,
          []
        );
        setDeliveryAddressLbl(returnedText);
      } else {
        const returnedText: string = await getTextScript(
          `${PageName}/walkInAdd`,
          commonScripts?.CollectionAddressSwap,
          language,
          []
        );
        setDeliveryAddressLbl(returnedText);
      }
    } else {
      if (isPickup) {
        const returnedText: string = await getTextScript(
          `${translationsKeys.Global.PageName}/${translationsKeys.Global.PickUpAddress}`,
          ClaimConfirmationScripts.Global?.PickUpAddress,
          language,
          []
        );
        setDeliveryAddressLbl(returnedText);
      } else {
        const returnedText: string = await getTextScript(
          `${PageName}/walkInRepairAdd`,
          'Repair Centre Location',
          language,
          []
        );
        setDeliveryAddressLbl(returnedText);
      }
    }
  };

  const getDeliveryDateTimeLabel = async () => {
    if (isSwapReplacement() && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType)) {
      if (isDelivery) {
        let returnedText: string;
        if(isBackOrder || isUpgradeFlow){
          returnedText = ''
        }else{
        returnedText = await getTextScript(
          `${PageName}/deliveryDateTime`,
          commonScripts?.DeliveryDateTime,
          language,
          []
        );}
        setDeliveryDateTimeLbl(returnedText);
      } else {
        const returnedText: string = await getTextScript(
          `${PageName}/appointmentDate`,
          commonScripts?.CollectionDateTime,
          language,
          []
        );
        setDeliveryDateTimeLbl(returnedText);
      }
    } else {
      if (isPickup) {
        const returnedText: string = await getTextScript(
          `${translationsKeys.Global.PageName}/${translationsKeys.Global.PickUpDateTime}`,
          ClaimConfirmationScripts.Global.PickUpDateTime,
          language,
          []
        );
        setDeliveryDateTimeLbl(returnedText);
      } else {
        const returnedText: string = await getTextScript(
          `${PageName}/walkInRepairDateTime`,
          commonScripts?.CollectionDateTime,
          language,
          []
        );
        setDeliveryDateTimeLbl(returnedText);
      }
    }
  };

  const getReturnAddLabel = async () => {
    if (isPickup && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType)) {
      const returnedText: string = await getTextScript(
        `${PageName}/${translationsKeys.Global.ReturnAddress}`,
        ClaimConfirmationScripts.Global.ReturnAddress,
        language,
        []
      );
      setReturnAddLbl(returnedText);
    } else {
      return;
    }
  };

  const getReturnDateTimeLabel = async () => {
    if (isPickup && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType)) {
      const returnedText: string = await getTextScript(
        `${translationsKeys.Global.PageName}/${translationsKeys.Global.ReturnDateTime}`,
        ClaimConfirmationScripts.Global.ReturnDateTime,
        language,
        []
      );
      setReturnDateTimeLbl(returnedText);
    } else {
      return;
    }
  };

  const isRepairFlow = () => {
    if (
      isDeviceRefresh(incType) ||
      isDeviceRepair(incType) ||
      isBatteryReplacement(incType) ||
      isScreenRepair(incType) ||
      isScreenRepairBatReplacement(incType) ||
      isScreenRepairWarranty(incType) ||
      (isMalfunction(incType) && isInWarrantyOEM(serviceRequestData)) ||
      isEWSClaim(serviceRequestData, incType)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isSwapReplacement = () => {
    if (isSwap(incType) || isMalfunction(incType) || isReplacement(incType)) {
      return true;
    } else {
      return false;
    }
  };

  const getSubheadingScript1 = async (key:any, defaultText:any) => {
    const script = await getTextScript(
      `${PageName}/Subheadingcontent1/${key}`,
      defaultText,
      language,
      []
    );
    setSubheadingContent1(script);
   }

  const getSubheadingScriptSwapReplacement1 = async (key: any, defaultText: any, value: any) => {
    const script = await getTextScript(
      `${PageName}/Subheadingcontent1/${key}`,
      defaultText,
      language,
      [value]);
    setSubheadingContent1(script);
  }


  const getSubheadingcontent1 = () => {
    if (isRepairFlow() && !isWalkIn(srDeliveryType)) {
     const subheadingContent = isRepairScripts?.subheadingcontent12;
      getSubheadingScript1('text1', subheadingContent);
    } else if (
      (isSwap(incType) || isReplacement(incType)) &&
      !isWalkIn(srDeliveryType)
    ) {
      if (isBackOrder) {
        const subheadingContent = BackOrderScripts?.subheadingcontent1;
        getSubheadingScript1('backOrder', subheadingContent);
      } else {
        const subheadingContent = swapScripts?.subheadingcontent1;
        (PerilType && getSubheadingScriptSwapReplacement1('swapReplacementText2', subheadingContent, PerilType));
      }
    } else if (
      isWalkIn(srDeliveryType) &&
      !isSwap(incType) &&
      !isReplacement(incType)
    ) {
      const subheadingContent = '';
      getSubheadingScript1('text3', subheadingContent);
    }
  };

  const getSubheadingContent2Script = async () => {
    let script = '';
    if (isRepairFlow() && !isWalkIn(srDeliveryType)) {
      script = await getTextScript(
        `${translationsKeys.ConfirmationAfterSubmission.PageName}/${translationsKeys.ConfirmationAfterSubmission.lblSubheadingContent1}`,
        isRepairScripts?.subheadingcontent1,
        language,
        []);  
    } else if(isWarranty || isWalkIn(srDeliveryType)){
      script = '';
    } else {
      if(isReplacement(incType) && getReplaceScript(agreementData1?.ClientOffer?.ClientOfferName)){
        script = await getTextScript(
          `${translationsKeys.ConfirmationAfterSubmission.PageName}/${translationsKeys.ConfirmationAfterSubmission.lblSubheadingContent3}`,
          ClaimConfirmationScripts?.ClaimConfirmation?.Replacement?.subheadingcontent2,
          language,
          []);
      }
      else{
        script = await getTextScript(
          `${translationsKeys.ConfirmationAfterSubmission.PageName}/${translationsKeys.ConfirmationAfterSubmission.lblSubheadingContent2}`,
          swapScripts?.subheadingcontent2,
          language,
          []);
      }
        
    }    
    setSubheadingContent2Script(script);
  }

  const getSubheadingcontent2 = () => {
    if (isRepairFlow() && !isWalkIn(srDeliveryType)) {
      return isRepairScripts?.subheadingcontent1;
    } else if(isWarranty || isWalkIn(srDeliveryType)){
      return ''
    }else {
      if(isReplacement(incType) && getReplaceScript(agreementData1?.ClientOffer?.ClientOfferName)){
        return ClaimConfirmationScripts?.ClaimConfirmation?.Replacement?.subheadingcontent2
      }
      else return swapScripts?.subheadingcontent2;
    }
  };

  const getSubheading1 = () => {
    if (isRepairFlow() && !isWalkIn(srDeliveryType)) {
      return isRepairScripts?.subheading1;
    } else if (isWalkIn(srDeliveryType)) {
      return '';
    }else {
      return swapScripts?.subheading1;
    }
  };

  const getSubheading2 = () => {
    if (isRepairFlow()) {
      return isRepairScripts?.subheading2;
    } else {
      if(isReplacement(incType)) return '';
      return swapScripts?.subheading2;
    }
  };

  const getSubheading2content1 = () => {
    if (!isReplacement(incType)) {
      return isRepairScripts?.subheading2content1;
     } else {
       return '';
     }
  };

  const getSubheading2content2 = () => {
     if (!isReplacement(incType) && !hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) && assetData?.Make?.Name?.toUpperCase() === 'APPLE') {
      return swapScripts?.subheading2content1;
     } else {
       return '';
     }
  };

  

  const getSubheading3content1 = () => {
    if (isRepairFlow()) {
      return isRepairScripts?.subheading3content1;
    } else {
      if(isReplacement(incType)) return ''
      else return swapScripts?.subheading3content1;
    }
  };

  const getlblrate = () => {
    if (isRepairFlow()) {
      return isRepairScripts?.lblrate;
    } else {
      return swapScripts?.lblrate;
    }
  };

  const getSRFLBL = async () => {
    const SRFLBL = await getTextScript( 
      `${translationsKeys.Global.PageName}/${translationsKeys.Global.SRFLBL}`,
      ClaimConfirmationScripts.Global.SRFLBL,
      language,
      []
      );
    setSRFLBLText(SRFLBL);
  }

  const getRepairSRFtext = async () => {
    const PlaceHolderText = await getTextScript(
    `${translationsKeys.Global.PageName}/${translationsKeys.Global.RepairFeeLBL}`,
    ClaimConfirmationScripts.Global.RepairFeeLBL,
    language,
    []);
    setRepairSRFtext(PlaceHolderText);
  };

  const getServiceRequestFeeText = () => {
    if(isSwapReplacement() || isDeviceRefresh(incType))
      return SRFLBLText;
    else if (isUpgradeFlow)
      return ClaimConfirmationScripts.Global.DeviceNonReturnFee
    else
      return RepairSRFText;
  };

  const getpaymentBreakdownlblText = async() =>  {
    const returnedText: string = await getTextScript(
      `${translationsKeys.Global.PageName}/${translationsKeys.Payment.paymentBreakdown}/${getCurrencySymbol()}`,
      `${ClaimConfirmationScripts.Payment?.paymentBreakdown} ${getFeeInclusiveTaxText(currencyCode)}`,
      language,
      []
    );
    setPaymentBreakdownlbl(returnedText);
  }

  const getDeliveryaddressvalue = () => {
    if (isSwapReplacement() && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType)) {
      return isDelivery
        ? `${
            deliveryAddress.Address1 ? deliveryAddress.Address1 + ', ' : ''
          }${
            deliveryAddress.Address2 ? deliveryAddress.Address2 + ', ' : ''
          }${
            deliveryAddress.Address3 ? deliveryAddress.Address3 + ', ' : ''
          }${
            deliveryAddress.StateProvinceCode ? deliveryAddress.StateProvinceCode + ', ' : ''
          }${deliveryAddress.PostalCode || ''}`
        : `${
            walkingAddress?.RepairStoreName ? walkingAddress.RepairStoreName + ', ' : ''
          }${
            walkingAddress?.AddressLine1 ? walkingAddress.AddressLine1 + ', ' : ''
          }${
            walkingAddress?.City ? walkingAddress.City + ', ' : ''
          }${walkingAddress?.PostalCode || ''}`;
    } else {
      return isPickup
        ? globalState?.state?.pickUpAddress
        : isDelivery
        ? `${
            deliveryAddress.Address1 ? deliveryAddress.Address1 + ', ' : ''
          }${
            deliveryAddress.Address2 ? deliveryAddress.Address2 + ', ' : ''
          }${
            deliveryAddress.Address3 ? deliveryAddress.Address3 + ', ' : ''
          }${
            deliveryAddress.StateProvinceCode ? deliveryAddress.StateProvinceCode + ', ' : ''
          }${deliveryAddress.PostalCode || ''}`
        : `${
            walkingAddress?.RepairStoreName ? walkingAddress.RepairStoreName + ', ' : ''
          }${
            walkingAddress?.AddressLine1 ? walkingAddress.AddressLine1 + ', ' : ''
          }${
            walkingAddress?.City ? walkingAddress.City + ', ' : ''
          }${walkingAddress?.PostalCode || ''}`;
    }
  };

  // const getDeliveryDateTimelbl = () => {
  //   if (isSwapReplacement()) {
  //     return isDelivery ? commonScripts?.DeliveryDateTime : commonScripts?.CollectionDateTime;
  //   } else {
  //     return isPickup
  //       ? isRepairScripts?.PickupDateTimeLBL
  //       : isDelivery
  //       ? 'Delivery Date/Time'
  //       : 'Appointment Date/Time';
  //   }
  // };

  const getDeliveryDateTimevalue = () => {
    if (isSwapReplacement() && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType)) {
      return isDelivery
        ? isBackOrder ? ''
        : `${getDateAndDay(deliveryDate, dateFormat)} ${isTOM ? getTime(timeConvert24to12Hr(deliveryTime)) :(globalState?.state?.isMaxis && (isSwap(incType) || isMalfunction(incType))) ? 'By 8pm' : deliveryTime} `
        : `${getDateAndDay(walkingDate, dateFormat)} ${getTime(walkingTime)} `;
    } else {
      return isPickup
        ? `${getDateAndDay(globalState?.state?.pickupDate, dateFormat)} ${isTOM ? getTime(timeConvert24to12Hr(globalState?.state?.pickupTime)) : globalState?.state?.pickupTime}`
        : isDelivery
        ? isUpgradeFlow ? '':`${getDateAndDay(deliveryDate, dateFormat)} ${getTime(deliveryTime)} `
        : `${getDateAndDay(walkingDate, dateFormat)} ${getTime(walkingTime)} `;
    }
  };

  const getDate = () => {
    if ((isSwapReplacement() || isWarranty) && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType)) {
      return `${getDateAndDay(deliveryDate, dateFormat)} ${deliveryDate} ${isTOM ? getTime(deliveryTime) : deliveryTime} `;
    } else if (isRepairFlow()) {
      if (isPickup) {
        `${getDateAndDay(globalState?.state?.pickupDate, dateFormat)} ${isTOM ? getTime(timeConvert24to12Hr(globalState?.state?.pickupTime)) : globalState?.state?.pickupTime}`
      } else {
        return `${getDateAndDay(walkingDate, dateFormat)} ${getTime(walkingTime)} `;
      }
    }
  };

  // const pickup = `${getDateAndDay(globalState?.state?.pickupDate)} ${globalState?.state?.pickupTime}`

  const callFindAgreement = () => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: true });
    fetchAgreementByMdn({
      mdn: globalState?.state?.selectedEnrolledDevice?.mdn || account?.enrolledMdn || '',
      email: globalState?.state?.selectedEnrolledDevice?.emailAddress ?? account?.email ?? '',
      data: sessionData,
      imei: '',
      idx: 0,
      assetId: '',
      isDeviceData: true
  })
  }

  const { mutate: fetchAgreementByMdn } = useMutation({
    mutationFn: findAgreementByMdn,
    retry: 5,
    retryDelay: (failureCount) => {
        if (failureCount === 3) {
            setLoadingMessage(
                loadingWaitingMessages[
                    Math.floor(Math.random() * loadingWaitingMessages.length)
                ]
            );
        }
        return Math.min(1000 * 2 ** failureCount, 30000);
    },
    onSuccess: (data) => {
        if(disableApplLoad) {
            // let deviceList: any = [];
            // deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'REPLACED')
      
            // if (!deviceList || deviceList === undefined || deviceList.length === 0) {
            // deviceList = data?.deviceData?.filter((a: any) => a?.type?.toLowerCase() !== 'byod' && a?.assetInstance?.toUpperCase() === 'ENROLLED')
            // }

            // deviceList = [...new Map(deviceList?.map((item: any) =>
            //   [item['mdn'], item])).values()]

            let deviceList1 = new Map()
                data?.deviceData?.forEach((v: any) => {
                    let prevValue = deviceList1.get(v.mdn)
                    if(!prevValue || prevValue.type === "ENROLLED"){
                        deviceList1.set(v.mdn, v)
                    }
                })
             let deviceList = [...deviceList1.values()]

            globalState?.dispatch({
                type: ActionTypes.SET_DEVICES_LIST,
                payload: deviceList || data?.deviceData,
            });

            globalState?.dispatch({
                type: ActionTypes.SET_FINDAGREEMENT_BY_MDN_DATA,
                payload: data,
            });
            globalState?.dispatch({
              type: ActionTypes.SET_FINDAGREEMENT_SESSION_DATA,
              payload: data.data,
            });
        }
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
        navigate(getRedirectUrl(getTypeByDomain()))
    },
    onSettled: () => {
        fetchOnlineSession();
    },
    onError: (error) => {
      globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
        console.error('Findagreement failed', (error as { message: string })?.message);
        // doLogout('findAgreementByMdn');
    },
});

const { refetch: fetchOnlineSession } = useQuery(
  ['sessionData'],
  async () => {
      return await getOnlineSession();
  },
  {
      retry: 5,
      retryDelay: (failureCount, error) => {
          if (failureCount === 3) {
              setLoadingMessage(
                  loadingWaitingMessages[
                      Math.floor(Math.random() * loadingWaitingMessages.length)
                  ]
              );
          }
          Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
              userId: account.userId,
              error: error,
          });
          return Math.min(1000 * 2 ** failureCount, 30000);
      },
      onSuccess: (data) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
          Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Success', {
              userId: account.userId,
          });
  
            globalState?.dispatch({
              type: ActionTypes.SET_SESSION_RESPONSE,
              payload: data,
            });
            const isMaxis = data.InitializeResponse?.ClientName?.toUpperCase() === "MAXIS"
            globalState?.dispatch({
              type: ActionTypes.SET_IS_MAXIS_FLAG,
              payload: isMaxis,
            });
            
            const btMethods = data?.InitializeResponse?.isBraintree?.method
            
            globalState?.dispatch({
              type: ActionTypes.SET_BRAINTREE_METHODS,
              payload: btMethods,
            });
            
      
            const agreementData = data?.FindAgreementsResults?.Agreements?.Agreement?.[0]
            const address1 = agreementData?.Address?.Address1;
            const address2 = agreementData?.Address?.Address2;
            const city = agreementData?.Address?.City;
            const _postalCode = agreementData?.Address?.PostalCode;
            const addressjoin = { address1, address2, city, _postalCode };
            const fulladdress = Object.values(addressjoin).join(', ');
      
            Geocode.setApiKey(import.meta.env.VITE_GOOGLE_API_KEY);    
            Geocode.setLanguage('en');
            Geocode.setRegion('SGP');
            Geocode.setLocationType('ROOFTOP');
      
            Geocode.fromAddress(fulladdress).then(
              (response) => {
                const { lat, lng } = response?.results?.[0]?.geometry?.location;
                globalState?.dispatch({
                  type: ActionTypes.SET_LOCATION_COORDINATES,
                  payload: { lat, lng },
                });
              },
              (error) => {
                console.error(error);
              }
            );
          // setAgreementData(data?.FindAgreementsResults?.Agreements?.Agreement?.[0])
      },
      onError: (error) => {
        globalState?.dispatch({ type: ActionTypes.LOADING, payload: false });
          // console.error(error);
          Mixpanel.sendMixpanelEvent('ASMA_Portal_LandingPage_FetchOnlineSession_Error', {
              userId: account.userId,
              error,
          });
          // setPageLoading(true);
          // setIsFailedApiInit(true);
          // doLogout('getOnlineSession');
      },
      enabled: false,
  }
);

  return (
    <Box id={'myasurion-claim-approve'} paddingTop={'64px'} mt={5}>
      <MainStackLayout gap={0} spacing={0}>
        <Box
          margin={'auto'}
          id={'myasurion-claim-confirmation'}
          w={'full'}
          h={'auto'}
          p={'0px 16px'}
        >
          {dateFormat && dateFormat?.length > 0 && !isUpgradeFlow &&
            <ConfirmationAfterSubmission
              LFLStock={true}
              isWarranty={isWarranty}
              isWalkin={isDelivery ? false : true}
              title={commonScripts?.YourRequestisApproved}
              heading={`${commonScripts?.YourRequestID}`}
              requestId={`${
                ProcessIncidentResponse?.CustomerCaseNumber || ResumeFlowData?.CustomerCaseNumber
              }`}
              // text1={
              //   isSwapReplacement() ? swapScripts?.text1?.replace('{email}', account?.email) : ''
              // }
              subheading1={getSubheading1()}
              // DateTime={isSwapReplacement()? DateTime: pickup}
              DateTime={getDeliveryDateTimevalue()}
              subheadingcontent1={subheadingContent1 || ''}
              Date={getDate()}
              subheadingcontent2={subheadingContent2Script || getSubheadingcontent2()}
              subheading2={getSubheading2()}
              subheading2content1={getSubheading2content1()}
              subheading2content2= {getSubheading2content2()}
              subheading3={isReplacement(incType) ? '' : swapScripts?.subheading3}
              make ={
                (!hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) && 
                assetData?.Make?.Name?.toUpperCase() === 'APPLE') ? 
                  true : 
                  false
              }
              // subheading3={isSwapReplacement() ? swapScripts?.subheading3 : ''}
              subheading3content1={getSubheading3content1()}
              info1={swapScripts?.info1}
              info1content={swapScripts?.info1content}
              info2={swapScripts?.info2}
              info2content={swapScripts?.info2content}
              info3={swapScripts?.info3}
              info3content={swapScripts?.info3content}
              searchTitle={swapScripts?.searchTitle}
              lblhowtofind={swapScripts?.lblhowtofind}
              lblrate={getlblrate()}
              lblclose={ClaimConfirmationScripts.Global.CloseThisPage}
              // IncidentType={IncidentType?.toUpperCase()}
              IncidentType={PerilType?.toUpperCase()}
              serviceRequestdetails={commonScripts?.serviceRequestdetails}
              Summarylbl={commonScripts?.Summarylbl}
              paymentBreakdown={commonScripts?.paymentBreakdown}
              serviceRequestFee={getServiceRequestFeeText()}
              serviceRequestFeeAmount={`${currencySymbol}${globalState?.state.srf || R_SRF || LFLSRFFees}`}
              nightDelivery={NighDeliverytFees ? commonScripts?.NightDelivery : ''}
              nightDeliveryAmount={NighDeliverytFees ? currencySymbol + NighDeliverytFees : ''}
              dataTransferFeesLBL={hasDTAddOnAcquired ? commonScripts?.DataTransferFee : ''}
              dataTransferFeesVal={
                hasDTAddOnAcquired ? currencySymbol + globalState?.state?.doorStepAddOnData?.fee : undefined
              }
              subTotal={ClaimConfirmationScripts?.Global.Subtotal}
              subTotalAmount={`${currencySymbol}${totalSRFee}`}
              Discount=""
              DiscountAmount=""
              TotalAfterDiscount={ClaimConfirmationScripts?.Global.Total}
              TotalAmountAfterDiscount={`${currencySymbol}${totalSRFee}`}
              RequestedServicelbl={ClaimConfirmationScripts?.Global?.RequestedService}
              RequestedServicevalue={PerilType?.toLowerCase() === INCIDENT_TYPES.FAULT_REPAIR?.toLowerCase() ? INCIDENT_TYPES.DEVICE_REPAIR : PerilType}
              IMEIlbl={commonScripts?.IMEIlbl}
              IMEIvalue={assetData?.IMEI || R_IMEI}
              RequestTypelbl={ClaimConfirmationScripts?.Global?.RequestType}
              RequestTypevalue={FulFillmentOptionType || srDeliveryType}
              DeliveryAddresslbl={deliveryAddLbl}
              Deliveryaddressvalue={getDeliveryaddressvalue()}
              DeliveryDateTimelbl={deliveryDateTimeLbl}
              DeliveryDateTimevalue={getDeliveryDateTimevalue()}
              rateButtonProps={{
                label: commonScripts?.Rate,
                onClick: () => {
                  navigate('/npsrating', { replace: true });
                },
              }}
              closeButtonProps={{
                label: ClaimConfirmationScripts?.Global.Close,
                onClick: () => {
                  clearSessionData(true, false);
                  // window.location.href = getRedirectUrl(getTypeByDomain());
                  callFindAgreement()
                  // navigate(getRedirectUrl(getTypeByDomain()))
                },
              }}
              howToFindButtonProps={{
                label: swapScripts?.FMIP,
                onClick: () => {
                  navigate('/fmipinstructions', { replace: true });
                },
              }}
              returnAddrsLBL={returnAddLbl}
              returnAddrsVal={(isPickup && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType)) ? globalState?.state?.returnAddress : ''}
              returnDateTimeLBL={(returnDate || returnTime) && returnDateTimeLbl}
              returnDateTimeVal={
                (isPickup && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType) && returnDate && returnTime)
                  ? `${getDateAndDay(returnDate, dateFormat)} ${isTOM ? getTime(timeConvert24to12Hr(returnTime)) : returnTime}`
                  : ''
              }
              showSurvey={hasSurveyFeature(agreementData1?.ClientOffer?.ClientOfferName)}
              alternativeContactNumber={globalState?.state?.alternateContactNumber}
            ></ConfirmationAfterSubmission>
          }
          {dateFormat && dateFormat?.length > 0 && isUpgradeFlow &&
            <JBConfirmationAfterSubmission
              LFLStock={true}
              isWarranty={isWarranty}
              isWalkin={isDelivery ? false : true}
              title={commonScripts?.YourRequestisApproved}
              heading={`${commonScripts?.YourRequestID}`}
              requestId={`${
                ProcessIncidentResponse?.CustomerCaseNumber || ResumeFlowData?.CustomerCaseNumber
              }`}
              // text1={
              //   isSwapReplacement() ? swapScripts?.text1?.replace('{email}', account?.email) : ''
              // }
              subheading1={getSubheading1()}
              // DateTime={isSwapReplacement()? DateTime: pickup}
              DateTime={getDeliveryDateTimevalue()}
              subheadingcontent1={subheadingContent1 || ''}
              Date={getDate()}
              subheadingcontent2={subheadingContent2Script || getSubheadingcontent2()}
              subheading2={getSubheading2()}
              subheading2content1={getSubheading2content1()}
              subheading2content2= {getSubheading2content2()}
              subheading3={isReplacement(incType) ? '' : jbSwapScripts?.subheading3}
              subheading3generateLabel={jbSwapScripts?.subheading3generateLabel}
              make ={
                (!hasMultiDeviceSupport(agreementData1?.ClientOffer?.ClientOfferName) && 
                assetData?.Make?.Name?.toUpperCase() === 'APPLE') ? 
                  true : 
                  false
              }
              // subheading3={isSwapReplacement() ? swapScripts?.subheading3 : ''}
              subheading3content1={getSubheading3content1()}
              info1={swapScripts?.info1}
              info1content={swapScripts?.info1content}
              info2={swapScripts?.info2}
              info2content={swapScripts?.info2content}
              info3={swapScripts?.info3}
              info3content={swapScripts?.info3content}
              searchTitle={swapScripts?.searchTitle}
              lblhowtofind={swapScripts?.lblhowtofind}
              lblrate={getlblrate()}
              lblclose={ClaimConfirmationScripts.Global.CloseThisPage}
              // IncidentType={IncidentType?.toUpperCase()}
              IncidentType={PerilType?.toUpperCase()}
              serviceRequestdetails={commonScripts?.serviceRequestdetails}
              Summarylbl={commonScripts?.Summarylbl}
              paymentBreakdown={commonScripts?.paymentBreakdown}
              serviceRequestFee={getServiceRequestFeeText()}
              serviceRequestFeeAmount={`${currencySymbol}${globalState?.state.srf || R_SRF || LFLSRFFees}`}
              nightDelivery={NighDeliverytFees ? commonScripts?.NightDelivery : ''}
              nightDeliveryAmount={NighDeliverytFees ? currencySymbol + NighDeliverytFees : ''}
              dataTransferFeesLBL={hasDTAddOnAcquired ? commonScripts?.DataTransferFee : ''}
              dataTransferFeesVal={
                hasDTAddOnAcquired ? currencySymbol + globalState?.state?.doorStepAddOnData?.fee : undefined
              }
              subTotal={ClaimConfirmationScripts?.Global.Subtotal}
              subTotalAmount={`${currencySymbol}${totalSRFee}`}
              Discount=""
              DiscountAmount=""
              TotalAfterDiscount={ClaimConfirmationScripts?.Global.Total}
              TotalAmountAfterDiscount={`${currencySymbol}${totalSRFee}`}
              RequestedServicelbl={ClaimConfirmationScripts?.Global?.RequestedService}
              RequestedServicevalue={PerilType?.toLowerCase() === INCIDENT_TYPES.FAULT_REPAIR?.toLowerCase() ? INCIDENT_TYPES.DEVICE_REPAIR : PerilType}
              IMEIlbl={commonScripts?.IMEIlbl}
              IMEIvalue={assetData?.IMEI || R_IMEI}
              RequestTypelbl={ClaimConfirmationScripts?.Global?.RequestType}
              RequestTypevalue={FulFillmentOptionType || srDeliveryType}
              DeliveryAddresslbl={deliveryAddLbl}
              Deliveryaddressvalue={getDeliveryaddressvalue()}
              DeliveryDateTimelbl={deliveryDateTimeLbl}
              DeliveryDateTimevalue={getDeliveryDateTimevalue()}
              rateButtonProps={{
                label: commonScripts?.Rate,
                onClick: () => {
                  navigate('/npsrating', { replace: true });
                },
              }}
              closeButtonProps={{
                label: ClaimConfirmationScripts?.Global.Close,
                onClick: () => {
                  clearSessionData(true, false);
                  // window.location.href = getRedirectUrl(getTypeByDomain());
                  callFindAgreement()
                  // navigate(getRedirectUrl(getTypeByDomain()))
                },
              }}
              howToFindButtonProps={{
                label: swapScripts?.FMIP,
                onClick: () => {
                  navigate('/fmipinstructions', { replace: true });
                },
              }}
              returnAddrsLBL={returnAddLbl}
              returnAddrsVal={(isPickup && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType)) ? globalState?.state?.returnAddress : ''}
              returnDateTimeLBL={(returnDate || returnTime) && returnDateTimeLbl}
              returnDateTimeVal={
                (isPickup && !isInWarrantyOEM(serviceRequestData) && !isEWSClaim(serviceRequestData, incType) && returnDate && returnTime)
                  ? `${getDateAndDay(returnDate, dateFormat)} ${isTOM ? getTime(timeConvert24to12Hr(returnTime)) : returnTime}`
                  : ''
              }
              showSurvey={hasSurveyFeature(agreementData1?.ClientOffer?.ClientOfferName)}
              alternativeContactNumber={globalState?.state?.alternateContactNumber}
            ></JBConfirmationAfterSubmission>
          }
        </Box>
      </MainStackLayout>
    </Box>
  );
}

export default ClaimConfirmation;
