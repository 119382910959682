import useContentfulImage from '../../hooks/useContentfulImage';

export const getLabels = (theme: any) => {
    const {
        hero: { banner, bannerSlug, bannerMobileSlug, bannerMobile },
        serviceRequest: {
            refresh,
            replace,
            swap,
            phoneVertical,
            upgrade,
            screenRepairBatteryReplacement,
            malfunction,
            ews,
            pickupAndReturn,
        },
        planInclusions: { dataTransfer, planDelivery, techSpecialist },
        planRenewal,
        recommendations: {
            antiVirusDesktop,
            antiVirusDesktopSlug,
            antiVirusMobile,
            antiVirusMobileSlug,
        },
        techTips: { smartPlug, ai, iphones },
        feedback: { icon },
        components: {
            articleCardTag: { featured, standard },
        },
    }: Record<string, any> = theme;
    const antiVirusDesktopImgUrl = useContentfulImage(antiVirusDesktopSlug);
    const antiVirusMobileImgUrl = useContentfulImage(antiVirusMobileSlug);
    return {
        subheader: {
            title: 'Welcome to your',
            plans: [
                { value: 'SmartSupportPortal', label: 'SmartSupport Portal' },
                { value: 'deviceCare', label: 'Device Care' },
                { value: 'smartSupport', label: 'Smart Support' },
                { value: 'smartSupportHome', label: 'Smart Support Home' },
            ],
        },
        hero: {
            title: 'No matter what happens, your device is cared for.',
            description: 'Submit, manage and track your service request.',
            buttonText: 'View plan details',
            banner: banner,
            bannerSlug: bannerSlug,
            bannerMobileSlug: bannerMobileSlug,
            bannerMobile: bannerMobile,
        },
        exclusiveOffer: {
            title: 'Exclusive for you',
        },
        serviceRequest: {
            title: 'SmartSupport service request',
            requestTypes: [
                {
                    image: swap,
                    id: 'swap',
                    title: 'Swap',
                    tooltipTitle: "What's included?",
                    tooltipBody:
                        "Something wrong with your device? Give us your current device and we'll give you an as-good-as new swap.",
                },
                {
                    image: replace,
                    id: 'replace',
                    title: 'Replace',
                    tooltipTitle: "What's included?",
                    tooltipBody:
                        "Have you lost your phone? Has it been stolen? We'll provide you a replacement and you can go about your day like nothing has happened.",
                },
                {
                    image: refresh,
                    id: 'refresh',
                    title: 'Refresh',
                    tooltipTitle: "What's included?",
                    tooltipBody:
                        "Let us give your device a makeover. We'll fix up the cracks, give it a polish and it'll be back to you, good as new.",
                    // 'Device Refresh offers a replacement of screen, detailed cleaning, diagnostic service, operating system refresh plus provision of cleaning solution kit and a charging cable.',
                },
                {
                    image: upgrade,
                    id: 'upgrade',
                    title: 'Upgrade',
                    tooltipTitle: "What's included?",
                    tooltipBody: 'Upgrade your device',
                },
                {
                    image: ews,
                    id: 'ews',
                    title: 'Extended Warranty',
                    tooltipTitle: 'title',
                    tooltipBody: 'body',
                },
                {
                    image: pickupAndReturn,
                    id: 'ews-home',
                    perilPath: 'ews',
                    title: 'Pickup and Return',
                    tooltipTitle: 'Pickup and Return',
                    tooltipBody: 'Pickup and Return',
                },
                {
                    image: pickupAndReturn,
                    id: 'oem-home',
                    perilPath: 'malfunction',
                    title: 'Pickup and Return',
                    tooltipTitle: 'Pickup and Return',
                    tooltipBody: 'Pickup and Return',
                },
            ],
        },
        valueAddedServices: {
            title: 'Available for this device',
            services: [
                {
                    image: refresh,
                    id: 'screen-repair',
                    title: 'Screen Repair',
                    tooltipTitle: "What's included?",
                    description:
                        'Let us know what happened and we’ll help you as fast as possible.',
                    tooltipBody:
                        "If your screen is cracked, your phone might be hard to use and that's not why you spent so much on your device. Let us help you change your screen with OEM approved parts and say goodbye to tilting your phone to read or even finger cuts.",
                    plan: ['ss3'],
                    linkText: 'Start a claim',
                    linkType: 'button',
                    linkProps: {
                        colorScheme: 'primary',
                        width: 'max-content',
                        height: 'max-content',
                        padding: '12px 20px',
                        marginTop: '16px',
                        marginBottom: '24px',
                    },
                },
                {
                    image: phoneVertical,
                    id: 'device-repair',
                    title: 'Device Repair',
                    description:
                        'Let us know what happened and we’ll help you as fast as possible.',
                    tooltipTitle: "What's included?",
                    tooltipBody:
                        "Issues with your phone but not sure why? Software, Hardware issues? We'll diagnose and repair the issues that we can.",
                    plan: ['ss4'],
                    linkText: 'Start a claim',
                    linkType: 'button',
                    linkProps: {
                        colorScheme: 'primary',
                        width: 'max-content',
                        height: 'max-content',
                        padding: '12px 20px',
                        marginTop: '16px',
                        marginBottom: '24px',
                    },
                },
                {
                    image: phoneVertical,
                    id: 'battery-replacement',
                    title: 'Battery Replacement',
                    description:
                        'Let us know what happened and we’ll help you as fast as possible.',
                    tooltipTitle: "What's included?",
                    tooltipBody:
                        "Battery doesn't seem to last as long as it did, and you constantly find yourself needing a charger? Your battery health might not be at its optimum. We'll take care of that and get your battery health back to what it used to be.",
                    plan: ['ss3', 'ss4'],
                    linkText: 'Start a claim',
                    linkType: 'button',
                    linkProps: {
                        colorScheme: 'primary',
                        width: 'max-content',
                        height: 'max-content',
                        padding: '12px 20px',
                        marginTop: '16px',
                        marginBottom: '24px',
                    },
                },
                {
                    image: screenRepairBatteryReplacement,
                    id: 'screen-repair-&-battery-replacement',
                    title: 'Bat Scr Replacement',
                    description:
                        'Let us know what happened and we’ll help you as fast as possible.',
                    tooltipTitle: "What's included?",
                    tooltipBody:
                        'Get both screen repair and battery replacement altogether under one single request.',
                    // "Battery doesn't seem to last as long as it did, and you constantly find yourself needing a charger? Your battery health might not be at its optimum. We'll take care of that and get your battery health back to what it used to be.",
                    plan: ['ss3'],
                    linkText: 'Start a claim',
                    linkType: 'button',
                    linkProps: {
                        colorScheme: 'primary',
                        width: 'max-content',
                        height: 'max-content',
                        padding: '12px 20px',
                        marginTop: '16px',
                        marginBottom: '24px',
                    },
                },
                {
                    image: malfunction,
                    id: 'malfunction',
                    title: 'Cleaning Services',
                    tooltipTitle: 'Cleaning Services',
                    tooltipBody: 'Cleaning Services',
                },
            ],
        },
        planInclusions: {
            title: 'Included in your plan',
            linkText: 'Plan details',
            articles: [
                {
                    image: dataTransfer,
                    tag: 'Exclusive promotion',
                    tagProps: featured,
                    title: 'Transfer data between devices',
                    description: 'Switching devices? We’ll take care of the data transfer for you.',
                    linkText: 'Learn more',
                    link: '',
                },
                {
                    image: planDelivery,
                    tag: 'Speedy service',
                    tagProps: standard,
                    title: '2-hour doorstep delivery',
                    description:
                        'Sit back and relax - your replacement device will be delivered to you within 2 hours, right to your door.',
                    linkText: 'Learn more',
                    link: '',
                },
                {
                    image: techSpecialist,
                    tag: 'Available 9am-6pm',
                    tagProps: standard,
                    title: 'Transfer data between devices',
                    description: 'Switching devices? We’ll take care of the data transfer for you.',
                    linkText: 'Learn more',
                    link: '',
                },
            ],
        },
        digitalSecurityScore: {
            loaderText: 'Fetching your digital score...',
            title: 'Your digital security score',
            titleBreach: 'Data Breach Monitoring',
            noscore: 'No score recorded',
            noscoreDescription:
                'Find out how digitally secure you are by taking this quick self-assessment',
            breachAlert: 'We found COUNT data breaches that need your attention',
            breachNote: 'What’s exposed on dark web:',
            notRegisteredNote:
                'Please confirm the email address that you would like to register for Security Advisor. ',
            notRegisteredTitleTip: 'Which email should I use?',
            noRegisteredTooltipContent:
                'You may change the email address to one that is commonly used for your online accounts. This email address will be used for Data Breach Monitoring and cannot be changed later.',
            heading: 'Top Recommendation',
            buttonView: 'View full report',
            buttonTakeAssessment: 'Take assessment',
            buttonSeeAlerts: 'See all alerts',
            buttonRegister: 'Register',
        },
        recommendations: {
            title: 'Recommended for this device',
            articles: [
                {
                    imageDesktop: antiVirusDesktopImgUrl.imageUrl ?? antiVirusDesktop,
                    imageMobile: antiVirusMobileImgUrl.imageUrl ?? antiVirusMobile,
                    size: 'lg',
                    title: 'Exclusive promo: 30% off antivirus + free installation',
                    titleProps: { fontSize: '24px' },
                    description:
                        'Data security woes? Fret not! Grab this exclusive subscriber-only deal and let our Tech Experts guide you through setup.',
                    linkText: 'Subscribe now',
                    linkType: 'button',
                    linkProps: {
                        colorScheme: 'primary',
                        width: '163px',
                        height: '48px',
                        padding: '12px 20px',
                        marginTop: '16px',
                        marginBottom: '24px',
                    },
                    link: '',
                },
            ],
        },
        techTips: {
            title: 'Expert tech tips',
            linkText: 'More tech tips',
            articles: [
                {
                    image: iphones,
                    category: 'Apple',
                    title: 'iPhones will be able to speak in your voice with 15 minutes of training',
                    titleProps: { fontSize: '20px' },
                    linkText: 'Learn more',
                    link: '',
                },
                {
                    image: ai,
                    category: 'AI',
                    title: 'Big Tech is already warning us about AI privacy problems',
                    titleProps: { fontSize: '20px' },
                    linkText: 'Learn more',
                    link: '',
                },
                {
                    image: smartPlug,
                    category: 'Security',
                    title: 'PSA: time to recycle your old Wemo smart plugs (if you haven’t already)',
                    titleProps: { fontSize: '20px' },
                    linkText: 'Learn more',
                    link: '',
                },
            ],
        },
        feedback: {
            title: 'Tell us what you think',
            description: 'Help us improve the site by sharing your experience.',
            buttonText: 'Get started',
            buttonLink: '',
            image: icon,
        },
        planDetailsPage: {
            overviewTitle: 'Everything you need to know',
        },
        planRenewal: {
            title: 'Renew your plan',
            articles: [
                {
                    imageDesktop: planRenewal?.planRenewalDesktop ?? null,
                    imageMobile: planRenewal?.planRenewalMobile ?? null,
                    size: 'md',
                    title: 'Up to 20% off when you renew your plan for a 12-month term',
                    titleProps: { fontSize: '24px' },
                    description:
                        'Enjoy unlimited swaps and access to repair services when you renew your plan.',
                    linkText: 'Renew now',
                    linkType: 'link',
                    linkProps: {
                        colorScheme: 'primary',
                        width: '163px',
                        height: '48px',
                        padding: '12px 20px',
                        marginTop: '16px',
                        marginBottom: '24px',
                    },
                    link: 'swap/renewal',
                },
            ],
        },
    };
};

export const planStorageKey = 'pd';
export const smartScanTokenUrlKey = 'ssUrl';
export const deviceSelectedMdnKey = 'dsMdn';
export const gadgetCategoryName = 'gadgetCategoryName'
export const deviceRegDataKey = 'deviceRegData'

export const loadingMessages = [
    'Please wait a moment while we prepare the page for you...',
    'Loading your content, just a few more seconds...',
    "Hold on, we're fetching the latest updates for you...",
    "Sit tight, we're getting everything ready for your visit...",
    'Loading in progress - your patience is appreciated!',
    "We're working hard to load your page, thank you for waiting.",
    "Page loading... Grab a coffee and we'll be ready shortly",
    "Hang on tight, we're almost there with your content...",
    "Just a moment longer, we're loading your personalized experience.",
    'Loading... Your journey through our website is about to begin.',
];

export const loadingWaitingMessages = [
    "Your future is so bright; it's taking a moment to load. Hang tight!",
    "We're working diligently behind the scenes to make your visit extra special",
    'Elevating your online experience takes a little time - thanks for waiting!',
];

export const reloginMessages = [
    "Oops! Something went wrong. Let's give it another shot, shall we?",
    'We hit a bump in the digital highway. Ready to try that login again?',
    "Looks like our spaceship hit a meteor. Hit that login button and we'll soar again!",
];

export const assets: { [key: string]: any } = {
    asurion: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/asurion/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/asurion/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    starhub: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/starhub/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/starhub/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    jbhifi: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/jbhifi/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/jbhifi/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    true: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/true/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/true/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    techcare: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/techcare/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/techcare/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    asurion_techcare: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/techcare/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/techcare/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    celcom: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/celcom/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/celcom/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    umobile: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/umobile/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/umobile/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    lazada: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/lazada/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/lazada/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    smart: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/smart/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/smart/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    maxis: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/maxis/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/maxis/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
    cht_home: {
        sidebar: {
            items: [
                {
                    code: 'plan-overview',
                    name: 'Plan overview',
                    url: 'plan',
                    icon: '/images/carriers/cht_home/portal-home.png',
                    iconSlug: 'global-house-favicon',
                },
                {
                    code: 'my-service-requests',
                    name: 'My service requests',
                    url: '/swap/myplansandservicerequests',
                    icon: '/images/carriers/cht_home/portal-preference.png',
                    iconSlug: 'global-preference-favicon',
                },
            ],
        },
    },
};

export const programsMap: Record<
    string,
    { programName: string; clientFacingName: string; planDetailCarouselCards: string[] }
> = {
    'SmartSupport 4.0': {
        programName: 'SS 4.0',
        clientFacingName: 'SmartSupport',
        planDetailCarouselCards: ['swap', 'delivery', 'tech', 'requests', 'secure', 'upgrade'],
    },
    'SmartSupport 3.0': {
        programName: 'SS 3.0',
        clientFacingName: 'SmartSupport',
        planDetailCarouselCards: ['swap', 'tech', 'requests', 'secure'],
    },
    'SmartSupport Lite': {
        programName: 'SS Lite',
        clientFacingName: 'SmartSupport Lite',
        planDetailCarouselCards: ['swap', 'tech', 'requests'],
    },
    'SmartSupport 2.1': {
        programName: 'SS 2.1',
        clientFacingName: 'SmartSupport',
        planDetailCarouselCards: ['swap', 'tech', 'requests'],
    },
    'SmartSupport 2.0': {
        programName: 'SS 2.0',
        clientFacingName: 'SmartSupport',
        planDetailCarouselCards: ['swap', 'tech', 'requests'],
    },
    'SmartSupport Plus': {
        programName: 'SS Plus',
        clientFacingName: 'SmartSupport Plus',
        planDetailCarouselCards: ['swap', 'tech', 'requests'],
    },
    'SmartSupport Basic': {
        programName: 'SS Basic',
        clientFacingName: 'SmartSupport Basic',
        planDetailCarouselCards: ['swap', 'requests'],
    },
    'JBHIFI': {
        programName: 'JBHIFI',
        clientFacingName: 'JB HI-FI',
        planDetailCarouselCards: [],
    },
};

export const carrierMap: Record<string, string> = {
    "JBHIFI": "jbhifi",
    "STARHUB" : "starhub"
}
