// import { Spinner } from '@chakra-ui/react';
import { useCentralStore } from "../store/appContext";
import { Text } from "@chakra-ui/layout";
import PaymentKeys from '../Scripts/translationsKeys.json'
import { TextScript, useConfig } from '@MGPD/myasurion-shared'

const Loader = () => {
  const globalState = useCentralStore();
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { bannerGradient } = colors;

  const wave_gradient: any = {
    background: bannerGradient
  }

  return (
    <>
      <div className="loaderOverlay">
        <div className="loader">
          {/* <Spinner/> */}
          <div className="waveLoader">
            <div className="wave" style={wave_gradient}></div>
            <div className="wave" style={wave_gradient}></div>
            <div className="wave" style={wave_gradient}></div>
            <div className="wave" style={wave_gradient}></div>
            <div className="wave" style={wave_gradient}></div>
            <div className="wave" style={wave_gradient}></div>
            <div className="wave" style={wave_gradient}></div>
            <div className="wave" style={wave_gradient}></div>
            <div className="wave" style={wave_gradient}></div>
            <div className="wave" style={wave_gradient}></div>
          </div>
          {globalState?.state?.isPaymentMessage && <Text w={'full'} fontSize={'20px'} fontWeight={'bold'}>
                  <TextScript
                        text={'Processing your payment, please do not close window'}
                        translationKey={`${PaymentKeys.Payment.PageName}/${PaymentKeys.Payment.paymentInProgress}`}
                    />
                    </Text>}
        </div>
      </div>
    </>
  );
};

export default Loader;
