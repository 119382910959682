import { useScrollBlock } from '@MGPD/myasurion-shared/hooks';
import { Chat, ChatButton } from '@MGPD/myasurion-shared/modules/chat';
import { Box, useBoolean, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface ChatOverlayProps {
    question: string;
    onChatClose: () => void;
}

const ChatOverlay = ({ question, onChatClose }: ChatOverlayProps) => {
    const pathName = window.location.pathname
    const removeChatIcon = pathName.includes('survey')
    const fixChatIcon = pathName?.includes('payment') || pathName?.includes('terms') || pathName?.includes('datatransfer') || pathName?.includes('reviewsubmission') || pathName?.includes('deviceselection')

    const [refresh, setRefresh] = useState<number>(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isMinimized, setToMinimize] = useBoolean();
    const [blockScroll, allowScroll] = useScrollBlock();
    const isMobile = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        if (isMobile && isOpen && !isMinimized) {
            blockScroll();
        } else {
            allowScroll();
        }
    }, [isMobile, isOpen, isMinimized]);

    useEffect(() => {
        if (question) onOpen();
    }, [question]);

    return (
        <>
            <Box
                display={isOpen ? 'initial' : 'none'}
                position="fixed"
                w={{ base: 'full', lg: '350px' }}
                h={{ base: 'full', xs: '800px' }}
                mt={{ base: 'unset', lg: '30px' }}
                top="56px"
                bottom={{ base: 0, lg: 8 }}
                right={{ base: 0, lg: 8 }}
                zIndex="9999"
                borderRadius="lg"
                boxShadow="lg"
                visibility={isMinimized ? 'hidden' : 'visible'}
                bg="white"
            >
                <Chat
                    key={refresh}
                    onMinimize={() => {
                        setToMinimize.toggle();
                    }}
                    onClose={() => {
                        onClose();
                        onChatClose();
                        setRefresh(Math.random());
                    }}
                    isLoggedIn={true}
                    quickQuestion={question}
                    isChatOpen={isOpen}
                />
            </Box>

            {!removeChatIcon &&
                <ChatButton
                    id="onboarding__chat"
                    cursor="pointer"
                    onClick={isMinimized ? setToMinimize.toggle : onOpen}
                    styleProps={{
                        position: 'fixed',
                        bottom: fixChatIcon ? '80px' : 8,
                        right: fixChatIcon ? '20px' : 8,
                        zIndex: '1300', // Less than chakra drawer zIndex
                        visibility: isMinimized || !isOpen ? 'visible' : 'hidden',
                    }}
                    boxShadow={'0px 16px 8px 0px rgba(0, 0, 0, 0.08)'}
                    borderRadius="80%"
                />
            }
        </>
    );
};

export default ChatOverlay;
