/* eslint-disable no-console */
import { ConfirmDeviceRegistration } from '@MGPD/myasurion-shared';
import { Stack } from '@chakra-ui/react';

import highfive from '../../../public/images/MiniHighFive.svg';

export const ConfirmRegistration = () => {
    const onCancelClick = () => {
        window.location.href = window.location.origin;
    };

    return (
        <Stack
            h={'full'}
            w={'full'}
            minW={'320px'}
            // direction={{ base: 'column', md: 'row' }}
            // spacing={{ base: 0, md: 8 }}
            alignItems="center"
            id="auth-container"
            rowGap={{ base: 4, md: 8 }}
        >
            <ConfirmDeviceRegistration
                highfive={highfive}
                onCancelClick={onCancelClick}
                pageName={'ConfirmDeviceRegistration'}
            ></ConfirmDeviceRegistration>
        </Stack>
    );
};
