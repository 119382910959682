/* eslint-disable no-console */
import Scripts from '../Scripts/asurion-script.json';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import { CustomerSupportFooter, SubHeader, TextScript, VideoPlayer, getTextScript, useConfig } from '@MGPD/myasurion-shared';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Highlight,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Feedback from './Feedback';
import CancelSrPopup from './myclaims/CancelSrPopup';
import translationsKeys from '../Scripts/translationsKeys.json'
import { CONTENTFUL_SLUG } from '../utils/constant';
import useContentfulImage from '../hooks/useContentfulImage';
import { getCurrencySymbol, getProgrammeName, getSwapLBL } from '../utils/localization-helper';
import expertLeft from '/images/carriers/asurion/expert-default-left.png';
import expertMid from '/images/carriers/asurion/expert-default-mid.png';
import expertRight from '/images/carriers/asurion/expert-default-right.png';
import expertDesktop from '/images/carriers/asurion/expert-desktop.png';
import ChatOverlay from './ChatOverlay';

interface NoteContent {
  title: string;
  content: string;
}

interface FeedbackProps {
  title: string;
  description: string;
}

interface FeeContentProps {
  highlightText: string;
  type: string;
  feeContent1: string;
}

interface DevicePrepareContent {
  title: string;
  subtitle: string;
  steps: Array<DevicePrepareSteps>;
}

interface DevicePrepareSteps {
  index: string;
  content: string;
}

interface DataTransferGuideContent {
  title: string;
  subtitle: string;
  steps: Array<DataTransferGuideSteps>;
}

interface DataTransferGuideSteps {
  title: string;
  content: string;
  type: string;
  highlightText: string;
}

interface DiyVsDataTransferContent {
  title: string;
  subtitle: string;
  infoContent: string;
  subtitle1: string;
  info: Array<Info>;
}

interface Info {
  infoSequence: string;
  infoTitle: string;
  infoContent: string;
}

const noteContent = Scripts.DoorStepDataTransfer.noteContent as NoteContent;
const feedbackProps = Scripts.DoorStepDataTransfer.feedback as FeedbackProps;
const feeContentProps = Scripts.DoorStepDataTransfer.feeContentProps as FeeContentProps;
const devicePrepareContent = Scripts.DoorStepDataTransfer
  .devicePrepareContent as DevicePrepareContent;
const dataTransferGuideSteps = Scripts.DoorStepDataTransfer
  .dataTransferGuideContent as DataTransferGuideContent;
const diyVsDataTransferContent = Scripts.DoorStepDataTransfer
  .diyVsDataTransferContent as DiyVsDataTransferContent;

export const DataTransferGuide = () => {
  const globalState = useCentralStore();
  const navigate = useNavigate();

  const DoorStepDataTransferKeys = translationsKeys.DoorStepDataTransfer;

  const [isChecked, setIsChecked] = useState(false);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [dataTransferInfoContent, setDataTransferInfoContent] = useState('');
  const [feeContent, setFeeContent] = useState('');
  const [diyVsDataTransferFeeContents, setDiyVsDataTransferFeeContents] = useState<Info[]>([]);
  const [SwapLBL, setSwapLBL] = useState('')
  const [currencySymbol, setCurrencySymbol] = useState('$')
  const [programmeNameLBL, setProgrammeNameLBL] = useState('')
  const [question, setQuestion] = useState<string>('');

  const agreementData1: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  // const assetData = agreementData1?.Assets?.Asset?.filter(
  //   (a: any) => a?.AssetInstance === 'ENROLLED'
  // )?.[0];

  let assetData =  agreementData1?.Assets?.Asset?.filter(
    (asset: any) => asset?.AssetInstance === 'ENROLLED'
  )?.[0];
    if(assetData === undefined) {
      assetData = agreementData1?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'REPLACED'
      )?.[0];
    }

  const isApple = assetData?.Make?.Name?.toLowerCase() === 'apple';

  const videoLink = useContentfulImage(isApple ? CONTENTFUL_SLUG.APPLE_VIDEO : CONTENTFUL_SLUG.ANDROID_VIDEO);

  useEffect(() => {
    getDataTransferInfoContent();
    getFeeContent();
    loadDiyVsDataTransferContent();
  }, [SwapLBL]);

  useEffect(() => {
    getProgrammeNameLbl();
    getCurrency();
    getSwapLbl();    
  }, [])

  const getProgrammeNameLbl = async () => {
    const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName);
    setProgrammeNameLBL(programmeName);
  }

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  }

  const getSwapLbl = async () => {
    const swapLBL = await getSwapLBL();
    setSwapLBL(swapLBL);
  }

  const getDataTransferInfoContent = async () => {
    const returnedText = await getTextScript(
      `${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.diyVsDataTransferContent.KeyDiyInfoContent}`,
      diyVsDataTransferContent.infoContent,
      'en-US',
      [SwapLBL]
    );

    setDataTransferInfoContent(returnedText);
  };

  const getFeeContent = async () => {
    const returnedText = await getTextScript(
      `${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.feeContent.KeyFeeContent}`,
      feeContentProps.feeContent1
    );

    setFeeContent(returnedText);
  };

  const loadDiyVsDataTransferContent = async () => {

    const feeContents = await Promise.all(diyVsDataTransferContent.info.map( async (val: Info, index) => {
      const returnedText = await getTextScript(
        `${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.diyVsDataTransferContent.KeyInfoContent}${index}`,
        val.infoContent,
        'en-US',
        [SwapLBL, currencySymbol]
      );
      val.infoContent = returnedText;
      return val;
    }))

    setDiyVsDataTransferFeeContents(feeContents);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const onNextClick = () => {
    if (isChecked) {
      // todo API call to set door step add on
    }

    // save door stpe addon
    globalState?.dispatch({
      type: ActionTypes.SET_DOOR_STEP_DATA_TRANSFER_ADD_ON,
      payload: { hasAddOnAcquired: isChecked, fee: isChecked ? '0' : '0' },
    }); //todo remove hard coded fee

    // navigate to deliveryoptions
    navigate('/deliveryoptions', { replace: true });
  };

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  };

  const startChat = () => {
    globalState?.dispatch({
      type: ActionTypes.OPEN_CHAT_WINDOW,
      payload: true,
    }); 
  }

  const isChatButton = (highlightText: string) => {
    return highlightText === 'Start a chat';
  }

  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { primary } = colors;

  return (
    <Box pt={'4rem'} pb={'11rem'} maxWidth={{ base: 'full', lg: '80%' }} marginX={'auto'}>
      <SubHeader
        backText={'Back'}
        allowBackButtonClick={true}
        showCancelButton={true}
        showBackButton={false}
        cancelText={Scripts.CancelSR.cancelBtnText}
        onCancelClick={onCancelClick}
      ></SubHeader>
      <Box px={'1rem'}>
        <Text fontSize="3xl">
          <TextScript
            text={Scripts.DoorStepDataTransfer.header}
            translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.KeyHeader}`}
          />
        </Text>

        <Box w={'full'} h={'auto'} p={'16px 0px'}>
          <Text fontSize="xl">
            <TextScript
              text={noteContent.title}
              translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.noteContent.KetTitle}`}
            />
          </Text>
          <Text mt={'1rem'}>
            <TextScript
              text={noteContent.content}
              translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.noteContent.KeyContent}`}
            />
          </Text>
        </Box>

        <Box w={'full'} h={'auto'} p={'16px 0px'}>
          <Text fontSize="xl">
            <TextScript
              text={devicePrepareContent.title}
              translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.devicePrepareContent.KeyTitle}`}
            />
          </Text>
          <Text mt={'1rem'}>
            <TextScript
              text={devicePrepareContent.subtitle}
              translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.devicePrepareContent.KeySubtitle}`}
            />
          </Text>
          {devicePrepareContent.steps.map((val, index) => (
            <Flex>
              <Text as={'b'}>
                <TextScript
                  text={val.index}
                  translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.devicePrepareContent.KeyStepsIndex}${index}`}
                />
              </Text>
              <Text ml={'8px'}>
                <TextScript
                  text={val.content}
                  translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.devicePrepareContent.KeyStepsContent}${index}`}
                />
              </Text>
            </Flex>
          ))}
        </Box>

        <Box w={'full'} h={'auto'} p={'16px 0px'}>
          <Text fontSize="xl">
            <TextScript
              text={dataTransferGuideSteps.title}
              translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.dataTransferGuideContent.KeyTitle}`}
            />
          </Text>
          {dataTransferGuideSteps.steps.map((val, index) => (
            <Flex direction={'column'} my={'1rem'}>
              <Text as={'b'}>
                <TextScript
                  text={val.title}
                  translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.dataTransferGuideContent.KeyStepsTitle}${index}`}
                />
              </Text>
              {val.type === 'video' ? (
                <Box>
                  <Highlight
                    query={val.highlightText}
                    styles={{ textDecoration: 'underline', mb: '4px' }}
                  >
                    {val.content}
                  </Highlight>
                  <VideoPlayer
                    width="100%"
                    minHeight="auto"
                    src={videoLink?.imageUrl || ''}
                    showPlayer
                  ></VideoPlayer>
                </Box>
              ) : (
                <Box>
                  <Text cursor={isChatButton(val.highlightText) ? 'pointer' : 'auto'} onClick={isChatButton(val.highlightText) ? () => startChat() : () => {}}>
                    <Highlight query={val.highlightText} styles={{ textDecoration: 'underline' }}>
                      {val.content}
                    </Highlight>
                  </Text>
                </Box>
                // <Text ml={'8px'}>{val.content}</Text>
              )}
            </Flex>
          ))}
        </Box>

        <Box w={'full'} h={'auto'} p={'8px 0px'}>
          <Text fontSize="xl">
            <TextScript
              text={diyVsDataTransferContent.title}
              translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.diyVsDataTransferContent.KeyTitle}`}
            />
          </Text>
          <Text mt={'1rem'} fontSize="md">
            <TextScript
              text={diyVsDataTransferContent.subtitle}
              translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.diyVsDataTransferContent.KeySubtitle}`}
            />
          </Text>
          <br />
          {dataTransferInfoContent && (
            <div dangerouslySetInnerHTML={{ __html: dataTransferInfoContent }} />
          )}
          <br />
          <Text fontSize="md">
            <TextScript
              text={diyVsDataTransferContent.subtitle1}
              translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.diyVsDataTransferContent.KeySubtitle1}`}
            />
          </Text>
          <Accordion defaultIndex={[0]} allowMultiple>
            {diyVsDataTransferContent.info.map((val, index) => (
              <AccordionItem py={'1rem'} borderColor={'#EBEBEB'} borderWidth={'0 0 1px 0'}>
                <AccordionButton px={'0'}>
                  <Flex flex={'1'}>
                    <Text as={'b'}>
                      <TextScript
                        text={val.infoSequence}
                        translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.diyVsDataTransferContent.KeyInfoSequence}${index}`}
                      />
                    </Text>
                    <Text ml={'8px'}>
                      <TextScript
                        text={val.infoTitle}
                        translationKey={`${DoorStepDataTransferKeys.PageName}/${DoorStepDataTransferKeys.diyVsDataTransferContent.KeyInfoTitle}${index}`}
                      />
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} px={0}>
                  {diyVsDataTransferFeeContents && diyVsDataTransferFeeContents.length > 0 &&
                    <div dangerouslySetInnerHTML={{ __html: diyVsDataTransferFeeContents[index].infoContent }} />
                  }
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>

        <Box
          p={'24px 16px'}
          bg={'white'}
          position={'fixed'}
          bottom={0}
          right={0}
          left={0}
          zIndex={'3'}
          style={{ boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.08)' }}
          w={'full'}
        >
          <Flex direction={{ base: 'column', lg: 'row' }} justifyContent={'center'} mx={'auto'}>
            <Flex align={'baseline'} w={{ base: 'full', lg: '70%' }} justify={'center'}>
              <Checkbox
                mr={'0.5rem'}
                onChange={handleCheckboxChange}
                size="lg"
                colorScheme="primary"
              >
                {feeContent && 
                  <Text fontSize={'md'} lineHeight={'tall'}>
                    <Highlight
                      query={feeContentProps.highlightText}
                      styles={{ fontWeight: 'bold', mb: '4px' }}
                    >
                      {feeContent}
                    </Highlight>
                  </Text>
                }
              </Checkbox>
            </Flex>
            <Button
              textAlign={'center'}
              onClick={() => onNextClick()}
              colorScheme={'primary'}
              p={'12px 20px'}
              px={{ base: '20px', lg: '60px' }}
              mt={{ base: '16px', lg: '0px' }}
              style={{
                background: primary,
                color: 'white',
              }}
            >
              <TextScript
                text={Scripts.Global.Next}
                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`}
              />
            </Button>
          </Flex>
        </Box>
      </Box>
      { programmeNameLBL &&
        <Feedback title={feedbackProps.title} description={feedbackProps.description} feedbackGradient={primary} clientOfferName = {programmeNameLBL}></Feedback> }
      <CustomerSupportFooter
        keywords={['Like for like', 'Service request fee', 'Storage capacity', 'Wipe my phone']}
        title="Need help?"
        heading="Hey 👋 I’m Anna, your Tech specialist."
        subheading="Our team is here to help!"
        searchTitle="You might be searching for this..."
        onSendQuestion={(qq) => {
          setQuestion(qq);
        }}
        doAnimate={true}
        images={[
          {
            position: 'left',
            url: expertLeft,
            view: 'mobile',
          },
          {
            position: 'middle',
            url: expertMid,
            view: 'mobile',
          },
          {
            position: 'right',
            url: expertRight,
            view: 'mobile',
          },
          {
            position: 'middle',
            url: expertDesktop,
            view: 'desktop',
          },
        ]}
      />
      <ChatOverlay question={question} onChatClose={() => setQuestion('')} />
      <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
    </Box>
  );
};
