/* eslint-disable no-console */
import CalendarIcon from '../../icons/CalendarIcon';
import MainStackLayout from '../../layout/MainStackLayout';
import { useCentralStore } from '../../store/appContext';
import { DeviceSpecsFieldConfig } from '../../types/types';
import { allowAnchorDeviceRegistration, getAddDeviceFields, showDeviceCategoryFromCode } from '../../utils/featuresUtills';
import { formatDate, getDateAndDay, getRedirectUrl, isEarPhones, isGameConsoles, isHeadPhones, isLaptop, isPhone, isSmartWatches, isTablet, saveError, showErrorPage } from '../../utils/utills.tsx';
import { SubHeader, TextScript, getTextScript, getTypeByDomain, useConfig, useSessionStorage, useTextScript } from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Image, Input, InputGroup, InputRightElement, Link, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Scripts from '../../Scripts/asurion-script.json';
import translationsKeys from '../../Scripts/translationsKeys.json';
import help from '../../icons/Help.svg';
import DeviceSpecs from './DeviceSpecs';
import FindSerialNoModal from './FindSerialNoModal';
import ActionTypes from '../../store/actionTypes';
import { createInquiry, deviceRegistration, getInventoryData } from '../../services/api';
import { CONTENTFUL_SLUG } from '../../utils/constant';
import useContentfulImage from '../../hooks/useContentfulImage';
//Local Images
import laptopimg from '../../icons/Laptop.png';
import smartwatchimg from '../../icons/Smartwatches.png';
import tabletimg from '../../icons/Tablet.png';
import gameconsolesimg from '../../icons/GameConsoles.png';
import headphonesimg from '../../icons/Headphones.png';
import earphonesimg from '../../icons/Earphones.png';
//import phoneimg from '../../icons/Phone.png'



function DeviceDetailsNew() {
  const navigate = useNavigate();
  const globalState = useCentralStore();
  const { isTranslationsLoading, language } = useTextScript();

  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const [gadgetCategoryName,] = useSessionStorage<string>('gadgetCategoryName', '');

  const laptop = useContentfulImage(CONTENTFUL_SLUG.LAPTOP);
  const tablet = useContentfulImage(CONTENTFUL_SLUG.TABLET);
  const gameConsoles = useContentfulImage(CONTENTFUL_SLUG.GAMECONSOLE);
  const healthexercise = useContentfulImage(CONTENTFUL_SLUG.HEALTHEXERCISE);
  const headphones = useContentfulImage(CONTENTFUL_SLUG.HEADPHONES);
  // const phones = useContentfulImage(CONTENTFUL_SLUG.PHONE)

  const agreementData1: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const clientOfferId = agreementData1?.ClientOffer?.ClientOfferId;
  const selectedCategoryDetails = globalState?.state?.selectedCategory?.selectedDeviceCategory[0];
  const selectedCategory =  gadgetCategoryName?.toUpperCase();
  const EmailAddress = agreementData1?.ContactPoints?.ContactPoint?.filter((a: any) => a.EmailAddress)
  const MobileDeviceNumber = agreementData1?.ContactPoints?.ContactPoint?.filter((a: any) => { return a.PhoneNumber })
  const intializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;
  const enrolledAssetData = globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0]?.Assets?.Asset?.filter(
    (a: any) => { return a?.AssetInstance?.toUpperCase() === 'ENROLLED' || a?.AssetInstance?.toUpperCase() === 'CUSVERIFY'; }
  );
  const isBYOD = agreementData1?.isBYODCustomer

  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [date, setDate] = useState<string>('');
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const [purchaseDateLbl, setPurchaseDateLbl] = useState<string>('');
  const [fields, setFields] = useState<any>([])
  const [AttributesOnBrandModel, setAttributesOnBrandModel] = useState<any>([]);
  const [otherSelected, setOther] = useState<any>(false);
  const [otherBrand, setBrandOther] = useState<any>(false);
  const [otherModel, setModelOther] = useState<any>(false);
  const [AssetCatalogId,setAssetCatalogId] = useState<any>('')
  const [, setInventoryResponse] = useState<any>();

  const AddNewDeviceScripts = Scripts.AddDevice.AddNewDevice;
  const AddNewDeviceKeys = translationsKeys.AddDevice.AddNewDevice;

  const AssetPurchaseDate = date && getDateAndDay(date, 'MMMM D, YYYY');
  const pageName = AddNewDeviceKeys.PageName;
  const futureDate = getDateAndDay(new Date(), 'YYYY-MM-DD');
  // const pastDateLimit = new Date(new Date().setDate(new Date().getDate() - 30));
  // const pastDateFormat = getDateAndDay(pastDateLimit, 'YYYY-MM-DD');

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  useEffect(() => {
    if (!isTranslationsLoading) {
      getPurchaseDateLbl();
    }
  }, [language, isTranslationsLoading])

  useEffect(() => {

    if (agreementData1) {
      if(!isLaptop(selectedCategory) && !isTablet(selectedCategory)){
        callInventoryDataApi()
      }
      const addDeviceFields = getAddDeviceFields(agreementData1?.ClientOffer?.ClientOfferName, selectedCategory);
      setFields(addDeviceFields);
      // loadPlaceholderScripts(addDeviceFields);
    }
  }, [selectedCategory, agreementData1])

  useEffect(() => {

    if (globalState?.state?.registerDeviceResponse) {
      const filteringSelectedcategoryData = globalState?.state?.registerDeviceResponse?.filter((a: any) => a.AssetCategoryName?.toUpperCase() === (selectedCategory?.toUpperCase() || selectedCategoryDetails.categoryName?.toUpperCase()))
       const BrandField = getFieldByFieldName("Brand")
      if (filteringSelectedcategoryData?.length && fields?.length > 0 && BrandField?.data?.length === 0) {
        const BrandNames = filteringSelectedcategoryData?.map((val: any) => {
          return val.AssetMakeName
        });
        if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) BrandNames.push('Others')
        const filterBrands = Array.from(new Set(BrandNames));
        const BrandField = getFieldByFieldName("Brand");
        updateFields(BrandField, filterBrands, false);
        // setFilteredSelectedCategoryData(filteringSelectedcategoryData) 
      }
    }
  }, [globalState?.state?.registerDeviceResponse, fields])

  useEffect(() => {
    if (AttributesOnBrandModel?.length > 0) {
      if (isTablet(selectedCategory)) {
        colorOptions(AttributesOnBrandModel)
        StorageSizeOptions(AttributesOnBrandModel)
        ConnectivityOptions(AttributesOnBrandModel)
        RAMOptions(AttributesOnBrandModel)
        RRPOptions(AttributesOnBrandModel)
      } else if (isPhone(selectedCategory)) {
        colorOptions(AttributesOnBrandModel)
        CapacityPhoneOptions(AttributesOnBrandModel)
        RAMOptions(AttributesOnBrandModel)
        RRPOptions(AttributesOnBrandModel)
      } else if (isSmartWatches(selectedCategory)) {
        colorOptions(AttributesOnBrandModel)
        RRPOptions(AttributesOnBrandModel)
        ConnectivityOptions(AttributesOnBrandModel)
        caseTypeOptions(AttributesOnBrandModel)
        FaceSizeOptions(AttributesOnBrandModel)
      } else if (isHeadPhones(selectedCategory) || isEarPhones(selectedCategory)) {
        colorOptions(AttributesOnBrandModel)
        RRPOptions(AttributesOnBrandModel)
        ConnectivityOptions(AttributesOnBrandModel) // uncomment this after getting data from api ***
       // ConnectivityOptionsDumy(AttributesOnBrandModel) //remove this hardcode after getting data from api ***
      } else if (isLaptop(selectedCategory)) {
        colorOptions(AttributesOnBrandModel)
        StorageTypeOptions(AttributesOnBrandModel)
        ProcessorOptions(AttributesOnBrandModel)
        CapacityOptions(AttributesOnBrandModel)
        RAMOptions(AttributesOnBrandModel)
        RRPOptions(AttributesOnBrandModel)
      } else if (isGameConsoles(selectedCategory)) {
        colorOptions(AttributesOnBrandModel)
        //StorageOptions(AttributesOnBrandModel)
        RRPOptions(AttributesOnBrandModel)
      }
    }
  }, [AttributesOnBrandModel])

  const getPurchaseDateLbl = async () => {
    const purchaseDateLbl = await getTextScript(
      `${AddNewDeviceKeys.PageNameAnchorDevice}/${AddNewDeviceKeys.purchaseDate}`,
      AddNewDeviceScripts.purchaseDate,
      language,
      []);

    setPurchaseDateLbl(purchaseDateLbl);
  }

  // const loadPlaceholderScripts = async (addDeviceFields: Array<DeviceSpecsFieldConfig>) => {
  //   let updatedFields = [];

  //   for (const field of addDeviceFields) {

  //     const defaultPlaceholder = field?.placeholder;
  //     const defaultError = field?.errorMessage;

  //     const returnedText = await getTextScript(
  //       `${translationsKeys.Global.PageName}/${field?.name}`,
  //       defaultPlaceholder,
  //       language,
  //       []
  //     );

  //     const returnedTextError = await getTextScript(
  //       `${translationsKeys.Global.PageName}/${field?.name}Error`,
  //       defaultError,
  //       language,
  //       []
  //     );

  //     field.placeholder = returnedText;
  //     field.errorMessage = returnedTextError;

  //     updatedFields.push(field)
  //   }

  //   setFields(updatedFields);

  // };

  const onClickPopup = () => {
    setOpenCancelPopup(!openCancelPopup);
  };

  const getImagesFromContentFul = (CategoryName:any) => {
    switch (CategoryName?.toUpperCase()) {
      case 'LAPTOP':
       return laptop.imageUrl

      case 'HEADPHONES':
        return headphones.imageUrl
      
      case 'TABLET':
        return tablet.imageUrl

      case 'GAME CONSOLES':
        return gameConsoles.imageUrl
      
      case 'EARPHONES':
        return headphones.imageUrl
      
      case 'SMARTWATCHES':
        return healthexercise.imageUrl
      
      }
   }
  
   const getImagesFromLocal = (CategoryName:any) => {
    switch (CategoryName?.toUpperCase()) {
      case 'LAPTOP':
       return laptopimg

      case 'HEADPHONES':
        return headphonesimg
      
      case 'TABLET':
        return tabletimg

      case 'GAME CONSOLES':
        return gameconsolesimg
      
      case 'EARPHONES':
        return earphonesimg
      
      case 'SMARTWATCHES':
        return smartwatchimg
      
      }
   }

  const ConnectivityOptions = (filteringSelectedcategoryData: any) => {

    const connectivity = filteringSelectedcategoryData?.map((val: any) => {
      return val.Connectivity
    });
    if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName))connectivity.push('Others')
    const allConnectivity = [].concat(...connectivity)
    const filterConnectivity = Array.from(new Set(allConnectivity))
    const ConnectivityFeild = getFieldByFieldName("Connectivity");
    updateFields(ConnectivityFeild, filterConnectivity, false);
  }

  const CapacityOptions = (filteringSelectedcategoryData: any) => {
    const capacity = filteringSelectedcategoryData[0]?.Storage_Size?.filter((val: any) => val.includes('GB'));
    const capacityTB = filteringSelectedcategoryData[0]?.Storage_Size?.filter((val: any) => val.includes('TB'));
    capacity.push(capacityTB)
    if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) capacity.push('Others')
    const AllCapacities = [].concat(...capacity)
    const filterCapacity = Array.from(new Set(AllCapacities))

    const CapacityField = getFieldByFieldName("Capacity");
    updateFields(CapacityField, filterCapacity, false);
  }

  const CapacityPhoneOptions = (filteringSelectedcategoryData: any) => {
    // const capacity = filteringSelectedcategoryData[0]?.Capacity?.filter((val: any) => val.includes('GB'));
    // const capacityTB = filteringSelectedcategoryData[0]?.Capacity?.filter((val: any) => val.includes('TB'));
    const capacity = filteringSelectedcategoryData[0]?.Capacity
    //capacity.push(capacityTB)
    //capacity.push('Others')
    const AllCapacities = [].concat(...capacity)
    const filterCapacity = Array.from(new Set(AllCapacities))

    const CapacityField = getFieldByFieldName("Capacity");
    updateFields(CapacityField, filterCapacity, false);
  }

  const RAMOptions = (filteringSelectedcategoryData: any) => {
    const ram = filteringSelectedcategoryData[0]?.RAM?.filter((val: any) => val.includes('GB'));
    const ramTB = filteringSelectedcategoryData[0]?.RAM?.filter((val: any) => val.includes('TB'));
    ram.push(ramTB)
    if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) ram.push('Others')
    const allRAMS = [].concat(...ram)
    const filterRAM = Array.from(new Set(allRAMS))
    const RamField = getFieldByFieldName("RAM");
    updateFields(RamField, filterRAM, false);
  }

   //Remove this function after getting data from api ***

  const RRPOptions = (filteringSelectedcategoryData: any) => {
    const data: any = ['0-250', '250-550', '550-750']; //remove this after getting data from api ***
    console.log(filteringSelectedcategoryData) //uncomment this after getting data from api ***
    // const rrp = filteringSelectedcategoryData?.map((val: any) => {
    //   return val.ModelRrp;
    // });
    if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) data.push('Others')
    const filterRRP = Array.from(new Set(data))
    const RRPField = getFieldByFieldName("RRP");
    updateFields(RRPField, filterRRP, false);
  }

  const StorageTypeOptions = (filteringSelectedcategoryData: any) => {
    const storageType = filteringSelectedcategoryData?.map((val: any) => {
      return val.Storage_Type;
    });
    if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) storageType.push('Others')
    const AllstorageType = [].concat(...storageType)
    const filterStorage = Array.from(new Set(AllstorageType))

    const StorageField = getFieldByFieldName("StorageType");
    updateFields(StorageField, filterStorage, false);
  }

  const ProcessorOptions = (filteringSelectedcategoryData: any) => {
    const processor = filteringSelectedcategoryData?.map((val: any) => {
      return val.Processor;
    });
    if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) processor.push('Others')
    const AllProcessor = [].concat(...processor)
    const filterProcessor = Array.from(new Set(AllProcessor))
    const ProcessorField = getFieldByFieldName("Processor");
    updateFields(ProcessorField, filterProcessor, false);
  }

  const FaceSizeOptions = (filteringSelectedcategoryData: any) => {
    const facesize = filteringSelectedcategoryData?.map((val: any) => {
      return val.Face_Size;
    });
    if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) facesize.push('Others')
    const AllFaceSize = [].concat(...facesize)
    const filterFacesize = Array.from(new Set(AllFaceSize))
    const FacesizeField = getFieldByFieldName("FaceSize");
    updateFields(FacesizeField, filterFacesize, false);
  }

  const caseTypeOptions = (filteringSelectedcategoryData: any) => {
    const data = ['Steel', 'Leather', 'Metal', 'Others']
     const facetype = filteringSelectedcategoryData?.map((val: any) => {
       return val.Face_type;
     });
     console.log(facetype, "facetype")
    // if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) facetype.push('Others')
    // const AllFacetype = [].concat(...facetype)
    // const filterFacetype = Array.from(new Set(AllFacetype))
    const FacesizeField = getFieldByFieldName("CaseType");
    updateFields(FacesizeField, data, false);   // filterFacetype in place of data ***
  }

  const colorOptions = (filteringSelectedcategoryData: any) => {
    const data = ['Red', 'White', 'Black', 'Rose gold','Others']
     const DeviceColor = filteringSelectedcategoryData?.map((val: any) => {
       return val.DeviceColor;
     });
     console.log(DeviceColor, "DeviceColor")
    // if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) facetype.push('Others')
    // const AllDeviceColor = [].concat(...DeviceColor)
    // const filterDeviceColor = Array.from(new Set(AllDeviceColor))
    const DeviceColorField = getFieldByFieldName("Color");
    updateFields(DeviceColorField, data, false);   // filterDeviceColor in place of data ***
  }

  // const StorageOptions = (filteringSelectedcategoryData: any) => {
  //   const storage = filteringSelectedcategoryData?.map((val: any) => {
  //     return val.Storage_Size
  //   });
  //   if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) storage.push('Others')
  //   const AllStorages = [].concat(...storage)
  //   const filterStorage = Array.from(new Set(AllStorages))

  //   const StorageField = getFieldByFieldName("Storage");
  //   updateFields(StorageField, filterStorage, false);
  // }

  const onCancelClick = () => {
    window.location.href = getRedirectUrl(getTypeByDomain());
  };

  const StorageSizeOptions = (filteringSelectedcategoryData: any) => {
    const capacity = filteringSelectedcategoryData[0]?.Capacity?.filter((val: any) => val.includes('GB'));
    const capacityTB = filteringSelectedcategoryData[0]?.Capacity?.filter((val: any) => val.includes('TB'));
    capacity.push(capacityTB)
    if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) capacity.push('Others')
    const AllCapacities = [].concat(...capacity)
    const filterCapacity = Array.from(new Set(AllCapacities))

    const CapacityField = getFieldByFieldName("Capacity");
    updateFields(CapacityField, filterCapacity, false);
  }

  const handleSelectChange = (event: any, field: DeviceSpecsFieldConfig) => {
    let isInvalid = false
    const filteringSelectedcategoryData = globalState?.state?.registerDeviceResponse?.filter((a: any) => a.AssetCategoryName?.toUpperCase() === selectedCategory)
    const selectedValue = event.value;
    if (field.name === 'Brand') {
      if (selectedValue !== '') {
        clearFieldsForBrand()
        if (selectedValue === 'Others') {
          setOther(true)
          setAssetCatalogId('')
          setBrandOther(true)
          setModelOther(false)
          setOtherValues()
        } else {
          setOther(false)
          setBrandOther(false)
          const BrandField = getFieldByFieldName('Brand');
          updateFields(BrandField, selectedValue, isInvalid);
          const ModelField = getFieldByFieldName('Model');
          const ModelListOnBrand = filteringSelectedcategoryData?.filter((a: any) => a.AssetMakeName?.toUpperCase() === selectedValue?.toUpperCase()) //filteredSelectedCategoryData
          const Models = ModelListOnBrand?.map((b: any) => { return b.Model })
          if(!isBYOD && allowAnchorDeviceRegistration(agreementData1.ClientOffer.ClientOfferName)) Models.push('Others')
          const ModelList = [].concat(...Models)
          updateFields(ModelField, ModelList, ModelField?.isInvalid)                                                                                                                                                                                           
        }
      }
    } else if (field.name === 'Model') {
      console.log(fields, "fileds model")
      if (selectedValue !== '') {
        clearFieldsForModel(selectedValue)
        if (selectedValue === 'Others') {
          setOther(true)
          setModelOther(true)
          OtherModelSelected()
          setAssetCatalogId('')
        } else {
          setOther(false)
          setModelOther(false)
          const attributesOnBrandModel = filteringSelectedcategoryData?.filter((a: any) => a.Model.includes(selectedValue))
          setAttributesOnBrandModel(attributesOnBrandModel)
          const ModelList =  attributesOnBrandModel?.map((a: any) => a.Model)
          const getIndexOfSelectedModel = ModelList[0].indexOf(selectedValue)
           let AssetCatalogId = attributesOnBrandModel[0]?.AssetCatalogIds?.[getIndexOfSelectedModel]
           setAssetCatalogId(AssetCatalogId)
        }
      }
    }
    else {
      if (selectedValue === 'Others') {
        setOther(true)
        SetOtherAttributes(field)
        setAssetCatalogId('')
      } else {
        setOther(false)
        clearOtherAtr(field)
        updateFields(field, selectedValue, isInvalid);
      }
    }
  };

  const handleInputChange = (field: DeviceSpecsFieldConfig, value: string) => {
    let isInvalid = false;
    if (field.name === 'SerialNumber' || field.name === 'ModelNumber') {
      const regex = /^[a-zA-Z0-9-_#]+$/;

      if (regex.test(value)) {
        isInvalid = false
      } else {
        isInvalid = true
      }
    }
    updateFields(field, value, isInvalid);
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };

  const handleSubmit = () => {
    if (showDeviceCategoryFromCode(agreementData1?.ClientOffer?.ClientOfferName)) {
      navigate('/uploadPurchaseReceipt', { replace: true });
    } else {
      if (isLaptop(selectedCategory) || isTablet(selectedCategory) || isPhone(selectedCategory) || otherSelected) {
        callInquiryRegisterDeviceApi()
      } else {
        callDeviceRegistrationApi()
      }
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const showPicker = () => {
    inputRef?.current?.showPicker();
  }

  const onBackClick = () => {
   if(isLaptop(selectedCategory) || isTablet(selectedCategory)) {
      navigate('/thingsToNote', { replace: true });
    } else {
      window.history.back()
     // navigate('/deviceCategoryList', { replace: true });
    }  
  };


  const getFieldByFieldName = (fieldName: string) => {
    return fields.find((item: any) => {
      return item.name === fieldName;
    });
  }

  const setOtherValues = () => {

    setFields((prevFields: any) =>
      prevFields.map((item: any) => {
        return {
          ...item,
          value: '',
          isInvalid: false,
          Other: 'Others',
          showSelect: false
        }
      }
      )
    )
  }

  const OtherModelSelected = () => {

    setFields((prevFields: any) =>
      prevFields.map((item: any) =>
        (item.name === 'Brand')
          ? {
            ...item,
            isInvalid: false,
            Other: '',
            showSelect: false
          }
          : {
            ...item,
            Other: 'Others',
            showSelect: false,
            value: ''
          }
      )
    )
  }

  const SetOtherAttributes = (field: any) => {

    setFields((prevFields: any) =>
      prevFields.map((item: any) =>

          (item?.showSelect) ?
          {
            ...item,
            isInvalid: false,
            Other: 'Others',
            showSelect: true
          }
          : (item.name === field?.name)
          ? {
            ...item,
            value: '',
            isInvalid: false,
            Other: 'Others',
            showSelect: true
          }
          :{
            ...item,
            showSelect: false,
            Other: '',

          }
      )
    )
  }

  const clearOtherAtr = (field: any) => {

    setFields((prevFields: any) =>
      prevFields.map((item: any) =>
        (item.name === field?.name) ?
          {
            ...item,
            //data: [],
            isInvalid: false,
            Other: '',
            showSelect: false
          }
          :
          {
            ...item,

          }
      )
    )
  }

  const clearFieldsForBrand = () => {

    setFields((prevFields: any) =>
      prevFields.map((item: any) =>
        item.name !== 'Brand'
          ? {
            ...item,
            data: [],
            value: '',
            isInvalid: false,
            Other: '',
            showSelect: false
          }
          :
          {
            ...item,
            value: '',
            Other: '',
            showSelect: false
          }
      )
    )
  }

  const clearFieldsForModel = (value: string) => {

    setFields((prevFields: any) =>
      prevFields.map((item: any) =>
        (item.name !== 'Model' && item.name !== 'Brand')
          ? {
            ...item,
            data: [],
            value: '',
            isInvalid: false,
            Other: '',
            showSelect: false
          }
          : (item.name === 'Model') ?
            {
              ...item,
              value: value,
              Other: '',
              showSelect: false
            } : item
      )
    )
  }

  const updateFields = (field: any, value: any, isInvalid: boolean) => {
    if (typeof value === 'string') {
      if (value === 'Others') {
        setFields((prevFields: any) =>
          prevFields.map((item: any) =>
            item.name === field.name
              ? {
                ...item,
                Other: value,
                isInvalid: isInvalid,
                value: ''
              }
              : item
          ))
      }
      else {
        setFields((prevFields: any) =>
          prevFields.map((item: any) =>
            item.name === field.name
              ? {
                ...item,
                value: value,
                isInvalid: isInvalid,
              }
              : item
          )
        )
      }
    }
    else {
      setFields((prevFields: any) =>
        prevFields.map((item: any) =>
          item.name === field?.name
            ? {
              ...item,
              data: value,
              value: '',
              isInvalid: isInvalid,
            }
            : item
        )
      );

    }
  }

  const isAnyRequiredFieldInvalid = (fields: any) => {
    for (const field of fields) {
      if (field?.isRequired && field?.isInvalid) {
        return true;
      }
      if (field?.isRequired && (field?.value === null || field?.value === '')) {
        return true;
      }
      if (date === '') {
        return true;
      }
    }
    return false;
  };

  const getFormValues = () => {
    const purchaseDate = formatDate(date, 'YYYY-MM-DD', 'en-US', 'MMMM D, YYYY');
    //const categoryDetails = AssetCategoryDetails?.filter((a: any) => a.CategoryName?.toUpperCase() === selectedCategory)
    const categoryDetails = globalState?.state?.categoryListResponse?.filter((a: any) => a.CategoryName?.toUpperCase() === selectedCategory)
    const AssetCategoryId = categoryDetails?.map((a: any) => { return a.AssetCategoryId })
    let obj = {
      AssetCategoryId: AssetCategoryId?.[0],
      Category: selectedCategory,
      AssetCatalogId: AssetCatalogId,
      Brand: getFieldByFieldName('Brand')?.value,
      Model: getFieldByFieldName('Model')?.value,
      Connectivity: getFieldByFieldName('Connectivity')?.value,
      Capacity: getFieldByFieldName('Capacity')?.value,
      Storage: getFieldByFieldName('Storage')?.value,
      StorageType: getFieldByFieldName('StorageType')?.value,
      RRP: getFieldByFieldName('RRP')?.value,
      RAM: getFieldByFieldName('RAM')?.value,
      SerialNumber: getFieldByFieldName('SerialNumber')?.value,
      IMEI: getFieldByFieldName('IMEI')?.value,
      ModelNumber: getFieldByFieldName('ModelNumber')?.value,
      FaceSize: getFieldByFieldName('FaceSize')?.value,
      DateOfPurchase: purchaseDate,
      Processor: getFieldByFieldName('Processor')?.value
    }
    return obj
  }


  const getInquiryDescription = (separator: any) => {  //do else if
    const AddedDeviceDetails = getFormValues()
    let inquiryDescription: any
    if (isLaptop(selectedCategory)) {
      inquiryDescription = `${'Processor=' + AddedDeviceDetails?.Processor + separator + 'RAM=' + AddedDeviceDetails?.RAM + separator + 'Storage_Size=' + (AddedDeviceDetails?.Storage || AddedDeviceDetails.Capacity) + separator + 'Storage_Type=' + AddedDeviceDetails?.StorageType + separator + 'Brand=' + AddedDeviceDetails?.Brand + separator + 'Model=' + AddedDeviceDetails?.Model + separator + 'SerialNumber=' + AddedDeviceDetails?.SerialNumber + separator + 'AssetCatalogId=' + AssetCatalogId + separator } `
    } if (isTablet(selectedCategory)) {
      inquiryDescription = `${'RAM=' + AddedDeviceDetails?.RAM + separator + 'Capacity=' + AddedDeviceDetails?.Capacity + separator + 'Connectivity=' + AddedDeviceDetails?.Connectivity + separator + 'AssetId=' + enrolledAssetData?.[0]?.AssetId + separator + 'AssetCatalogId=' + AssetCatalogId + separator + 'Brand=' + AddedDeviceDetails?.Brand + separator + 'Model=' + AddedDeviceDetails?.Model + separator + 'SerialNumber=' + AddedDeviceDetails?.SerialNumber + separator} `
    } if (isSmartWatches(selectedCategory)) {
      inquiryDescription = `${'Connectivity=' + AddedDeviceDetails?.Connectivity + separator + 'Face_Size=' + AddedDeviceDetails?.FaceSize + separator + 'Brand=' + AddedDeviceDetails?.Brand + separator + 'Model=' + AddedDeviceDetails?.Model + separator + 'SerialNumber=' + AddedDeviceDetails?.SerialNumber + separator + 'AssetCatalogId=' + AssetCatalogId + separator} `
    } if (isHeadPhones(selectedCategory) || isEarPhones(selectedCategory)) {
      inquiryDescription = `${'Model=' + AddedDeviceDetails?.Model + separator + 'Brand=' + AddedDeviceDetails?.Brand + separator + 'AssetCatalogId=' + AssetCatalogId + separator} `
    } if (isPhone(selectedCategory)) {
      inquiryDescription = `${'Model=' + AddedDeviceDetails?.Model + separator + 'AssetId=' + enrolledAssetData?.[0]?.AssetId + separator + 'AssetCatalogId=' + AssetCatalogId + separator + 'Brand=' + AddedDeviceDetails?.Brand + separator + 'SerialNumber=' + AddedDeviceDetails?.IMEI + separator}`
    } if (isGameConsoles(selectedCategory)) {
      inquiryDescription = `${'Storage_Size=' + AddedDeviceDetails?.Storage + separator + 'Model=' + AddedDeviceDetails?.Model + separator + 'Brand=' + AddedDeviceDetails?.Brand + separator + 'SerialNumber=' + AddedDeviceDetails?.SerialNumber + separator + 'AssetCatalogId=' + AssetCatalogId + separator}`
    }
    return inquiryDescription?.charAt(inquiryDescription.length - 1) ===
      separator
      ? inquiryDescription.substring(0, inquiryDescription.length - 1)
      : inquiryDescription;
  }

  // Inquiry Api 
  const callInquiryRegisterDeviceApi = () => {
    setLoading(true);
    const AddedDeviceDetails = getFormValues()
    const CreateInquiryParams = {
      CreateInquiryParams: {
        MobileDeviceNumber: MobileDeviceNumber?.[0]?.PhoneNumber || '',
        FirstName: agreementData1?.Customers?.Customer[0]?.FirstName || '',
        LastName: agreementData1?.Customers?.Customer[0]?.LastName || '',
        ContactNumber: MobileDeviceNumber?.[0]?.PhoneNumber || '',
        ClientName: intializeResponse?.ClientName,
        InquiryChannel: 'ONLINE',
        InquiryType: isBYOD || otherSelected ? "Spex Check" : 'Device Registration',
        InquirySubType: isBYOD ? 'Spex Check' : otherSelected ? 'Spex Check Other': 'Device Registration',
        InquiryDescription: getInquiryDescription('|'),
        CallDriverCategory: 'Master Data Management',
        CallDriverSubCategory: AddedDeviceDetails?.Category,
        ClientId: intializeResponse?.ClientId,
        Email: EmailAddress[0]?.EmailAddress,
        DepartmentType: (isLaptop(AddedDeviceDetails?.Category) || isTablet(AddedDeviceDetails?.Category)) && !otherSelected ? 'Risk' : 'Supply Chain',
        Priority: 'High',
        ProgramName: agreementData1?.ClientOffer?.ClientOfferName,
        AgreementId: agreementData1?.AgreementId,
        isPremiumPlan: true,
        ClientAccountId: agreementData1?.ClientAccount?.ClientAccountId,
        ClientChannelId: agreementData1?.ClientAccount?.ClientChannelId,
        CustomerId: agreementData1?.Customers?.Customer[0]?.CustomerId,
        AssetId: enrolledAssetData?.[0]?.AssetId,
        DeviceDetails: {
          Category: AddedDeviceDetails?.Category || AddedDeviceDetails?.Storage,
          AssetMake: AddedDeviceDetails?.Brand,
          AssetModel: AddedDeviceDetails?.Model,
          AssetAttributes: getInquiryDescription('|'),
          PurchaseDate: AddedDeviceDetails?.DateOfPurchase,
          SerialNumber: AddedDeviceDetails?.SerialNumber,
          Face_Size: AddedDeviceDetails?.FaceSize,
          Connectivity: AddedDeviceDetails?.Connectivity,
          Storage_Size: AddedDeviceDetails.Storage
        },
        UpdateDeviceDetails: {
          AssetCatalogId: AddedDeviceDetails?.AssetCatalogId,
          IMEI: AddedDeviceDetails?.SerialNumber || '',
          ClientAssetSkuNumber: AddedDeviceDetails?.Model,
        },
      },
    };

    createInquiry(
      CreateInquiryParams,
      interactionData?.InteractionLine.InteractionLineId,
      globalState?.state?.findAgreementSessionData
    )
      .then((resp:any) => {
        setLoading(false);
        globalState?.dispatch({ type: ActionTypes.SET_INQUIRY_RESPONSE, payload: resp });
        navigate('/confirmDeviceRegistration', { replace: true });
      })
      .catch((error: any) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  const callDeviceRegistrationApi = () => {
    setLoading(true);
    let AddedDeviceDetails = getFormValues()
    const DeviceRegistrationParameters = {
      DeviceRegistrationParameters: {
        AssetCategoryId: AddedDeviceDetails.AssetCategoryId,
        AgreementId: agreementData1?.AgreementId,
        User: `${agreementData1?.Customers?.Customer[0]?.FirstName || '' + agreementData1?.Customers?.Customer[0]?.LastName || ''}`,
        DeviceDetails: {
          Category: selectedCategory,
          Make: AddedDeviceDetails?.Brand,
          Capacity: AddedDeviceDetails?.Capacity || '',
          RAM: AddedDeviceDetails?.RAM || '',
          Connectivity: AddedDeviceDetails.Connectivity || '',
          SerialNumber: AddedDeviceDetails?.SerialNumber,
          Face_Size: AddedDeviceDetails?.FaceSize,
          Storage_Size: AddedDeviceDetails.Storage
        },
        SerialNumber: AddedDeviceDetails?.SerialNumber ? AddedDeviceDetails?.SerialNumber: AddedDeviceDetails?.IMEI,
        ClientId: intializeResponse?.ClientId,
        ClientAccountId: agreementData1?.ClientAccount?.ClientAccountId,
        ClientChannelId: agreementData1?.ClientAccount?.ClientChannelId,
        ModelNo: AddedDeviceDetails?.ModelNumber,
        PurchaseDate: AddedDeviceDetails?.DateOfPurchase || '',
        PurchasePrice: AddedDeviceDetails?.RRP || ''
      },
    };

    deviceRegistration(
      DeviceRegistrationParameters,
      interactionData?.InteractionLine.InteractionLineId,
      globalState?.state?.findAgreementSessionData
    )
      .then((resp:any) => {
        setLoading(false);
        globalState?.dispatch({ type: ActionTypes.SET_DEVICE_REGISTRATION_RESPONSE, payload: resp });
        navigate('/confirmDeviceRegistration', { replace: true });
      })
      .catch((error: any) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  const callInventoryDataApi = () => {
    setLoading(true);
    getInventoryData(clientOfferId, interactionData)

      .then((data: any) => {
        globalState?.dispatch({
          type: ActionTypes.SET_REGISTER_DEVICE_RESPONSE,
          payload: data,
        });
        setLoading(false);
        setInventoryResponse(data);
      })
      .catch((error: any) => {
        saveError(error, globalState);
        showErrorPage(navigate);
        setLoading(false);
      });
  };

  return (
    <Box>
      <Box w={{ base: 'full', lg: '80%' }} margin="auto" pt={'50px'}>
        <MainStackLayout gap={0} spacing={0}>
          <SubHeader
            onClick={onBackClick}
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={false}
            showBackButton={true}
            cancelText={Scripts.CancelSR.cancelBtnTextCancel}
            onCancelClick={onCancelClick}
          ></SubHeader>
          {fields?.length > 0 &&
          <Box
            id={'myasurion-profile-avatar'}
            w={{ base: 'full', lg: '80%' }}
            margin="0 auto"
            h={'auto'}
            p={'1rem'}
          >
            <Image
              my={'2'}
              marginX={'auto'}
              src={getImagesFromContentFul(selectedCategory) || getImagesFromLocal(selectedCategory)}
              //src={selectedCategoryDetails?.image || selectedCategoryDetails?.localimage}
              alt="your model"
            />

            <Text mt={'5'} fontSize={{ base: '3xl', lg: '4xl' }}>
              <TextScript
                text={AddNewDeviceScripts.header}
                translationKey={`${pageName}/${AddNewDeviceKeys.header}`}
              />
            </Text>
            <DeviceSpecs
              fields={fields}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              OtherBrand={otherBrand}
              OtherModel={otherModel}
            ></DeviceSpecs>
            <Flex flexDir={'row'} my="5" pt={2} onClick={() => { onClickPopup(); }}>
              <Image src={help} cursor={'pointer'}></Image>
              <Link
                cursor={'pointer'}
                ml={'2'}
                textDecoration={'underline'}
                onClick={() => {
                  onClickPopup();
                }}
              >
                <TextScript
                  text={AddNewDeviceScripts.findSerialNumber}
                  translationKey={`${pageName}/${AddNewDeviceKeys.findSerialNumber}`}
                />
              </Link>
            </Flex>
            {
            // (((selectedCategory === 'PHONE' || selectedCategory === 'TABLET') && isBYOD) || showDeviceCategoryFromCode(agreementData1?.ClientOffer?.ClientOfferName)) &&
              <Box onClick={() => showPicker()} position={'relative'} mb={'10px'}>
                <Input
                  size="md"
                  borderColor={'#A5AAAF'}
                  max={futureDate}
                 // min={pastDateFormat}
                  type="date"
                  ref={inputRef}
                  onChange={handleDateChange}
                  display={'hidden'}
                  position={'absolute'}
                  cursor={'pointer'}
                  style={{
                    opacity: 0,
                    overflow: 'hidden',
                    zIndex: 999,
                  }}
                />
                <InputGroup position={'absolute'}>
                  <InputRightElement pointerEvents="none" marginTop={{ lg: '0px', base: '20px' }} marginRight={{ lg: '0px', base: '7px' }}>
                    <CalendarIcon />
                  </InputRightElement>
                  <Input
                    size={'lg'}
                    borderColor={'#A5AAAF'}
                    onFocus={(e) => e.target.showPicker()}
                    placeholder={purchaseDateLbl || AddNewDeviceScripts.purchaseDate}
                    type="text"
                    position={'relative'}
                    value={AssetPurchaseDate}
                  />
                </InputGroup>
              </Box>}
            {/* <Box mt={showDeviceCategoryFromCode(agreementData1?.ClientOffer?.ClientOfferName) || isBYOD ? '20' : '0'} width={{ base: 'full', lg: '50%' }} marginX={'auto'}> */}
            <Box mt={'20'} width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
              <Button
                mt={'5'}
                width={'full'}
                textAlign={'center'}
                colorScheme="primary"
                bg={isAnyRequiredFieldInvalid(fields) ? 'gray.200' : colors.primary}
                cursor={isAnyRequiredFieldInvalid(fields) ? 'not-allowed' : 'pointer'}
                isDisabled={isAnyRequiredFieldInvalid(fields)}
                onClick={() => (isAnyRequiredFieldInvalid(fields) ? undefined : handleSubmit())}
              >
                <TextScript
                  text={Scripts.Global.Continue}
                  translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Continue}`}
                />
              </Button>
            </Box>
          </Box>
          }
        </MainStackLayout>
        <FindSerialNoModal
          closeCancelPopup={setOpenCancelPopup}
          openCancelPopup={openCancelPopup}
        />
      </Box>
    </Box>
  );
}

export default DeviceDetailsNew;