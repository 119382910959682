import {
    Box,
    Button,
    Center,
    Flex,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack,
    Text,
} from '@chakra-ui/react';
import { useState } from 'react';

import { TextScript } from '@MGPD/myasurion-shared';
const PAGE_NAME = 'NPS';

const labelStyles = {
    mt: '-9',
    ml: '-1',
    fontWeight: 'bold',
};

export interface NPSSliderProps {
    defaultScore?: number;
    onSubmit: (sliderValue: number) => void;
}

export const NPSSlider = ({ defaultScore = 7, onSubmit }: NPSSliderProps) => {
    const [sliderValue, setSetsliderValue] = useState(defaultScore);

const getSliderLabelByValue = (val: any) => {
    const sliderMapping = [
        {value: 1, label: 'Laborious', keyname: 'sliderLabel1'},
        {value: 2, label: 'Tough', keyname: 'sliderLabel2'},
        {value: 3, label: 'Difficult', keyname: 'sliderLabel3'},
        {value: 4, label: 'Neutral', keyname: 'sliderLabel4'},
        {value: 5, label: 'Manageable', keyname: 'sliderLabel5'},
        {value: 6, label: 'Smooth Sailing', keyname: 'sliderLabel6'},
        {value: 7, label: 'Effortless', keyname: 'sliderLabel7'}
    ];
    return sliderMapping?.find(d => d.value == val)    
}
    return (
        <Flex flexFlow="column wrap" align="left" justify="space-between" h="700px">
            <Box p={4}>
                <Flex flexFlow="column wrap" gap={2} mb={2}>
                    <Text
                        fontSize="32px"
                        fontWeight="light"
                        lineHeight="39.68px"
                        letterSpacing="-0.352px"
                    >
                        <TextScript
                            text="Your feedback is important to us"
                            translationKey={`${PAGE_NAME}/sliderTitle`}
                        />
                    </Text>
                    <Text fontSize="lg" lineHeight="22.32px">
                        <TextScript
                            text="How likely are you to recommend to your friends and family?"
                            translationKey={`${PAGE_NAME}/sliderDescription`}
                        />
                    </Text>
                </Flex>
                <Flex flexFlow="column wrap" py="10px" gap="40px" px={4}>
                    <Center fontSize="lg" color="primary.500" fontWeight="bold" pt="10px">                        
                          {sliderValue && (
                            <TextScript
                                text={`${getSliderLabelByValue(sliderValue)?.label}`}
                                translationKey={`${PAGE_NAME}/${getSliderLabelByValue(sliderValue)?.keyname}`}
                            />
                        )}
                    </Center>
                    <Slider
                        defaultValue={defaultScore}
                        min={1}
                        max={7}
                        step={1}
                        onChange={(val) => setSetsliderValue(val)}
                    >
                        {[1, 2, 3, 4, 5, 6, 7].map((e, i) => (
                            <SliderMark
                                key={i}
                                value={e}
                                fontSize={sliderValue === e ? 'xl' : 'sm'}
                                color={sliderValue === e ? 'primary.500' : ''}
                                top={sliderValue === e ? '1' : ''}
                                {...labelStyles}
                            >
                                {e}
                            </SliderMark>
                        ))}
                        <SliderTrack bg="#EDEDED" h="7px" borderRadius={8}>
                            <SliderFilledTrack bg="primary.500" />
                        </SliderTrack>
                        <SliderThumb boxSize={5} bg="primary.500" _focus={{ boxShadow: 'none' }} />
                    </Slider>
                </Flex>
            </Box>
            <Box py={6} px={4} boxShadow="0px -4px 8px 0px rgba(0, 0, 0, 0.04)">
                <Button
                    w="full"
                    colorScheme="primary"
                    fontWeight="bold"
                    borderRadius={8}
                    onClick={() => onSubmit(sliderValue)}
                >
                    <TextScript text={'Next'} translationKey={`${PAGE_NAME}/nextButtonText`} />
                </Button>
            </Box>
        </Flex>
    );
};