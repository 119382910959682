/* eslint-disable no-console */
import { assets } from '../modules/config/constants';
import {
  SignOutLink,
  TextScript,
  getCarrierByDomain,
  useFeatureIsOn,
  useIsDisabledFeature,
} from '@MGPD/myasurion-shared';
import { Box, HStack, Image, Link, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
// import { checkIsApplLoadDisabled } from '../../../utils/config';

export const MenuSidebar = ({ activePage = 'dashboard' }: { activePage?: string }) => {
  const navigate = useNavigate();
  const carrier = getCarrierByDomain() || 'asurion';
  const carrierAssets = assets[carrier];
  const { sidebar } = carrierAssets;
  const { items } = sidebar;
  const isChangePasswordDisabled = useIsDisabledFeature('profile', 'stnChangePassword');
  const isCommunicationPreferencesDisabled = useIsDisabledFeature(
    'profile',
    'stnCommunicationPreferences'
  );
  const isTechcarePreferencesDisabled = useIsDisabledFeature('profile', 'stnTechcarePreferences');
  const isFeedbackDisabled = useIsDisabledFeature('profile', 'stnFeedback');
  const isServiceRequestsDisabled = useIsDisabledFeature('profile', 'stnServiceRequests');

//   const extractLastPathSegment = (path: any) => {
//     const segments = path.split('/');
//     return segments.pop();
// }

const disableApplLoad = useFeatureIsOn('disableApplLoad');

  return (
    <Box w={'full'}>
      <VStack gap={'16px'} alignItems={'flex-start'}>
        {items?.map((item: any, i: number) => {
          const active = item.code === activePage;
          if (
            (item.code === 'myplansandservicerequests' && isServiceRequestsDisabled) ||
            (item.code === 'feedback' && isFeedbackDisabled) ||
            (item.code === 'techcare' && isTechcarePreferencesDisabled) ||
            (item.code === 'communication' && isCommunicationPreferencesDisabled) ||
            (item.code === 'changepass' && isChangePasswordDisabled)
          ) {
            return null;
          }

          return (
            <Link
              // href={getCarrierByDomain()?.toLowerCase() === 'starhub' ? '#' : item.url}
              // onClick={() => getCarrierByDomain()?.toLowerCase() === 'starhub' ? navigate(extractLastPathSegment(item.url), { replace: true }) : undefined}
              href={disableApplLoad ? '#' : item.url}
              onClick={() => disableApplLoad ? navigate(item.name === 'My Service Requests' ? '/myplansandservicerequests' : item.name === 'My Account' ? '/profile' : '/portal/plan') : undefined}
              key={`sidebar-item-${i}`}
              p={'12px'}
              w={'full'}
              bgColor={active ? '#F0F0F5' : 'none'}
              borderRadius={active ? '8px' : '0px'}
            >
              <HStack w={'full'} gap={'8px'} fontSize={'md'} fontWeight={700}>
                <Image src={item.icon} alt={item.code} />
                {/* <Text fontSize={'md'} fontWeight={700}>
                  {item.name}
                </Text> */}
                <TextScript text={item.name} translationKey={`MenuSidebar/${item.name}`} />
              </HStack>
            </Link>
          );
        })}
        <Box>
          <SignOutLink
            textAlign={'left'}
            p={'12px'}
            m={'48px 0 0 0'}
            minW={'unset'}
            color={'primary.500'}
          />
        </Box>
      </VStack>
    </Box>
  );
};

export default MenuSidebar;
