/* eslint-disable no-console */
import MainStackLayout from '../layout/MainStackLayout';
import {
  ConfirmDeviceKey,
  ResumeFlowDataKey,
  createServiceOrderDataKey,
  interactionLineKey,
  processIncidentKey,
  replacementDataKey,
  serviceFeeKey,
  LFLStockKey
} from '../modules/config/constants';
import { getDeviceImageByModel, getDeviceImages } from '../utils/get-device-images';
import { getGTMData, isInWarranty, isMalfunction, isReplacement, isSwap, saveError, showErrorPage } from '../utils/utills.tsx';
import {
  Cards,
  CustomerSupportFooter,
  // DeviceModel,
  SmartStock,
  SubHeader,
  TextScript,
  getCarrierByDomain,
  getTextScript,
  getTypeByDomain,
  useSessionStorage,
} from '@MGPD/myasurion-shared';
import { Box, Button, Checkbox, Flex, Image, Square, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Scripts from '../Scripts/asurion-script.json';
import Translationkeys from '../Scripts/translationsKeys.json';
import { createServiceOrder, getReplacementDevice, logGTMEvent, setFulfillmentMethodApi } from '../services/api';
import DeviceModel1 from '../pages/DeviceModel.tsx';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import { camelize, getAssetCapacity, getFeeInclusiveTaxText } from '../utils/helper.tsx';
import AlertIcon from './AlertIcon.tsx';
import DualRangeSlider from './DualRangeSlider';
import FilterOptionsAccordion, { Section } from './FilterOptionsAccordion';
import SmartStockContainer from './SmartStockContainer.tsx';
import CancelSrPopup from './myclaims/CancelSrPopup.tsx';
import { getCurrencySymbol, getPerilText, getProgrammeName } from '../utils/localization-helper.tsx';
import { hideIWHeaderText } from '../utils/featuresUtills.tsx';
import expertLeft from '/images/carriers/asurion/expert-default-left.png';
import expertMid from '/images/carriers/asurion/expert-default-mid.png';
import expertRight from '/images/carriers/asurion/expert-default-right.png';
import expertDesktop from '/images/carriers/asurion/expert-desktop.png';
import ChatOverlay from './ChatOverlay.tsx';
import { GTM_EVENT, INCIDENT_TYPES } from '../utils/constant.tsx';
interface CardContent {
  img: string;
  familyName: string;
  srf: string;
}

interface SCMItemInformation {
  DAXItemId: string;
  ItemType: string;
  WarehouseId?: string;
  Make: string;
  Model?: string;
  Color: string;
  // Description?: string;
  // AccessoryPrice?: string;
}

interface HorizonItemInformation {
  AssetCatalogItemId: string;
  AssetCatalogName: string;
  Make: string;
  Model: string;
  AssetFamily: string;
  AssetCapacity: string;
  AssetCatalogStartDate: string;
}

interface MainObject {
  IsLikeForLike: boolean;
  Priority: string;
  LossType: string;
  SCMItemInformation: SCMItemInformation;
  HorizonItemInformation: HorizonItemInformation;
  IsNoLongerProduced: boolean;
  IsNotCurrentlyAvailableForOrder: boolean;
  IsInStock: boolean;
  PhysicalInvent: string;
  AreMandatoryAccessoriesInStock: boolean;
  IsAvailableForBackorder: boolean;
  PhonePrice: string;
  ServiceFee: string;
  CurrencyCode: string;
  UpOrDownGradeCost: string;
  // Accessories: Accessories;
  // UpOrDownOrGuaranteedDetails: UpOrDownOrGuaranteedDetails;
}

interface serviceRequest {
  CustomerCaseId: string;
  CustomerCaseNumber: number;
  CustomerCaseStatus: string;
  ServiceRequest: {
    ServiceRequestId: string;
    ServiceRequestNumber: number;
    ServiceRequestStatus: string;
  };
}

const ReplacementContainer: React.FC = () => {
  const globalState = useCentralStore();
  const ReplacementKeys = Translationkeys.Replacement;
  const isTOM = globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === 'Asurion_TechCare';
  const [replacementResponse, setReplacementResponse] = useState<MainObject[]>([]);
  const [LFL, setLFL] = useState<MainObject[]>([]);
  const [LFLStock, setLFLStock] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<Section[]>([]);
  const [, setClickedCard] = useState<CardContent | null>(null);
  const [sliderDefaultValue, setSliderDefaultValue] = useState<[number, number]>([0, 15000]);
  const [selectedSliderValues, setSelectedSliderValues] = useState<[number, number]>([0, 15000]);
  const [showPriceRange, setShowPriceRange] = useState(false);
  const [selectedValues, setSelectedValues] = useState<{
    [sectionId: string]: { [value: string]: boolean };
  }>({});
  const [loaded, setLoaded] = useState(false);
  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const agreementData1: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];
  const [actualModel, setActualModel] = useState<{
    img: string;
    familyName: string;
    srf: string;
  }>({
    img: '',
    familyName: '',
    srf: '',
  });
  const [LFLModel, setLFLModel] = useState<{
    img: string;
    familyName: string;
    srf: string;
  }>({
    img: '',
    familyName: '',
    srf: ''    
  });

  const [, setLFLModelSession] = useSessionStorage<any>(ConfirmDeviceKey, null);

  const [cardContent, setCardContent] = useState<CardContent[]>([]);
  const [sortedOptions, setSortedOptions] = useState('');
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [question, setQuestion] = useState<string>('');

  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const [processIncidentData] = useSessionStorage<any>(processIncidentKey, null);
  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
  const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
  const R_CustomerCaseId = R_ServiceRequests?.CustomerCaseId;
  const R_FulfillmentOption = R_ServiceRequests?.FulfillmentOption;

  const navigate = useNavigate();
  const [replacementData, setReplacementData] = useSessionStorage<any>(replacementDataKey, null);
  const [, setServiceOrderData] = useSessionStorage<any>(createServiceOrderDataKey, null);
  const [, setLFLStockSession] = useSessionStorage<any>(LFLStockKey, null);
  const [interactionLineId] = useSessionStorage<any>(interactionLineKey, null);
  const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
  // const [currentAgreement] = useSessionStorage<any>(currentAgreementKey, null);
  const [ServiceFeeData] = useSessionStorage<any>(serviceFeeKey, null);
  
  const IncidentType =
    serviceRequestData?.ServiceRequest?.IncidentType || R_ServiceRequests?.IncidentType;
  const [PerilType, setPerilType] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [programmeNameLBL, setProgrammeNameLBL] = useState('')
  
  const [processIncidentResponse] = useSessionStorage<serviceRequest>(processIncidentKey, {
    CustomerCaseId: '',
    CustomerCaseNumber: 0,
    CustomerCaseStatus: '',
    ServiceRequest: {
      ServiceRequestId: '',
      ServiceRequestNumber: 0,
      ServiceRequestStatus: '',
    },
  });
  
  const isBackOrderPendingAction =    
    ResumeFlowData?.CustomerCaseStatus?.toUpperCase() === 'WORKING' &&
    ResumeFlowData?.IsWBKODeviceAvailable;
  const isWarranty = isInWarranty(processIncidentData);
  let LFLSRFFees =
    ServiceFeeData &&
    ServiceFeeData?.filter((item: any) => item?.IncidentType === IncidentType?.toUpperCase())?.[0]
    LFLSRFFees =  {TotalAmount: LFLSRFFees?.Fees?.TotalAmount, CurrencyCode: LFLSRFFees?.Fees?.CurrencyCode};
  const TermsAndConditionsKeys = Translationkeys.TermsAndConditionsPage;
  const [setFulfillmentOptions, setSetFulfillmentOptions] = useState<any>();
  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };
  const sortOptions = [
    { name: 'Best selling', value: 'Best selling' },
    { name: 'Price (Low to High)', value: 'Price (Low to High)' },
    { name: 'Price (High to Low)', value: 'Price (High to Low)' },
    { name: 'Latest release', value: 'Latest release' },
  ];

  const { ServiceRequest } = processIncidentResponse;

  const deviceServiceFeeUpdation = () => {    
    let lossType = ['UP1', 'DOWN1'];
    replacementResponse?.forEach((item) => {
      if (parseFloat(item.ServiceFee) === 0.0 && !lossType.includes(item.LossType?.toUpperCase())) {
        item.ServiceFee = LFLSRFFees.TotalAmount,
        item.CurrencyCode = LFLSRFFees.CurrencyCode
      }
    });

    setReplacementData(replacementResponse);
  };

  useEffect(() => {
    if (globalState?.state?.RPMFlag === 'INPROGRESS') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [globalState?.state?.RPMFlag]);

  useEffect(() => {
    const rpmData = globalState?.state?.ReplacementResponse
    if (rpmData) {
      if(isReplacement(IncidentType)){
        const sortedRpmData = rpmData.filter(
          (a: any) => a.LossType != 'UP1' &&  a.LossType != 'DOWN1'
        );
        setReplacementResponse(sortedRpmData);
        setReplacementData(sortedRpmData);
      }else{
        setReplacementResponse(rpmData);
        setReplacementData(rpmData);
      }
     setLoaded(true);
    }
  }, [globalState?.state?.ReplacementResponse]);

  useEffect(() => {
    if (globalState?.state?.deviceImages.length === 0) {
      getDeviceImages()
        .then((data) => {
          globalState?.dispatch({ type: ActionTypes.GET_DEVICE_IMAGES, payload: data });
        })
        .catch((err) => {
          setLoading(false);
          saveError(err, globalState);
          // showErrorPage(navigate)
        });
    }
    globalState?.dispatch({ type: ActionTypes.SET_PROCESS_INC_DATA, payload: serviceRequestData });
  }, []);
  const ServiceRequestId = ServiceRequest?.ServiceRequestId || R_ServiceRequestId;

  const flagCheck = ['INPROGRESS', 'PROCESSED'];

  const enableApiCall = () => {
    if (
      flagCheck?.includes(globalState?.state?.RPMFlag) ||
      isBackOrderPendingAction ||
      ResumeFlowData
    ) {
      return false;
    } else if (
      isReplacement(IncidentType) ||
      isMalfunction(IncidentType)
    ) {
      return setFulfillmentOptions?.ServiceRequestId !== undefined;
    } else {
      return true;
    }
  };

  const { refetch: callGetReplacementDevice } = useQuery(
    ['replacementData'],
    async () => {
      setLoading(true);
      return await getReplacementDevice(
        ServiceRequestId,
        interactionData?.InteractionLine.InteractionLineId,
        globalState?.state?.findAgreementSessionData
      );
    },
    {
      enabled: enableApiCall(),
      onSuccess: (data) => {
        setLoading(false);
        setLoaded(true);
        if (isReplacement(IncidentType)) {
          const ReplacementData =
            data.ReplacementEquipmentResults.ReplacementItems.ReplacementItem.filter(
              (a: any) => a.LossType != 'UP1' &&  a.LossType != 'DOWN1'
            );
          setReplacementResponse(ReplacementData);
          setReplacementData(ReplacementData);
        } else {
          const ReplacementData = data.ReplacementEquipmentResults.ReplacementItems.ReplacementItem;
          setReplacementResponse(ReplacementData);
          setReplacementData(ReplacementData);
        }
        let GTMData = getGTMData(GTM_EVENT.REPLACEMENT_CALL_EVENT, agreementData1, getCarrierByDomain(), getTypeByDomain(), data?.CustomerCaseNumber)                
        logGTMEvent(GTMData);
      },
      onError: (error) => {
        // let RPM: MainObject[] = obj?.ReplacementEquipmentResults?.ReplacementItems?.ReplacementItem;
        // setReplacementResponse(RPM);
        // setReplacementData(RPM);
        setLoading(false);
        // setLoaded(true);
        console.error('Error fetching replacement device:', error);
        saveError(error, globalState);
        showErrorPage(navigate);
      },
    }
  );

  useEffect(() => {
    if(replacementResponse.length > 0){
    deviceServiceFeeUpdation(); // check for LFL,NLFL zero service Fee
    const serviceFees = replacementResponse?.map((item) => parseFloat(item?.ServiceFee));
    const maxServiceFee = Math?.max(...serviceFees);
    const minServiceFee = Math?.min(...serviceFees);
    setSliderDefaultValue([Math?.round(minServiceFee), Math?.round(maxServiceFee)]);
    setSelectedSliderValues([Math?.round(minServiceFee), Math?.round(maxServiceFee)]);

    const LFLDevice: Array<MainObject> = replacementResponse?.filter((val: MainObject) => {
      return val.IsLikeForLike === true;
    });
    if (LFLDevice?.length && currencySymbol) {
      let LFLDeviceStock = LFLDevice[0]?.IsInStock;
      setLFLStock(LFLDeviceStock);
      setLFLStockSession(LFLDeviceStock);
      const updatedModel = {
        img: getDeviceImageByModel(
          globalState?.state?.deviceImages,
          LFLDevice[0]?.HorizonItemInformation?.AssetFamily,
          '',
          80
        ),
        familyName: LFLDevice[0]?.HorizonItemInformation.AssetFamily,
        srf:
          LFLDevice[0]?.ServiceFee === '0' || LFLDevice[0]?.ServiceFee === '0.00'
            ? 'SRF waived'
            : `${currencySymbol}${LFLDevice[0]?.ServiceFee}`,
      };
      const LFLModel = {
        img: getDeviceImageByModel(
          globalState?.state?.deviceImages,
          LFLDevice[0]?.HorizonItemInformation?.AssetFamily,
          '',
          80
        ),
        familyName:
          camelize(LFLDevice[0]?.HorizonItemInformation?.AssetFamily.toLowerCase())?.replace(
            /iphone/gi,
            'iPhone'
          ) +
          ' ' +
          getAssetCapacity(LFLDevice[0]) +
          ' ' +
          LFLDevice[0]?.SCMItemInformation.Color,
        srf:
          LFLDevice[0]?.ServiceFee === '0' || LFLDevice[0]?.ServiceFee === '0.00'
            ? 'SRF waived'
            : `${currencySymbol}${LFLDevice[0]?.ServiceFee}`,
      };
      setLFLModel(LFLModel);
      setLFLModelSession(LFLModel);
      setActualModel(updatedModel);
      setLFL(LFLDevice);
    }}
  }, [replacementResponse, currencySymbol]);

  const checkSessionRpmStock = () => {
    if (flagCheck?.includes(globalState?.state?.RPMFlag)) {
      return;
    } else if (replacementData) {
      setLoaded(true);
      if (isReplacement(IncidentType)) {
        const ReplacementData = replacementData?.filter((a: any) => a.LossType != 'UP1' && a.LossType != 'DOWN1');
        setReplacementResponse(ReplacementData);
        setReplacementData(ReplacementData);
      } else {
        setReplacementResponse(replacementData);
        setReplacementData(replacementData);
      }
    } else {
      callGetReplacementDevice();
    }
  };

  useEffect(() => {
    const extractedData = replacementResponse?.map((item: any) => {
      return {
        id: item.SCMItemInformation.DAXItemId,
        name: item.SCMItemInformation.Model,
        make: item.SCMItemInformation.Make,
        color: item.SCMItemInformation.Color,
        capacity: item.HorizonItemInformation.AssetCapacity,
        phonePrice: item.PhonePrice,
        srf: item?.ServiceFee,
      };
    });

    let makeOptions = [
      ...new Set(
        extractedData?.map((val) => {
          return val?.make;
        })
      ),
    ]?.sort();

    let colorOptions = [
      ...new Set(
        extractedData?.map((val) => {
          return val?.color;
        })
      ),
    ]?.sort();

    let storageOptions = [
      ...new Set(
        extractedData?.map((val) => {
          return val?.capacity;
        })
      ),
    ]?.sort((a, b) => a - b);

    let filterData = [
      {
        id: 'category',
        name: 'Brand',
        options: makeOptions?.map((val) => {
          return { value: val, label: val, name: 'Brand', checked: false };
        }),
      },
      {
        id: 'color',
        name: 'Color',
        options: colorOptions?.map((val) => {
          return { value: val, label: val, name: 'Color', checked: false };
        }),
      },
      {
        id: 'storage',
        name: 'Storage',
        options: storageOptions?.map((val) => {
          return {
            value: val == 1 ? `${val}TB` : `${val}GB`,
            label: val == 1 ? `${val}TB` : `${val}GB`,
            name: 'Storage',
            checked: false,
          };
        }),
      },
    ];
    setFilterData(filterData);
  }, [replacementResponse]);

  function extractTrueValues(obj: { [key: string]: { [key: string]: boolean } }): {
    [key: string]: string[];
  } {
    const result: { [key: string]: string[] } = {};

    for (const prop in obj) {
      const innerObj = obj[prop];
      const trueValues: string[] = [];

      for (const key in innerObj) {
        if (innerObj[key] === true) {
          trueValues.push(key);
        }
      }

      if (trueValues.length > 0) {
        result[prop] = trueValues;
      }
    }

    return result;
  }

  useEffect(() => {
    let cardDataWithoutLFL: Array<MainObject> = replacementResponse.filter((val: MainObject) => {
      return val.IsLikeForLike !== true;
    });
    cardDataWithoutLFL = cardDataWithoutLFL?.filter((val: MainObject) => {
      return (
        val?.HorizonItemInformation?.AssetFamily !== LFL[0]?.HorizonItemInformation?.AssetFamily
      );
    });

    const filterValues = {
      makes: extractTrueValues(selectedValues).category || [],
      assetCapacity:
        extractTrueValues(selectedValues).storage?.map((val) =>
          val === '1TB' ? val.replace(/TB/g, '') : val.replace(/GB/g, '')
        ) || [],
      colors: extractTrueValues(selectedValues).color || [],
    };
    if (filterValues?.makes?.length > 0) {
      cardDataWithoutLFL = cardDataWithoutLFL?.filter((item) =>
        filterValues.makes.includes(item.SCMItemInformation.Make)
      );
    }

    if (filterValues.assetCapacity?.length > 0) {
      cardDataWithoutLFL = cardDataWithoutLFL?.filter((item) =>
        filterValues.assetCapacity.includes(item.HorizonItemInformation.AssetCapacity)
      );
    }

    if (filterValues?.colors?.length > 0) {
      cardDataWithoutLFL = cardDataWithoutLFL?.filter((item) =>
        filterValues.colors.includes(item.SCMItemInformation.Color)
      );
    }

    cardDataWithoutLFL = cardDataWithoutLFL?.filter((item) => {
      const srf = parseInt(item?.ServiceFee);
      return srf >= selectedSliderValues[0] && srf <= selectedSliderValues[1];
    });

    if (sortedOptions && sortedOptions == 'Best selling') {
      cardDataWithoutLFL.sort((a, b) => {
        const srfA = Number(a.PhysicalInvent);
        const srfB = Number(b.PhysicalInvent);
        return srfB - srfA;
      });
    }
    if (sortedOptions && sortedOptions == 'Latest release') {
      cardDataWithoutLFL.sort(
        (a, b) =>
          new Date(b.HorizonItemInformation.AssetCatalogStartDate).getTime() -
          new Date(a.HorizonItemInformation.AssetCatalogStartDate).getTime()
      );
    }

    let AssetFamily = [
      ...new Set(cardDataWithoutLFL?.map((item) => item?.HorizonItemInformation?.AssetFamily)),
    ];
    let cardData: CardContent[] =
      AssetFamily &&
      AssetFamily?.map((val) => {
        return {
          img: getDeviceImageByModel(globalState?.state?.deviceImages, val, '', 80),
          familyName: val,
          srf: replacementResponse
            .filter((item) => {
              return item.HorizonItemInformation?.AssetFamily === val;
            })
            .sort((a, b) => {
              const srfA = Number(a.ServiceFee);
              const srfB = Number(b.ServiceFee);
              return srfA - srfB;
            })[0].ServiceFee,
        };
      });
    if (sortedOptions && sortedOptions == 'Price (Low to High)') {
      cardData.sort((a, b) => {
        const srfA = Number(a.srf);
        const srfB = Number(b.srf);
        return srfA - srfB;
      });
    }
    if (sortedOptions && sortedOptions == 'Price (High to Low)') {
      cardData.sort((a, b) => {
        const srfA = Number(a.srf);
        const srfB = Number(b.srf);
        return srfB - srfA;
      });
    }

    cardData = cardData.map((val) => {
      return {
        img: getDeviceImageByModel(globalState?.state?.deviceImages, val.familyName, '', 80),
        familyName: val.familyName,
        srf: val.srf === '0' || val.srf === '0.00' ? 'SRF waived' : `SRF ${currencySymbol}${val.srf}`,
      };
    });

    if (LFL.length > 0) {
      cardData.unshift({
        img: getDeviceImageByModel(
          globalState?.state?.deviceImages,
          LFL[0].HorizonItemInformation.AssetFamily,
          '',
          80
        ),
        familyName: LFL[0].HorizonItemInformation.AssetFamily,
        srf:
          LFL[0].ServiceFee === '0' || LFL[0].ServiceFee === '0.00'
            ? 'SRF waived'
            : `SRF ${currencySymbol}${LFL[0].ServiceFee}`,
      });
    }

    setCardContent(cardData);
  }, [replacementResponse, LFL, sortedOptions, selectedSliderValues, selectedValues]);

  const handleCardClick = (card: any) => {
    setClickedCard(card);
    globalState?.dispatch({ type: ActionTypes.SET_RPM_DEVICE, payload: card });
    navigate('/deviceselection');
  };

  const handleSliderChange = (values: [number, number]) => {
    setShowPriceRange(true);
    setSelectedSliderValues(values);
  };
  const AssetFamily = LFL[0]?.HorizonItemInformation?.AssetFamily;
  const make = AssetFamily?.split(' ')?.[0] || ResumeFlowData?.ReplacementAsset?.Make;
  const handleSliderCancel = () => {
    setShowPriceRange(false);
    setSelectedSliderValues(sliderDefaultValue);
  };

  const CreateServiceOrderParameters = {
    InteractionLineId: interactionLineId,
    ApprovedServiceFeeChange: false,
    ServiceOrder: {
      FulfillmentMethodType: 'ADVEXCH',
      ServiceRequestId: ServiceRequest.ServiceRequestId || R_ServiceRequestId,
      CustomerCaseId: processIncidentResponse.CustomerCaseId || R_CustomerCaseId,
      ServiceOrderLines: {
        ServiceOrderLine: [
          {
            ServiceOrderLineType: 'PHN',
            Quantity: 1,
            AssetCatalogId: LFL[0]?.HorizonItemInformation?.AssetCatalogItemId,
            VendorItemId: LFL[0]?.SCMItemInformation?.DAXItemId,
            Priority: 'MNDTRY',
            VenderItemType: LFL[0]?.SCMItemInformation?.ItemType,
            VendorData: {
              IsLikeForLike: true,
              IsSimCard: false,
              IsInStock: LFLStock,
            },
          },
        ],
      },
    },
  };

  const { refetch: createServiceOrderRequest } = useQuery(
    ['createServiceOrderRequest'],
    async () => await createServiceOrder(CreateServiceOrderParameters, interactionLineId, globalState?.state?.findAgreementSessionData),
    {
      enabled: false,
      onSuccess: (data) => {
        setLoading(false);
        setServiceOrderData(data);
        let GTMData = getGTMData(GTM_EVENT.SERVICE_ORDER_EVENT, agreementData1, getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)                
        logGTMEvent(GTMData);
        globalState?.dispatch({ type: ActionTypes.SET_SERVICE_ORDER_DATA, payload: data });
        if (!LFLStock && !(isTOM || globalState?.state?.isMaxis)) {
          handleBackOrderNavigation();
        } else {
          handleNavigation();
        }
      },
      onError: (error) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      },
    }
  );

  const handleBackOrderNavigation = () => {
      // for IW and backorder flow redirect to SR review page
      navigate('/reviewhold', { replace: true });
  };

  const handleNavigation = () => {
    if (isMalfunction(IncidentType)) {
      
      // this is for malfunction with or without back order device stock flow
      if (make === 'APPLE') navigate('/fmip', { replace: true });
      else navigate('/deliveryoptions', { replace: true });

    } else if (isSwap(IncidentType) ) {
      if (make === 'APPLE' && !isSwapConversion) navigate('/fmip', { replace: true });
      // else if(isBackOrderPendingAction){
      //   if(hasDataTransferFeature(IncidentType, agreementData1?.ClientOffer?.ClientOfferName, features)){
      //     navigate('/datatransfer', { replace: true });  
      //   } else navigate('/deliveryoptions', { replace: true });
      // } 
      else navigate('/terms', { replace: true });
    } else if (isReplacement(IncidentType)) {
      // this is for Replacement with back order device stock flow
      if (make === 'APPLE') navigate('/fmip', { replace: true });
      else navigate('/terms', { replace: true });
    } else {
      navigate('/terms', { replace: true });
    }
  };

  const onYourModelClick = () => {
    if (isBackOrderPendingAction) {
      globalState?.dispatch({ type: ActionTypes.SRF, payload: LFLModel.srf?.replace(/[^.0-9]/g,"") });
      handleNavigation();
    } else {
      globalState?.dispatch({ type: ActionTypes.SRF, payload: LFL[0].ServiceFee?.replace(/[^.0-9]/g,"") });
    // Service Order API Call
    setLoading(true);
    createServiceOrderRequest();
    }
  };

  const getSelectedSortOption = (val: string) => {
    setSortedOptions(val);
    console.log("setSortedOptions:",val)
  };
  
  const callSetFulfillmentOptions = () => {
    // setLoading(true);
    setFulfillmentMethodApi(interactionLineId, 'RPLCMNT', ServiceRequestId, globalState?.state?.findAgreementSessionData)
      .then((data) => {
        // setLoading(false);
        setSetFulfillmentOptions(data);
        checkSessionRpmStock();
        let GTMData = getGTMData(GTM_EVENT.REPLACEMENT_SCREEN_EVENT, agreementData1, getCarrierByDomain(), getTypeByDomain(), data?.CustomerCaseNumber)                
        logGTMEvent(GTMData);
      })
      .catch((error) => {
        saveError(error, globalState);
        showErrorPage(navigate);
        setLoading(false)
      });
  };

  useEffect(() => {
    getCurrency();
    getPerilLbl();
    getProgrammeNameLbl();
    let GTMData = getGTMData(GTM_EVENT.REPLACEMENT_SCREEN_EVENT, agreementData1, getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)                        
    logGTMEvent(GTMData);
  },[])

  useEffect(() => {
    if(PerilType && PerilType?.length > 0){
      getSRFText();      
      getmalfunctionsTerms();      
      getBackOrderTermsText();
      getBackorderAccept();
      getAcceptText();
    }
  }, [PerilType]);

  useEffect(() => {
    if(programmeNameLBL && programmeNameLBL?.length > 0){
      getSwapTerms();
      getReplacementTerms();
    }
  }, [programmeNameLBL]);

  const getPerilLbl = async () => {
    const peril = await getPerilText(IncidentType?.toUpperCase(), IncidentType);
    setPerilType(peril);
  }

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  }

  const getProgrammeNameLbl = async () => {
    const programmeName = await getProgrammeName(agreementData1?.ClientOffer?.ClientOfferName);
    setProgrammeNameLBL(programmeName);
  }

  const [malfunctionsTerms, setmalfunctionsTerms] = useState('');
  const [swapTerms, setSwapTerms] = useState('');
  const [replacementTerms, setReplacementTerms] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  // const [MalfunctionAcceptText, setMalfunctionAcceptText] = useState('');
  const [BackOrderTermsText, setBackOrderTermsText] = useState('');
  const [BackorderAccept, setBackorderAccept] = useState('');
  const [AcceptText, setAcceptText] = useState('');
  const [SRFText, setSRFText] = useState('');

  const isSwapConversion = globalState?.state?.SwapConversion

  const getSRFText = async () => {
    const SRFText = await getTextScript(
      `${Translationkeys.Global.PageName}/${Translationkeys.Global.SRFLBL}`,
      Scripts.Global.SRFLBL
    );
    setSRFText(SRFText);
  };

  const getSwapTerms = async () => {
    const SwaptermsText = await getTextScript(
      `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.SwapTerms}`,
      `${Scripts.TermsAndConditionsNew.SwapTerms}`,
      'en-US',
      [programmeNameLBL]
    );
    setSwapTerms(SwaptermsText);
  };

  const getReplacementTerms = async () => {
    const returnedText = await getTextScript(
      `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.ReplacementTerms}`,
      Scripts.TermsAndConditionsNew.ReplacementTerms,
      'en-US',
      [programmeNameLBL]
    );
    setReplacementTerms(returnedText);
  };
   
  const getmalfunctionsTerms = async () => {
    const returnedText = await getTextScript(
      `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.malfunctionsTerms}`,
      Scripts.TermsAndConditionsNew.malfunctionsTerms
    );
    setmalfunctionsTerms(returnedText);
  };

  const getBackorderAccept = async () => {
    const returnedText = await getTextScript(
      `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BackorderAccept}`,
      `${(isTOM || globalState?.state?.isMaxis) ? Scripts.TermsAndConditionsNew.AcknowledgeTextTOM : Scripts.TermsAndConditions.BackOrder.checkBoxLbl}`
    );
    setBackorderAccept(returnedText);
  };

  const getBackOrderTermsText = async () => {
    const BackOrderTermsText = await getTextScript(
      `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BackorderNewTerms}`,
      `${(isTOM || globalState?.state?.isMaxis) ? Scripts.TermsAndConditionsNew.BackOrdertermsTOM : Scripts.TermsAndConditionsNew.BackOrderterms}`,
      'en-US',
      [programmeNameLBL]
    );
    setBackOrderTermsText(BackOrderTermsText);
  };

  // const getMalfunctionAcceptText = async () => {
  //   const RepairAcceptText = await getTextScript(
  //     `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.malfunctionAccept}`,
  //     `I accept the Device Malfuntion Terms and Conditions`
  //   );
  //   setMalfunctionAcceptText(RepairAcceptText);
  // };

  const getAcceptText = async () => {
    const AcceptText = await getTextScript(
      `${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.AcceptText}`,
      `I accept the {1} Terms and Conditions`,
      'en-US',
      [camelize(PerilType)]
    );
    setAcceptText(AcceptText);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    // onchange(e.target.checked);
  };

  useEffect(() => {
    if (isReplacement(IncidentType) && !R_FulfillmentOption) {
      // setFulfillmentMethodApi(interactionLineId, 'RPLCMNT', ServiceRequestId);
      callSetFulfillmentOptions();
    } else if (isMalfunction(IncidentType) && !R_FulfillmentOption && isWarranty) {
      callSetFulfillmentOptions();
    } else if (isBackOrderPendingAction && currencySymbol) {
      // do not call replacement API here    
      const LFLModel = {
        img: getDeviceImageByModel(
          globalState?.state?.deviceImages,
          ResumeFlowData?.ReplacementAsset?.AssetCatalogName,
          '',
          80
        ),
        familyName: ResumeFlowData?.ReplacementAsset?.AssetCatalogName,
        srf: currencySymbol+LFLSRFFees.TotalAmount,
      };

      setLFLModel(LFLModel);
      setLFLModelSession(LFLModel);

      setLFLStock(true);
      setLFLStockSession(true);
      setLoaded(true);
    } else {      
      checkSessionRpmStock();
    }
  }, [IncidentType, currencySymbol]);

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  };
  // const Hide = IncidentType?.toUpperCase() === 'FAULT REPAIR' ? true : false;
  
  return (
    <Box id={'myasurion-triage'} paddingTop={'54px'} marginX={'auto'} w="full">
      <MainStackLayout gap={'4px'} spacing={0}>
        <Box
          w={{ base: 'full', lg: '80%' }}
          marginX={{ base: '', lg: 'auto' }}
          mb={{ base: '0', lg: '20px' }}
        >
          <SubHeader
            backText={''}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={false}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}
          ></SubHeader>
        </Box>
        {isWarranty || isBackOrderPendingAction ? (
          <Box margin={'16px'}>
            {loaded && (
              <>
                <Flex justify={'center'}>
                  <Box marginRight={{ base: '', lg: '9rem' }}>
                    <Text fontSize={'2rem'}>
                      <TextScript
                        text={
                          isBackOrderPendingAction || hideIWHeaderText(agreementData1?.ClientOffer?.ClientOfferName)
                            ? Scripts.Replacement.HeaderRepair
                            : Scripts.InwarrantyScripts.HeaderText
                        }
                        translationKey={`${ReplacementKeys.PageName}/${
                          isBackOrderPendingAction || hideIWHeaderText(agreementData1?.ClientOffer?.ClientOfferName)
                            ? ReplacementKeys.KeyHeaderReplacement
                            : ReplacementKeys.KeyInwarrantyHeader
                        }`}
                        dynamicValues={[PerilType]}
                      />
                    </Text>
                    <Text fontSize={'1.12rem'}>{LFLModel.familyName}</Text>
                  </Box>
                  <Square maxWidth={'148px'} maxHeight={'148px'} margin={'20px'}>
                    <Image src={LFLModel.img} alt="your model" px={'0.25rem'} />
                  </Square>
                </Flex>

                {isBackOrderPendingAction && (
                  <Box
                    w={{ base: 'full', lg: '50%' }}
                    marginX={{ base: '', lg: 'auto' }}
                    marginY={'24px'}
                  >
                    <Flex justify={{ base: '', lg: 'center' }}>
                      <AlertIcon marginRight={'8px'} height={'1.5rem'} width={'1.5rem'} />
                      <Text color={'#DB1B1B'}>
                        <TextScript
                          text={Scripts.Replacement.backorderAlert}
                          translationKey={`${ReplacementKeys.PageName}/${ReplacementKeys.keyBackOrderAlert}`}
                        />
                      </Text>
                    </Flex>
                  </Box>
                )}
                <Box w="full" m="0">
                {isWarranty && <Box
                    className={''}
                    w={{ base: 'full', lg: '50%' }}
                    margin={{ base: '0', lg: 'auto' }}
                    pb={{ base: '0', lg: '0' }}
                  >
                    {LFLStock && (
                      <Text fontSize={'24px'}>
                        <TextScript
                          text="Terms And Conditions"
                          translationKey={`${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.HeaderIW}`}
                        />
                      </Text>
                    )} 
                    
                    {!LFLStock && (
                      <Text>
                        <TextScript
                          text={`${(isTOM || globalState?.state?.isMaxis) ? Scripts.TermsAndConditionsNew.BackorderHeaderTOM : Scripts.TermsAndConditions.BackOrder.header}`}
                          translationKey={`${TermsAndConditionsKeys.PageName}/${TermsAndConditionsKeys.BackorderTerms}`}
                        />
                      </Text>
                    )}
                    <Box marginTop={'10px'} mx={'10px'}>
                      {!LFLStock ? (
                        <Box>
                          {BackOrderTermsText && (
                            <div dangerouslySetInnerHTML={{ __html: BackOrderTermsText }} />
                          )}
                        </Box>
                      ) : isMalfunction(IncidentType) ? (
                        <Box>
                          {malfunctionsTerms && (
                            <div dangerouslySetInnerHTML={{ __html: malfunctionsTerms }} />
                          )}
                        </Box>
                      ) : isSwap(IncidentType) ? (
                        <Box>
                          {swapTerms && <div dangerouslySetInnerHTML={{ __html: swapTerms }} />}
                        </Box>
                      ) : isReplacement(IncidentType) ? (
                        <Box>
                          {replacementTerms && (
                            <div dangerouslySetInnerHTML={{ __html: replacementTerms }} />
                          )}
                        </Box>
                      ) : (
                        []
                      )}
                    </Box>
                    <Box
                      display={{ base: 'none', lg: 'block' }}
                      position="fixed"
                      bottom={0}
                      w="full"
                      bgColor={{ base: '', lg: '#faf9f5' }}
                    >
                      <hr
                        style={{
                          border: 'none',
                          borderTop: '2px solid #adaaaa',
                        }}
                      />
                    </Box>
                    <Flex
                      pb={{ base: '0', lg: '100px' }}
                      mb={'auto'}
                      w={{ base: 'full', lg: '100%' }}
                      marginLeft={'auto'}
                      marginRight={'auto'}
                      justifyContent={'start'}
                    >
                      <Checkbox
                        marginBottom={'24px'}
                        marginTop={'24px'}
                        alignItems={'flex-start'}
                        size="lg"
                        onChange={handleCheckboxChange}
                        colorScheme="primary"
                      ></Checkbox>
                      <Text
                        marginBottom={'24px'}
                        marginTop={'24px'}
                        marginLeft={'8px'}
                        size={'16px'}
                      >
                        {!LFLStock ? `${BackorderAccept}` : AcceptText}
                      </Text>
                    </Flex>
                  </Box>}
                  <Box display={{ base: 'block', lg: 'none' }}>
                    {LFLModel.srf !== '' && SRFText !== '' && (
                      <Box marginBottom={'8px'}>
                        {isBackOrderPendingAction || isWarranty ? SRFText : ''}{' '}
                        <b>
                          {isBackOrderPendingAction
                            ? `${LFLModel.srf} ${getFeeInclusiveTaxText(LFLSRFFees?.CurrencyCode)}`
                            : isWarranty ? `${currencySymbol}0.00` : ''}
                        </b>
                      </Box>
                    )}
                    <Box display="flex" justifyContent="center">
                      <Button
                        isDisabled={!isChecked && !isBackOrderPendingAction}
                        color="white"
                        colorScheme="primary"
                        width="343px"
                        mb={'16px'}
                        onClick={() => onYourModelClick()}
                      >
                        <TextScript
                          text={Scripts.Global.Proceed}
                          translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Proceed}`}
                        />
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    display={{ base: 'none', lg: 'block' }}
                    position="fixed"
                    bottom={0}
                    marginTop={'auto'}
                    w={{ base: 'full', lg: '100%' }}
                    bgColor={{ base: '', lg: '#faf9f5' }}
                  >
                    <hr
                      style={{
                        border: 'none',
                        borderTop: '2px solid #adaaaa',
                      }}
                    />
                    <Flex direction="row" p={4} justifyContent="space-between" w={'50%'} mx={'auto'}>
                        {LFLModel.srf !== '' && SRFText !== '' && (
                          <Box marginBottom={'8px'}>
                            <Text fontSize="14px">
                              {isBackOrderPendingAction || isWarranty ? SRFText : ''}{' '}
                            </Text>
                            <Text fontWeight="bold" fontSize="24px">
                              {isBackOrderPendingAction
                                ? `${LFLModel.srf} ${getFeeInclusiveTaxText(LFLSRFFees?.CurrencyCode)}`
                                : isWarranty ? `${currencySymbol}0.00` : ''}
                            </Text>
                          </Box>
                        )}
                        <Box display="flex" marginStart={'auto'}>
                          <Button
                            isDisabled={!isChecked && !isBackOrderPendingAction}
                            color="white"
                            colorScheme="primary"
                            width="200px"
                            mb={'16px'}
                            onClick={() => onYourModelClick()}
                          >
                            <TextScript
                              text={Scripts.Global.Proceed}
                              translationKey={`${Translationkeys.Global.PageName}/${Translationkeys.Global.Proceed}`}
                            />
                          </Button>
                        </Box>
                      {/* </Box> */}
                      {/* {isWarranty && !isBackOrderPendingAction && SRFText && (
                        <Box marginBottom={'8px'}>
                          <Text fontSize="14px">{SRFText}</Text>
                          <Text fontWeight="bold" fontSize="24px">
                            {`${currencySymbol}${LFLModel.srf}`}
                          </Text>
                        </Box>
                      )} */}

                      <Box></Box>
                    </Flex>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <Box w={{ base: 'full', lg: '70%' }} margin={{ base: '0', lg: '0 auto' }}>
            {loaded && PerilType && (
              <>
                {/* <Box display={{ base: 'block', lg: 'none' }}>
                  <DeviceModel
                    incidentType={ PerilType ||
                      (isReplacement(IncidentType) ? camelize(INCIDENT_TYPES.REPLACEMENT) : camelize(INCIDENT_TYPES.SWAP))
                    }
                    DeviceDetails={LFLModel || { img: '', familyName: '', srf: '' }}
                    inStock={LFLStock}
                    onClickCallback={onYourModelClick}
                  />
                </Box>
                <Box display={{ base: 'none', lg: 'block' }}> */}
                  <DeviceModel1
                    DeviceDetails={LFLModel || { img: '', familyName: '', srf: '' }}
                    inStock={LFLStock}
                    incidentType={ PerilType ||
                      (isReplacement(IncidentType) ? camelize(INCIDENT_TYPES.REPLACEMENT) : camelize(INCIDENT_TYPES.SWAP))
                    }
                    onClickCallback={onYourModelClick}
                    // LFLDetails={LFL}
                    // showBackOrderBtnForNCANLP={cardContent?.length === 1 ? true : false}
                  />
                {/* </Box> */}
              </>
            )}
            {/* {IncidentType?.toUpperCase() === 'REPLACEMENT' && !LFLStock && (LFL?.[0]?.IsNoLongerProduced || LFL?.[0]?.IsNotCurrentlyAvailableForOrder) ?
              <></>
              : */}
              {!isBackOrderPendingAction && <Box>
                <Box marginTop={'1rem'} display={{ base: 'block', lg: 'none' }}>
                  <SmartStock
                    sidebarBody={
                      <>
                        <DualRangeSlider
                          selectedSliderValues={selectedSliderValues}
                          handleSliderChange={handleSliderChange}
                          handleSliderCancel={handleSliderCancel}
                          showPriceRange={showPriceRange}
                          defaultSliderValues={sliderDefaultValue}
                        />

                        <FilterOptionsAccordion
                          selectedValues={selectedValues}
                          setSelectedValues={setSelectedValues}
                          filterArray={filterData}
                          pageName={ReplacementKeys.PageName}
                        />
                      </>
                    }
                    SortOptions={sortOptions}
                    getSelectedOption={getSelectedSortOption}
                  />
                  <Box marginTop={'24px'} paddingX={'16px'}>
                    <Cards
                      cardContent={cardContent}
                      actualModel={actualModel}
                      onClickCallback={handleCardClick}
                    />
                  </Box>
                </Box>
                {/* desktop view */}
                <Flex direction="row" marginTop={'1rem'} justifyContent={'center'} display={{ base: 'none', lg: 'flex' }}>
                  <Box w="30%" mr="30px">
                    <Box>
                      <Button
                        fontSize={'24px'}
                        variant={'link'}
                        colorScheme="black"
                        fontWeight="bold"
                        // leftIcon={<ArrowBackIcon />}
                      >
                        <TextScript
                          text={'Filter'}
                          translationKey={`${ReplacementKeys.PageName}/${ReplacementKeys.keyFilterBtn}`}
                        />
                      </Button>
                    </Box>
                    <Box border="1px" borderColor={'#A5AAAF'} p="20px">
                      <Box>
                        <DualRangeSlider
                          selectedSliderValues={selectedSliderValues}
                          handleSliderChange={handleSliderChange}
                          handleSliderCancel={handleSliderCancel}
                          showPriceRange={showPriceRange}
                          defaultSliderValues={sliderDefaultValue}
                        />
                        <FilterOptionsAccordion
                          selectedValues={selectedValues}
                          setSelectedValues={setSelectedValues}
                          filterArray={filterData}
                          pageName={ReplacementKeys.PageName}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <SmartStockContainer
                      sidebarBody={<></>}
                      SortOptions={sortOptions}
                      getSelectedOption={getSelectedSortOption}
                    />
                    <Box marginTop={'24px'} paddingX={'16px'}>
                      <Cards
                        cardContent={cardContent}
                        actualModel={actualModel}
                        onClickCallback={handleCardClick}
                      />
                    </Box>
                  </Box>
                </Flex>
              </Box>
            }
            <Box marginTop={'2.5rem'}>
              <CustomerSupportFooter
                keywords={[
                  'Like for like',
                  'Service Request fee',
                  'Storage capacity',
                  'Wipe my phone',
                ]}
                title="Need help?"
                heading="Hey 👋 I’m Anna, your Tech specialist."
                subheading="Our team is here to help!"
                searchTitle="You might be searching for this..."
                onSendQuestion={(qq) => {
                  setQuestion(qq);
                }}
                doAnimate={true}
                images={[
                  {
                    position: 'left',
                    url: expertLeft,
                    view: 'mobile',
                  },
                  {
                    position: 'middle',
                    url: expertMid,
                    view: 'mobile',
                  },
                  {
                    position: 'right',
                    url: expertRight,
                    view: 'mobile',
                  },
                  {
                    position: 'middle',
                    url: expertDesktop,
                    view: 'desktop',
                  },
                ]}
              />
            </Box>
          </Box>
        )}
      </MainStackLayout>
      <ChatOverlay question={question} onChatClose={() => setQuestion('')} />
      <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
    </Box>
  );
};

export default ReplacementContainer;
