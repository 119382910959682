/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-console */
import Scripts from '../Scripts/asurion-script.json';
import LogisticsKeys from '../Scripts/translationsKeys.json';
import {
  assetDataKey,
  ConfirmDeviceKey,
  // GetCitiesKey,
  LFLStockKey,
  ResumeFlowDataKey,
  currentAgreementKey,
  deliveryAddressKey,
  deliveryDateKey,
  deliveryTimeCodeKey,
  deliveryTimeKey,
  deliveryTypeKey,
  nightDeliveryFeeKey,
  processIncidentKey,
  serviceFeeKey,
  shippingMethodResponseKey,
  storeAddressKey,
} from '../modules/config/constants';
import { addressStandardize, getCitiesByPostalCode, getUpdateShippingDetails, logGTMEvent, shippingmethodsv1Api, doGetAddressQAS } from '../services/api.ts';
import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import { getDeviceImageByModel } from '../utils/get-device-images';
import { getCurrencySymbol, getGlobalDateFormat } from '../utils/localization-helper.tsx';
import { saveError, showErrorPage, timeConvert24to12Hr, getDateAndDay, isWalkIn, isPUR, isDelivery as isDeliveryType, getTime, isSwap, isMalfunction, isReplacement, getGTMData, isUpgrade } from '../utils/utills.tsx';
import {
  DeliveryDetails,
  LogisticsAndFulfilment,
  PickupSchedule,
  SubHeader,
  TextScript,
  getTextScript,
  useConfig,
  useSessionStorage,
  getCarrierByDomain,
  getTypeByDomain
} from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Input, Link } from '@chakra-ui/react';
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useNavigate } from 'react-router-dom';

import translationsKeys from '../Scripts/translationsKeys.json';
import { Regex, camelize, getCountryCode, isClaimWithinCutoff, getCountryName, getAssetCapacity } from '../utils/helper.tsx';
import WalkingDetails from './WalkingDetails';
import CancelSrPopup from './myclaims/CancelSrPopup.tsx';
import { getAddressFields, getPostalCodeLength, hasQAS, callShippingMethodv1, getDeliveryOptions, getInlineChangeAddressFlag, getDeliverySlot, isJBUpgradeFlow } from '../utils/featuresUtills.tsx';
import ChangeAddress from './ChangeAddress.tsx';
import features from '../utils/features.json';
import { AutoCompleteOption, ChangeAddressFieldConfig } from '../types/types.ts';
import { EMPTY_ADDRESS_OPTION } from '../utils/constant.tsx';
import PredictiveAddress from './component/PredictiveAddress/PredictiveAddress.tsx';
import _ from '../utils/lodashUtils.ts';
import { useMutation } from '@tanstack/react-query';
import { GTM_EVENT } from '../utils/constant.tsx';

const LogisticsAndFulfilmentContainer = () => {
  const navigate = useNavigate();
  const globalState = useCentralStore();
  const isTOM =
    globalState?.state?.sessionResponse?.InitializeResponse?.ClientName === 'Asurion_TechCare';
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [isPostalCodeChanged, setIsPostalCodeChanged] = useState(false)
  const [servicefeeList] = useSessionStorage<any>(serviceFeeKey, null);
  const [find_agreement] = useSessionStorage<any>(currentAgreementKey, null);
  const [confirmedDevice] = useSessionStorage<any>(ConfirmDeviceKey, null);
  const [assetData] = useSessionStorage<any>(assetDataKey, null);
  // const [citiesData, setCitiesData] = useSessionStorage<any>(GetCitiesKey, null);
  const [LFLStock] = useSessionStorage<any>(LFLStockKey, null);
  const LogisticFormtranslationKey = translationsKeys.LogisticForm;
  const agreementData1: any =
    globalState?.state?.sessionResponse?.FindAgreementsResults?.Agreements?.Agreement[0];

  const interactionData = globalState?.state?.sessionResponse?.Interaction;
  const InitializeResponse = globalState?.state?.sessionResponse?.InitializeResponse;

  const alternateContactNumber = find_agreement?.ContactPoints?.ContactPoint?.filter(
    (val: any) => val?.ContactPointType === 'MOBILE'
  )?.[0]?.PhoneNumber

  const setLoading = (val: boolean) => {
    globalState?.dispatch({ type: ActionTypes.LOADING, payload: val });
  };

  // const assetData1 = agreementData1?.Assets?.Asset?.filter(
  //   (a: any) => a?.AssetInstance === 'ENROLLED'
  // )?.[0];

  let assetData1 =  agreementData1?.Assets?.Asset?.filter(
    (asset: any) => asset?.AssetInstance === 'ENROLLED'
  )?.[0];
    if(assetData1 === undefined) {
      assetData1 = agreementData1?.Assets?.Asset?.filter(
        (asset: any) => asset?.AssetInstance === 'REPLACED'
      )?.[0];
    }

  const nightDeliveryFee = servicefeeList?.filter(
    (item: any) => item.IncidentType === 'NIGHT DELIVERY FEE'
  )[0].Fees.TotalAmount;

  const [, setNighDeliverytFees] = useSessionStorage<any>(nightDeliveryFeeKey, null);

  const [, setSessionUpdatedDeliveryAddress] = useSessionStorage<any>(deliveryAddressKey, null);
  const [, setSessionUpdatedDeliveryDate] = useSessionStorage<any>(deliveryDateKey, null);
  const [, setSessionUpdatedDeliveryTime] = useSessionStorage<any>(deliveryTimeKey, null);
  const [, setSessionUpdatedDeliveryTimeCode] = useSessionStorage<any>(deliveryTimeCodeKey, null);
  const [walkingQuestionText, setwalkingQuestionText] = useState('');
  const [confirmDeliveryScheduleText, setconfirmDeliveryScheduleText] = useState('');
  const [selectDeliveryQuestionText, setselectDeliveryQuestionText] = useState('');
  const [confirmAddressQuestionText, setconfirmAddressQuestionText] = useState('');
  const [EnterNewDeliveryAddressText, setEnterNewDeliveryAddressText] = useState('');
  const [EnterNewPickupAddressText, setEnterNewPickupAddressText] = useState('');
  const [cities, setCities] = useState([]);
  const [district, setDistrict] = useState([]);
  const [provience, setProvience] = useState<string[]>([]);
  const [showAlternateContactField, setShowAlternateContactField] = useState(false);
  const [alternateContactNo, setAlternateContactNo] = useState('');
  const [alternateContactNoPlaceholder, setAlternateContactNoPlaceholder] = useState('');
  const [invalidMobile, setInvalidMobile] = useState(false);
  
  const [fields, setFields] = useState(getAddressFields(agreementData1?.ClientOffer?.ClientOfferName))

  const [getShippingMethodResponse] = useSessionStorage<any>(shippingMethodResponseKey, null);
  const [srDeliveryType] = useSessionStorage<any>(deliveryTypeKey, null);

  const isDelivery = isDeliveryType(srDeliveryType);
  const isPickupAndReturn = isPUR(srDeliveryType);

  const [ResumeFlowData] = useSessionStorage<any>(ResumeFlowDataKey, null);
  const R_ReplacementAsset = ResumeFlowData?.ReplacementAsset;

  const [shippingMethodResponse, setShippingMethodResponse] = useState(
    getShippingMethodResponse || []
  );
  const [isNewAddress, setIsNewAddress] = useState(false);

  const address1 = find_agreement?.Address?.Address1;
  const address2 = find_agreement?.Address?.Address2;
  const address3 = find_agreement?.Address?.Address3;
  const city = find_agreement?.Address.City;
  const _postalCode = find_agreement?.Address.PostalCode;
  const addressjoin = { address1, address2, address3, city, _postalCode };
  const fulladdress = Object.values(addressjoin)
    ?.filter((val) => val != undefined)
    ?.join(', ');

  const isUpgradeFlow = isJBUpgradeFlow(agreementData1?.ClientOffer?.ClientOfferName)

  const sampleDeliverAddress = 'Blk 201E Tampines Street #01-86, Suntec Tower One';

  //localization color theme changes
  const { data } = useConfig();
  const { theme } = data;
  const { colors } = theme;
  const { accent } = colors;

  const [preferredWalkingStoreLocation, setPreferredWalkingStoreLocation] = useState('');
  const [preferredWalkingTimeSlot, setPreferredWalkingTimeSlot] = useState('');
  const [preferredWalkingDate, setPreferredWalkingDate] = useState('');

  const [isAddressConfirm, setIsAddressConfirm] = useState(false);
  const [isDateTimeslotConfirm, setIsDateTimeslotConfirm] = useState(false);
  const [changeDateTimeFlag, setChangeDateTimeFlag] = useState(false);
  const [changeAddressFlag, setChangesAddressFlag] = useState(false);

  // Predictive Address
  const [predictiveAddressFlag, setPredictiveAddressFlag] = useState(false);
  const [predictiveAddressValue, setPredictiveAddressValue] = useState<string>('');
  const [predictiveAddressOption, setPredictiveAddressOption] =
    useState<AutoCompleteOption>(EMPTY_ADDRESS_OPTION);

  //Delivery
  const [updatedDeliveryAddress, setUpdatedDeliveryAddress] = useState(fulladdress);
  const [updatedDeliveryDate, setUpdatedDeliveryDate] = useState('');
  const [updatedDeliveryTime, setUpdatedDeliveryTime] = useState('');
  // Pick-up
  const [updatedPickupAddress, setUpdatedPickupAddress] = useState(sampleDeliverAddress);
  const [updatedPickupDate, setUpdatedPickupDate] = useState('');
  const [updatedPickupTime, setUpdatedPickupTime] = useState('');
  // Return
  const [updatedReturnAddress, setUpdatedReturnAddress] = useState(sampleDeliverAddress);
  const [updatedReturnDate, setUpdatedReturnDate] = useState('');
  const [updatedReturnTime, setUpdatedReturnTime] = useState('');

  const [isNightFeesApplicable, setIsNightFeesApplicable] = useState(false);
  const [showAddUpdateError, setShowAddUpdateError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('$')
  const [dateFormat, setDateFormat] = useState<string>('');

  const AssetFamily =
    (confirmedDevice && confirmedDevice?.familyName?.replace(/iphone/gi, 'iPhone')) ||
    (confirmedDevice &&
      camelize(confirmedDevice[0]?.HorizonItemInformation?.AssetFamily?.toLowerCase())?.replace(
        /iphone/gi,
        'iPhone'
      ) +
      ' ' + getAssetCapacity(confirmedDevice[0]) + ' ' +
      confirmedDevice[0]?.SCMItemInformation?.Color) ||
    camelize(R_ReplacementAsset?.Make?.toLowerCase()) +
    ' ' +
    camelize(R_ReplacementAsset?.AssetCatalogName.split(' ')?.slice(1)?.join(' '))?.replace(
      /iphone/gi,
      'iPhone'
    )?.replace('gb', 'GB');

  const image =
    (confirmedDevice && confirmedDevice?.img) ||
    (confirmedDevice &&
      getDeviceImageByModel(
        globalState?.state?.deviceImages,
        confirmedDevice[0].HorizonItemInformation.AssetFamily,
        '',
        100
      )) ||
    getDeviceImageByModel(
      globalState?.state?.deviceImages,
      AssetFamily?.split(' ')?.slice(0, -2)?.join(' '),
      '',
      100
    );

  const enableDateTimeSlotButton =
    (updatedDeliveryDate && updatedDeliveryTime) ||
    (updatedPickupDate && updatedPickupTime) ||
    (updatedReturnDate && updatedReturnTime);
  const enableWalkingNextButton =
    preferredWalkingStoreLocation && preferredWalkingDate && preferredWalkingTimeSlot;

  const currencyCode = globalState?.state?.sessionResponse?.InitializeResponse?.CurrencyCode;
  const carrierNumber = globalState?.state?.sessionResponse?.InitializeResponse?.DeliveryCarrier;

  const postalCodeLength = getPostalCodeLength(agreementData1?.ClientOffer?.ClientOfferName);

  // useEffect(() => {
  //   if((isTOM || globalState?.state?.isMaxis) && formFields.PostalCode && !invalid.PostalCode){
  //     setIsPostalCodeChanged(true)
  //   }
  // }, [formFields.PostalCode])

  const applyShippingMethodFilters = (response: any) => {
    if ((isTOM) && response.length) {
      const claimWithinCutoff = isClaimWithinCutoff(InitializeResponse?.TimeZone);
      return response
        .filter((d: any) => !d.ShippingMethodType?.includes('SUR'))
        .map((r: any) => {
          r.DeliverySlots = [r.ShippingMethodType !== 'Same Day' ? '19' : claimWithinCutoff ? '20' : '17'];
          return r;
        });
    }else if(globalState?.state?.isMaxis &&  response.length){
      return response
      .filter((d: any) => !d.ShippingMethodType?.includes('SUR'))
      .map((r: any) => {
        r.DeliverySlots = [r.ShippingMethodType !== 'Same Day' ? '18'  : '6'];
        return r;
      });
    }
    return response?.filter((d: any) => d?.DeliverySlots?.length > 0);
  };

  useEffect(() => {
    let shippingMethodResponse = getShippingMethodResponse;
    if (shippingMethodResponse) {
      shippingMethodResponse = applyShippingMethodFilters(shippingMethodResponse);
      onTimeSlotSelect(shippingMethodResponse?.[0]?.DeliverySlots?.[0]);
      setShippingMethodResponse(shippingMethodResponse);
    }
  }, [JSON.stringify(getShippingMethodResponse), updatedDeliveryDate]);


  // End of API //
  const callshippingmethodapi = () => {    
    if((isTOM || globalState?.state?.isMaxis)){
      // if(!globalState?.state?.shippingMethodResponse){
      setLoading(true);
      shippingmethodsv1Api({
        LookupShippingMethodsParameters: {
          ServiceRequestId: processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId,
          ReceiverAddress: {
            Address1: deliveryAddress?.Address1 || find_agreement?.Address?.Address1,
            Address2: deliveryAddress?.Address2 || find_agreement?.Address?.Address2,
            Address3: deliveryAddress?.Address3 || find_agreement?.Address?.Address3,
            City: deliveryAddress?.City || find_agreement?.Address?.City,
            StateProvinceCode: deliveryAddress?.StateProvinceCode || find_agreement?.Address?.StateProvinceCode,
            CountryCode: deliveryAddress?.CountryCode || find_agreement?.Address?.CountryCode,
            PostalCode: deliveryAddress?.PostalCode || find_agreement?.Address?.PostalCode,
            LocationType: "Residential"
          },
          CustomerType: "RETAIL",
          Warehouse: "BKK",
          Simulation: true,
          getDeliverySlots: false,
          AlternateDays: 7
        },
        ServiceOrderId: _ServiceOrderId,
        InteractionLineId: interactionData?.InteractionLine?.InteractionLineId
      }, _ServiceOrderId, interactionData?.InteractionLine?.InteractionLineId, globalState?.state?.findAgreementSessionData)
        .then((data) => {
          setLoading(false);
          let ShippingMethodResults = data?.ShippingMethod;
          if (ShippingMethodResults) {
            ShippingMethodResults = applyShippingMethodFilters(ShippingMethodResults);
            onTimeSlotSelect(ShippingMethodResults?.[0]?.DeliverySlots?.[0]);
            setShippingMethodResponse(ShippingMethodResults);
          }
          setShippingMethod(ShippingMethodResults);
          setShippingMethodResponse(ShippingMethodResults);
          onDateSelect(ShippingMethodResults?.[0]?.EstimatedDeliveryDate)
          globalState?.dispatch({ type: ActionTypes.SET_SHIPPING_METHOD_RESPONSE, payload: ShippingMethodResults });
        })
        .catch((error) => {
          setLoading(false);
          console.error('error', error);
          saveError(error, globalState);
          showErrorPage(navigate);
        });
      // }
    }
  };

  function checkIfEmpty(strEntry: any) {
    const dontrun = false;
    if (dontrun) {
      setUpdatedPickupAddress('');
      setUpdatedPickupDate('');
      setUpdatedPickupTime('');
      setUpdatedReturnAddress('');
      setUpdatedReturnDate('');
      setUpdatedReturnTime('');
    }

    if (strEntry) {
      return false;
    } else {
      return true;
    }
  }

  const onDateSelect = (date: string) => {
    setUpdatedDeliveryDate(date);
    setSessionUpdatedDeliveryDate(date);
  };

  const onTimeSlotSelect = (timeSlot: string) => {
    setUpdatedDeliveryTime(timeSlot);
    setSessionUpdatedDeliveryTime((isTOM || globalState?.state?.isMaxis) ? timeSlot : timeSlot?.split(':')?.[0]);
    setSessionUpdatedDeliveryTimeCode((isTOM || globalState?.state?.isMaxis) ? timeSlot : timeSlot?.split(':')?.[1]);
    // checkIfNightFeeApplicable();
  };

  const clearValidations = () => {
    
    setFields(getAddressFields(agreementData1?.ClientOffer?.ClientOfferName));
    setDistrict([]);
    setProvience([]);
    
  };

  // const getDeliveryDay = (date: any) => {
  //   const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //   const d = new Date(date);
  //   const dateselected = d.getDate();
  //   const month = [
  //     'January',
  //     'February',
  //     'March',
  //     'April',
  //     'May',
  //     'June',
  //     'July',
  //     'August',
  //     'September',
  //     'October',
  //     'November',
  //     'December',
  //   ];
  //   let monthEng = month[d.getMonth()];
  //   const year = d.getFullYear();
  //   const day = dateselected + ' ' + monthEng + ' ' + year + ', ' + weekday[d.getDay()];
  //   return day;
  // };

  const R_ServiceRequests = ResumeFlowData?.ServiceRequests[0];
  const R_ServiceRequestId = R_ServiceRequests?.ServiceRequestId;
  const R_ServiceOrderId = R_ServiceRequests?.ServiceOrder?.ServiceOrderId;
  const R_IncidentType = R_ServiceRequests?.IncidentType;
  const [serviceRequestData] = useSessionStorage<any>(processIncidentKey, null);
  const processIncData = globalState?.state?.processIncData || serviceRequestData;
  const incType = camelize(processIncData?.ServiceRequest?.IncidentType || R_IncidentType);
  const isWalking = isWalkIn(srDeliveryType);
  const [selectedStoreDetails] = useSessionStorage<any>(storeAddressKey, null);
  const walkingAddress = selectedStoreDetails && selectedStoreDetails;
  const [deliveryAddress] = useSessionStorage<any>(deliveryAddressKey, null);
  const hasDTAddOnAcquired =
    globalState?.state?.doorStepAddOnData?.hasAddOnAcquired || ResumeFlowData?.IsDataTransfer;
  
  const ServiceRequestId = processIncData?.ServiceRequest?.ServiceRequestId || R_ServiceRequestId;
  const [deliveryDate] = useSessionStorage<any>('deliveryDate', null);
  const [walkingDate] = useSessionStorage<any>('storeDate', null);
  const [DeliveryTimeSlotCode] = useSessionStorage<any>('deliveryTimeCode', null);
  const [deliveryTime] = useSessionStorage<any>('deliveryTime', null);
  const [walkingTime] = useSessionStorage<any>('storeTime', null);
  
  const _ServiceOrderId =
    globalState?.state?.serviceOrderData?.CreateServiceOrderResults?.ServiceOrderId ||
    R_ServiceOrderId;
  const [, setShippingMethod] = useSessionStorage<any>('shippingMethodResponse', null);
  const [, setUpdateShippingOrderResponse] = useSessionStorage<any>(
    'updateShippingOrderResponse',
    null
  );

  const getSelectedShippingMethod = () => {
    const selectedShippingMethod = shippingMethodResponse?.filter((val: any) => {
      return val?.EstimatedDeliveryDate === deliveryDate;
    });

    return selectedShippingMethod?.[0];
  }

  const callUpdateShipingOrderAPI = () => {
    const userAddress = find_agreement?.Address;

    const getAddress = () => {
      if (isDelivery) {
        return deliveryAddress;
      } else if (isWalking && (isSwap(incType) || isReplacement(incType)) && !globalState?.state?.isMaxis) {
        return userAddress;
      } else if (walkingAddress || (isWalking && (isSwap(incType) || isReplacement(incType)) && globalState?.state?.isMaxis)) {
        return walkingAddress;
      } else {
        userAddress;
      }
    };

    const tomDeliveryWindow = DeliveryTimeSlotCode == '19' ? '17H' : '6H';

    const getDPDeliveryWindowSS3 = () => {
      if(deliveryDate === getDateAndDay(new Date(), 'YYYY-MM-DD'))
        return '4H'
      else
        return deliveryTime === '8am - 12pm' ? '12H' : '18H'
    }

    const getDPDeliveryWindow = () => {
      if (isTOM && !LFLStock) {
        return tomDeliveryWindow
      } else if(callShippingMethodv1(incType, find_agreement?.ClientOffer?.ClientOfferName)  && !getDeliverySlot(find_agreement?.ClientOffer?.ClientOfferName)) {
        return getDPDeliveryWindowSS3() // SS3.0
      } else {
        return DeliveryTimeSlotCode;
      }
    }

    const UpdateShippingOrderParameters = {
      UpdateShippingOrderParameters: {
        EntityUpdateOptions: {
          ReturnEntity: true,
        },
        IsDataTransfer: hasDTAddOnAcquired,
        InteractionLineId: interactionData?.InteractionLine?.InteractionLineId,
        ServiceRequestId: ServiceRequestId,
        FulfilmentMethod:
          (isSwap(incType) || isReplacement(incType)) &&
            isWalking
            ? 'INTLC'
            : undefined,
        ShippingOrder: {
          ShippingMethodType: getSelectedShippingMethod()?.ShippingMethodType || 'Same Day',
          CarrierNumber: carrierNumber,
          SignatureRequired: true,
          RequestedShipmentDate: isDelivery ? deliveryDate : walkingDate, // for repair we are passing exiting data from get claim api
          ExpectedDeliveryDate: isDelivery ? deliveryDate : walkingDate, // for repair we are passing exiting data from get claim api
          // DeliveryWindow: isDelivery ? DeliveryTimeSlotCode : walkingTime, // for repair we are passing exiting data from get claim api
          DeliveryWindow: isDelivery ? getDPDeliveryWindow() : walkingTime,
          NationalId: find_agreement?.IdentificationNumber?.substr(
            find_agreement?.IdentificationNumber.length - 4
          ),
          // Remark: clickYes
          //   ? `Repesentative Name:${form.name}; NRIC: ${form.FIN}; number: ${form.mobile}; fmip:`
          //   : '',
          Remark: '',
          AlternateContactNumber: alternateContactNumber,
          ReceiverName: find_agreement?.Customers?.Customer?.[0]?.FullName,
          ReceiverAddress: {
            LocationType: 'Residential',
            BusinessName: find_agreement?.Customers?.Customer?.[0]?.FullName,
            Standardized: true,
            IsOverridden: false,
            StandardizedSourceName: 'QAS',
            BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
            IsScrubbed: true,
            Address1: isWalking && getAddress()?.AddressLine1,
            ...getAddress(),
          },
        },
      },
    };

    // setLoading(true);
    getUpdateShippingDetails(
      UpdateShippingOrderParameters,
      _ServiceOrderId,
      interactionData?.InteractionLine?.InteractionLineId,
      globalState?.state?.findAgreementSessionData
    )
      .then((data: any) => {
        setLoading(false);
        // const ShippingOrderUpdateResults = data?.ShippingOrderUpdateResults;
        setUpdateShippingOrderResponse(data);
        // setUpdateShippingOrderRes(data);
        globalState?.dispatch({
          type: ActionTypes.SET_SHIPPING_ORDER_UPDATES_RESULT,
          payload: data?.data?.ShippingOrderUpdateResults,
        });
        globalState?.dispatch({ type: ActionTypes.ENABLE_REVIEW_BTN, payload: true });
      })
      .catch((error: any) => {
        setLoading(false);
        saveError(error, globalState);
        showErrorPage(navigate);
      });
  };

  const onConfirmOrNextClick = () => {
    if (isReplacement(incType)) {
      navigate('/reviewsubmission', { replace: true });
      callUpdateShipingOrderAPI();
    } else {
      navigate('/representativeform', { replace: true });
    }
  };

  const confirmAddressQuestion = Scripts?.LogisticsScripts?.confirmAddressQuestionLBL;
  const selectDeliveryQuestion = Scripts?.LogisticsScripts?.selectDeliveryQuestionLBL;
  const confirmDeliveryScheduleQuestion =
    Scripts?.LogisticsScripts?.confirmDeliveryScheduleQuestionLBL;
  const walkingQuestion = Scripts?.LogisticsScripts?.walkingQuestionLBLSwap;

  useEffect(() => {
    checkIfNightFeeApplicable();
  }, [updatedDeliveryTime]);

  const checkIfNightFeeApplicable = () => {
    if (['7pm - 10pm:19-22'].includes(updatedDeliveryTime)) {
      setNighDeliverytFees(nightDeliveryFee);
      globalState?.dispatch({
        type: ActionTypes.SET_DELIVERY_NIGHT_FEE,
        payload: nightDeliveryFee,
      });
      setIsNightFeesApplicable(true);
      // return true;
    } else {
      setIsNightFeesApplicable(false);
      setNighDeliverytFees(null);
      globalState?.dispatch({
        type: ActionTypes.SET_DELIVERY_NIGHT_FEE,
        payload: null,
      });
      // return false;
    }
  };

  const callAddressStandardizeApi = (payload: any) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      addressStandardize(payload, interactionData?.InteractionLine?.InteractionLineId, globalState?.state?.findAgreementSessionData)
        .then((data) => {
          setLoading(false);
          if (data) {
            resolve(data);
          } else {
            reject('No data received from API');
          }
        })
        .catch((error) => {
          setShowAddUpdateError(true);
          setLoading(false);
          reject(error);
        });
    });
  };

  const getAddress1 = () => {
    const addressLine1 = fields.filter((item: any) => {
      return item.mapTo === 'Address1';
    });

    return addressLine1.map((address: { value: any; }) => address.value).join(' ')
  }

  const getAddress2 = () => {
    const addressLine2 = fields.filter((item: any) => {
      return item.mapTo === 'Address2';
    });

    return addressLine2.map((address: { value: any; }) => address.value).join(' ')
  }

  const getAddress3 = () => {
    const addressLine3 = fields.filter((item: any) => {
      return item.mapTo === 'Address3';
    });

    return addressLine3.map((address: { value: any; }) => address.value).join(' ')
  }

  const getCity = () => {
    return getFieldByFieldName('District')?.value
  }

  const getStateProvinceCode = () => {
    return getFieldByFieldName('Province')?.value
  }

  const getPostalCode = () => {
    return getFieldByFieldName('PostalCode')?.value
  }

  const addNewAddress = () => {
    if(!hasQAS(agreementData1?.ClientOffer?.ClientOfferName)){
      
      let obj = {
        Address1: getAddress1() ?? '',
        Address2: getAddress2() ?? '',
        Address3: getAddress3() ?? '',
        City: getCity(),
        StateProvinceCode: getStateProvinceCode(),
        CountryCode: getCountryCode(currencyCode),
        PostalCode: getPostalCode()
      }
     
      let newAddress = `${obj?.Address1} ${obj?.Address2} ${obj?.Address3} ${obj?.City} ${obj?.StateProvinceCode} ${obj?.PostalCode}`;

      setIsAddressConfirm(false);
      setChangeDateTimeFlag(false);
      setChangesAddressFlag(false);
      setUpdatedDeliveryAddress(newAddress);
      setIsNewAddress(true);
      setSessionUpdatedDeliveryAddress(obj);
    } else {
      const payloadParams = {
        QASParams: {
          ClientId: InitializeResponse?.ClientId,
          AgreementId: agreementData1?.AgreementId,
          Address: {
            Address1: getAddress1() ?? '',
            Address2: getAddress2() ?? '',
            Address3: getAddress3() ?? '',
            City: 'Singapore',
            StateProvinceCode: 'Singapore',
            CountryCode: getCountryCode(currencyCode),
            PostalCode: getPostalCode(),
          },
        },
      };
      callAddressStandardizeApi(payloadParams)
        .then((data: any) => {
          if (data?.StandardizeAddressResponse) {
            setShowAddUpdateError(false);
            const correctedAdd = data?.StandardizeAddressResponse?.AddressResult?.CorrectedAddress;
            handleCorrectedAddress(correctedAdd, 'SGP', 'en', 'ROOFTOP');
          } else {
            setShowAddUpdateError(true);
          }
        })
        .catch(() => {
          setShowAddUpdateError(true);
        });
    }
  };

  const onCancelClick = () => {
    setOpenCancelPopup(!openCancelPopup);
  };

  const showBackButton = () => {
    const deliveryOptions =  getDeliveryOptions(incType, find_agreement?.ClientOffer?.ClientOfferName, features) || {};
    let optionsCount : any =  Object.values(deliveryOptions).reduce((a:any, item:any) => a + item, 0);
    return optionsCount > 1;
  }

  const onBackClick = () => {
    navigate('/deliveryoptions', { replace: true });
  }
  
  useEffect(() => {
    getDateFormat();
    getCurrency();
    getwalkingQuestionText();
    getconfirmDeliveryScheduleQuestion()
    getselectDeliveryQuestion();
    getconfirmAddressQuestion();
    getEnterNewDeliveryAddress();
    getEnterNewPickupAddress();
    loadPlaceholderScripts();
    getAlternateContactNumberPlaceHolder();
    
    if(globalState?.state?.isMaxis){
      // show change address page default for maxis
      handleChageDeliveryAddressClick();
    }
    
    let GTMData = getGTMData(GTM_EVENT.LOGISTIC_SCREEN_EVENT, globalState?.state?.currentAgreementData,
      getCarrierByDomain(), getTypeByDomain(), globalState?.state?.currentAgreementData?.CustomerCaseNumber)
      logGTMEvent(GTMData);
  }, []);

  const getDateFormat = async () => {
    const format = await getGlobalDateFormat();
    setDateFormat(format);
  }

  const getCurrency = async () => {
    const currency = await getCurrencySymbol();
    setCurrencySymbol(currency);
  };

  const getwalkingQuestionText = async () => {
    const walkinText = await getTextScript(
      `${LogisticFormtranslationKey.PageName}/${LogisticFormtranslationKey.walkingQuestion}`,
      `${walkingQuestion}`
    );
    setwalkingQuestionText(walkinText);
  };
  const getconfirmDeliveryScheduleQuestion = async () => {
    const confirmDeliveryScheduleQuestionText = await getTextScript(
      `${LogisticFormtranslationKey.PageName}/${LogisticFormtranslationKey.confirmDeliveryScheduleQuestion}`,
      `${confirmDeliveryScheduleQuestion}`
    );
    setconfirmDeliveryScheduleText(confirmDeliveryScheduleQuestionText);
  };

  const getselectDeliveryQuestion = async () => {
    const selectDeliveryQuestionText = await getTextScript(
      `${LogisticFormtranslationKey.PageName}/${LogisticFormtranslationKey.selectDeliveryQuestionText}`,
      `${selectDeliveryQuestion}`
    );
    setselectDeliveryQuestionText(selectDeliveryQuestionText);
  };

  const getconfirmAddressQuestion = async () => {
    const confirmAddressQuestionText = await getTextScript(
      `${LogisticFormtranslationKey.PageName}/${LogisticFormtranslationKey.confirmAddressQuestionText}`,
      confirmAddressQuestion
    );
    setconfirmAddressQuestionText(confirmAddressQuestionText);
  };

  const getEnterNewDeliveryAddress = async () => {
    const EnterNewDeliveryAddressText = await getTextScript(
      `${LogisticFormtranslationKey.PageName}/${LogisticFormtranslationKey.EnterNewDeliveryAddressText}`,
      Scripts?.LogisticsScripts?.EnterNewDeliveryAddressLBL
    );
    setEnterNewDeliveryAddressText(EnterNewDeliveryAddressText);
  };

  const getEnterNewPickupAddress = async () => {
    const EnterNewPickupAddressText = await getTextScript(
      `${LogisticFormtranslationKey.PageName}/${LogisticFormtranslationKey.EnterNewPickupAddressText}`,
      `Enter New Pick-Up Address`
    );
    setEnterNewPickupAddressText(EnterNewPickupAddressText);
  };

  const loadPlaceholderScripts = async () => {
    let updatedFields = [];

    for (const field of fields) {

      const defaultPlaceholder = field?.placeholder;
      const defaultError = field?.errorMessage;
     
      const returnedText = await getTextScript(
        `${translationsKeys.Global.PageName}/${field?.name}`,
        defaultPlaceholder
      );

      const returnedTextError = await getTextScript(
        `${translationsKeys.Global.PageName}/${field?.name}Error`,
        defaultError
      );

      field.placeholder = returnedText;
      field.errorMessage = returnedTextError;

      updatedFields.push(field)
    }

    setFields(updatedFields);

  };
 
  const handleInputChange = (field: ChangeAddressFieldConfig, value: string) => {
    let isInvalid = false;
    const hasQASFeature = hasQAS(agreementData1?.ClientOffer?.ClientOfferName);
  
    if (field?.name === 'PostalCode') {
      if (!checkIfEmpty(value) && value?.length !== postalCodeLength) {
        isInvalid = true;
      } else if (checkIfEmpty(value)) {
        isInvalid = true;

        // clear city and state drop down if pincode empty
        if(!hasQASFeature){
          setDistrict([]);
          setProvience([]);
        }
      }

      if (!hasQASFeature && value?.length === postalCodeLength) {
        setIsPostalCodeChanged(true)
        const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
        getCities(value, language);
      }

    } else if (!field?.isRequired) {
      if (!Regex.test(value)) {
        isInvalid = true;
      }
    } else {
      isInvalid = checkIfEmpty(value) || !Regex.test(value);
    }

    updateFields(field, value, isInvalid);
  };

  const getFieldByFieldName = (fieldName: string) => {
   
    return fields.find((item:any) => {
      return item.name === fieldName;
    });
    
  }

  const updateFields = (field: any, value: string, isInvalid: boolean) => {
    setFields((prevFields: any) =>
      prevFields.map((item: any) =>
        item.name === field.name
          ? {
              ...item,
              value: value,
              isInvalid: isInvalid,
            }
          : item
      )
    );
  }

  const isAnyRequiredFieldInvalid = (fields: any) => {
    for (const field of fields) {
      if (field.isRequired && field.isInvalid) {
        return true;
      }
      if (field.isRequired && (field.value === null || field.value === '')) {
        return true;
      }
      // if (formFields.District === '' || formFields.Province === '') {
      //   return true;
      // }
    }
    return false;
  };

  const handleSelectChange = (event:any, fieldName:any) => {
    const selectedValue = event.target.value;

    let field = getFieldByFieldName(fieldName);
    updateFields(field, selectedValue, field?.isInvalid);
  };

  // useEffect(() => {
  //   if (changeAddressFlag && (isTOM || globalState?.state?.isMaxis) && !citiesData) {
  //     // setLoading(true)
  //     getCities(interactionData?.InteractionLine?.InteractionLineId)
  //       .then((data) => {
  //         setCitiesData(data?.GetCitiesResponse);
  //         // setLoading(false);
  //       })
  //       .catch((error) => {
  //         // setLoading(false);
  //         saveError(error, globalState);
  //         showErrorPage(navigate);
  //       });
  //   }
  // }, [changeAddressFlag]);

  useEffect(() => {
    const language = (sessionStorage.getItem('al') || 'en-US')?.replace(/["']/g, '');
    const postalCodeField = getFieldByFieldName("PostalCode");
    const districtField = getFieldByFieldName("District");
    
    if (postalCodeField?.value?.length === postalCodeLength && districtField?.value) {

      const languageSuffix = language === 'en-US' ? '' : 'TL';
      const filterCondition = (val: any) => val.PostalCode === postalCodeField?.value;

      const filteredStates = cities
        ?.filter(filterCondition)
        .filter((val: any) => val[`City${languageSuffix}`] === districtField?.value)
        .map((val: any) => val[`State${languageSuffix}`]);

      if(!filteredStates?.length){
        const field = getFieldByFieldName('PostalCode')
        updateFields(field, "", true)
      } else {
        const field = getFieldByFieldName('Province')
        updateFields(field, filteredStates[0], field?.isInvalid)
      }
      setProvience(filteredStates);
    }
  }, [district]);

  const getCities = (postalCode: string, language: string) => {

    setLoading(true);

    const interactionLineId = interactionData?.InteractionLine?.InteractionLineId;
    const clientID = globalState?.state?.sessionResponse?.InitializeResponse?.ClientId;

    getCitiesByPostalCode(interactionLineId, postalCode, language, clientID, globalState?.state?.findAgreementSessionData).then((data)=> {
      setLoading(false);
      const citiesData = data?.GetCityStatebyZipCodeResponse;

      setCities(citiesData);

      const languageSuffix = language === 'en-US' ? '' : 'TL';
      const filterCondition = (val: any) => val.PostalCode === postalCode;

      const filteredCities = citiesData?.filter(filterCondition).map((v: any) => v[`City${languageSuffix}`]);
      // const filteredStates = citiesData
      //   ?.filter(filterCondition)
      //   .filter((val: any) => val[`City${languageSuffix}`] === filteredCities[0])
      //   .map((val: any) => val[`State${languageSuffix}`]);

      if(!filteredCities?.length){
        const field = getFieldByFieldName('PostalCode')
        updateFields(field, "", true)
      } else {
        const postalCodeField = getFieldByFieldName("District");
        updateFields(postalCodeField, filteredCities[0], postalCodeField?.isInvalid);
      }

      setDistrict(filteredCities);
      setProvience([]);
      
    }).catch((error) => {
      setLoading(false);
      saveError(error, globalState);
      showErrorPage(navigate)
    })
  }

  const getDeliverySlots = () => {
    if (isTOM) {
      return timeConvert24to12Hr(updatedDeliveryTime);
    } else {
      return updatedDeliveryTime?.split(':')?.[0]
    }
  };

  const setPredictiveAddressFlow = (
    isPredictiveAddress: boolean = false,
    addressValue: string = '',
    addressOption: AutoCompleteOption = EMPTY_ADDRESS_OPTION
  ) => {
    setPredictiveAddressFlag(isPredictiveAddress);
    setPredictiveAddressValue(addressValue);
    setPredictiveAddressOption(addressOption);
  };

  const handleChageDeliveryAddressClick = () => {
    if((isUpgrade(incType) && isUpgradeFlow)&& isDelivery){
      // Note: Enable JB Predictive Address Flow
      setPredictiveAddressFlow(true);
    } else {
      // Note: Enable Default Change Address Flow
      setChangesAddressFlag(true);
    }
    clearValidations();
  }

  const confirmAlternateContactNumber = () => {
    if (alternateContactNo && !invalidMobile && globalState) {
      globalState?.dispatch({ type: ActionTypes.SET_ALTERNATE_CONTACT_NUMBER, payload: alternateContactNo });
      setShowAlternateContactField(false);
    }
  }

  let validLength = (isTOM || globalState?.state?.isMaxis) ? 10 : 8;
  
  const handleAlternateContactChange = (e: any) => {
    const { value } = e.target;
    setAlternateContactNo(value);
    let reg = (isTOM || globalState?.state?.isMaxis) ? /^\d{10}$/ : /^\d{8}$/
    if (reg.test(value)) {
      setInvalidMobile(false);
    } else if (value.trim() === '') {
      setInvalidMobile(false);
    } else {
      setInvalidMobile(true);
    }
  };

  const handleCancel = () => {
    setShowAlternateContactField(false);
    setInvalidMobile(false);
  }

  const getAlternateContactNumberPlaceHolder = async() => {
    const returnedTextError = await getTextScript(
      `${translationsKeys.Global.PageName}/alternateContactPlaceholder`,
      'Alternate Contact Number'
    );
    setAlternateContactNoPlaceholder(returnedTextError)
  }

  const { mutateAsync: mutationDoGetAddressQAS } = useMutation(
    (inputObject: object) => doGetAddressQAS(inputObject),
    {
      onError: (error) => {
        console.error('Error occurred while getting addresses:', error);
        setShowAddUpdateError(true);
      },
    }
  );

  const addNewAddressBySearch = async () => {
    const result = await mutationDoGetAddressQAS(predictiveAddressOption);
    const correctedAdd = result?.AddressResult?.CorrectedAddress;
    handleCorrectedAddress(correctedAdd, 'AUS', 'en', 'ROOFTOP');
  };

  const handleCorrectedAddress = (
    correctedAdd: any,
    region: string = 'SGP',
    lang: string = 'en',
    locationType: string = 'ROOFTOP'
  ) => {
    if (correctedAdd) {
      const obj = {
        Address1: correctedAdd?.AddressLine1 ?? '',
        Address2: correctedAdd?.AddressLine2 ?? '',
        Address3: correctedAdd?.AddressLine3 ?? '',
        City: correctedAdd?.CityName || getCountryName(currencyCode),
        StateProvinceCode: correctedAdd?.StateProvinceCode ?? '',
        CountryCode: correctedAdd?.CountryCode ?? '',
        PostalCode: correctedAdd?.PostalCode ?? '',
      };
      let newAddress = `${obj?.Address1} ${obj?.Address3} ${obj?.Address2} ${obj?.PostalCode}`;
      setIsAddressConfirm(false);
      setChangeDateTimeFlag(false);
      setChangesAddressFlag(false);
      setUpdatedDeliveryAddress(newAddress);
      setIsNewAddress(true);
      setSessionUpdatedDeliveryAddress(obj);
      setPredictiveAddressFlow();

      Geocode.setApiKey(import.meta.env.VITE_GOOGLE_API_KEY); // import.meta.env.VITE_GOOGLE_API_KEY
      Geocode.setLanguage(lang);
      Geocode.setRegion(region);
      Geocode.setLocationType(locationType);

      Geocode.fromAddress(newAddress)
        .then(
          (response) => {
            const { lat, lng } = response?.results?.[0]?.geometry?.location;
            globalState?.dispatch({
              type: ActionTypes.SET_LOCATION_COORDINATES,
              payload: { lat, lng },
            });
          },
          (error) => {
            console.error(error);
          }
        )
        .catch((error) => {
          saveError(error, globalState);
          // showErrorPage(navigate)
        });
    } else {
      setAddressError(true);
    }
  };


  return (
    <Box>
      <Box id={'myasurion-srswap-logisticandfulfillment'} w="full" m="0 auto">
        <Box mt={16} w={{ base: 'full', lg: '80%' }} mx="auto">
          <SubHeader
            onClick={onBackClick}
            backText={'Back'}
            allowBackButtonClick={false}
            showCancelButton={true}
            showBackButton={!showBackButton() || ((isTOM || globalState?.state?.isMaxis) && !LFLStock && !changeAddressFlag && !predictiveAddressFlag) || ((isTOM || globalState?.state?.isMaxis) && !isAddressConfirm) ? false : true}
            cancelText={Scripts.CancelSR.cancelBtnText}
            onCancelClick={onCancelClick}
          ></SubHeader>
          <Box w={{ base: 'full', lg: '60%' }} mx="auto" pb={{ base: 0, lg: '100px' }}>
            <LogisticsAndFulfilment
              imageEl={image}
              question={
                isWalking
                  ? `${walkingQuestionText}`
                  : isAddressConfirm
                    ? isDateTimeslotConfirm
                      ? `${confirmDeliveryScheduleText}`
                      : `${selectDeliveryQuestionText}`
                    : `${confirmAddressQuestionText}`
              }
              deviceName={AssetFamily}
            >
              {(isDelivery || isPickupAndReturn) && (
                <Box p={4}>
                  {
                    // showLogisticsDetails &&
                    !changeAddressFlag && !predictiveAddressFlag && !changeDateTimeFlag && dateFormat && dateFormat?.length > 0 && (
                      <DeliveryDetails
                        deliveryAddress={isDelivery ? updatedDeliveryAddress : ''}
                        deliveryDate={
                          isDelivery
                            ? updatedDeliveryDate && getDateAndDay(updatedDeliveryDate, dateFormat)
                            : ''
                        }
                        deliveryTime={isDelivery ? (globalState?.state?.isMaxis && (isSwap(incType) || isMalfunction(incType))) ? 'By 8pm' : getTime(getDeliverySlots()) : ''}
                        pickupAddress={isPickupAndReturn ? updatedPickupAddress : ''}
                        pickupDate={isPickupAndReturn ? getDateAndDay(updatedPickupDate, dateFormat) : ''}
                        pickupTime={isPickupAndReturn ? getTime(updatedPickupTime.split(':')?.[0]) : ''}
                        returnAddress={isPickupAndReturn ? updatedReturnAddress : ''}
                        returnDate={isPickupAndReturn ? getDateAndDay(updatedReturnDate, dateFormat) : ''}
                        returnTime={isPickupAndReturn ? getTime(updatedReturnTime.split(':')?.[0]) : ''}
                        nightDeliveryFee={nightDeliveryFee}
                        showDeliveryDetails={true}
                        showPickupDeatils={true}
                        showReturnDetails={true}
                        isNightFeesApplicable={isNightFeesApplicable}
                        MDN={`${assetData?.MobileDeviceNumber || assetData1?.MobileDeviceNumber}`}
                        currencySymbol={currencySymbol}
                        isAddressConfirm={getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName) ? isAddressConfirm : true}
                        changeAddressFlag={getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName) ? changeAddressFlag : true}
                        handleChageDeliveryAddressClick={()=>handleChageDeliveryAddressClick()}
                        InlineFlag={getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName)}
                      />
                    )
                  }
                  {changeAddressFlag && (
                    <>
                      <ChangeAddress
                        newAddressQuestion={
                          isDelivery
                            ? EnterNewDeliveryAddressText
                            : EnterNewPickupAddressText
                        }
                        fields={fields}
                        handleInputChange={handleInputChange}
                        selectedProvince={getFieldByFieldName('Province')?.value}
                        selectedDistrict={getFieldByFieldName('District')?.value}
                        provience={provience}
                        district={district}
                        handleSelectChange={handleSelectChange}
                        programmeName={agreementData1?.ClientOffer?.ClientOfferName}
                      />
                    </>
                  )}
                  {predictiveAddressFlag && (
                    <>
                      <PredictiveAddress
                        newAddressQuestion={
                          isDelivery ? EnterNewDeliveryAddressText : EnterNewPickupAddressText
                        }
                        setAddressErrorState={setAddressError}
                        inputValue={predictiveAddressValue}
                        onInputValueChange={setPredictiveAddressValue}
                        selectedOption={predictiveAddressOption}
                        onOptionSelectionChange={setPredictiveAddressOption}
                      />
                    </>
                  )}
                  {
                    // showChangeDateAndTime &&
                    changeDateTimeFlag && (
                      <PickupSchedule
                        dateLabel={
                          isDelivery ? Scripts?.LogisticsScripts?.DeliveryDateLBL : 'Pick-up Date'
                        }
                        timeSlotLabel={
                          isDelivery ? Scripts?.LogisticsScripts?.DeliveryTimeLBL : 'Pick-up Time'
                        }
                        shipingMethods={shippingMethodResponse}
                        onDateSelect={onDateSelect}
                        onTimeSlotSelect={onTimeSlotSelect}
                        selectedDate={updatedDeliveryDate}
                        selectedTime={updatedDeliveryTime}
                        isWalking={false}
                        nightDeliveryFees={nightDeliveryFee}
                        nightDeliverySlots={['7pm - 10pm']}
                        isTOM={isTOM}
                        incidentType = {incType?.toUpperCase()}
                        isMaxis = {globalState?.state?.isMaxis}
                        applyDateFormat={getDateAndDay}
                        dateFormat={dateFormat || Scripts?.Global?.DateFormat}
                        applyTimeTranslation={getTime}
                      />
                    )
                  }
                </Box>
              )}
              {isWalking && (
                <Box p={4}>
                  <WalkingDetails
                    preferredWalkingStoreLocation={preferredWalkingStoreLocation}
                    preferredWalkingTimeSlot={preferredWalkingTimeSlot}
                    preferredWalkingDate={preferredWalkingDate}
                    setPreferredWalkingStoreLocation={setPreferredWalkingStoreLocation}
                    setPreferredWalkingTimeSlot={setPreferredWalkingTimeSlot}
                    setPreferredWalkingDate={setPreferredWalkingDate}
                  />
                </Box>
              )}
              {!isAddressConfirm && !changeAddressFlag && !predictiveAddressFlag && (isDelivery || isPickupAndReturn) && <Box px={4} pb={2}>
                <Flex justifyContent={'space-between'} pb={2}>
                  <Box fontWeight={'bold'}>
                  <TextScript
                  text={'Alternate Contact Number'}
                  translationKey={`${LogisticFormtranslationKey.PageName}/AlternateContactNumberText`}
                />
                  </Box>
                  {!showAlternateContactField && (
                    <Box onClick={() => setShowAlternateContactField(true)}>
                      <Link textDecorationLine={'underline'}>
                      <TextScript
                          text={Scripts.Global.Edit}
                          translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Edit}`}
                        />
                      </Link>
                    </Box>
                  )}
                  {showAlternateContactField && (
                    <Flex justifyContent={'end'}>
                      <Box
                        onClick={confirmAlternateContactNumber}
                        borderWidth="1px"
                        borderRadius="md"
                        borderColor="#6E767D"
                        px={1}
                        mr={3}
                        cursor={'pointer'}
                      >
                        <CheckIcon boxSize={4} focusable={true} />
                      </Box>
                      <Box
                        onClick={handleCancel}
                        borderWidth="1px"
                        borderRadius="md"
                        borderColor="#6E767D"
                        px={1}
                        cursor={'pointer'}
                      >
                        <SmallCloseIcon boxSize={4} focusable={true} />
                      </Box>
                    </Flex>
                  )}
                </Flex>
                {showAlternateContactField && (
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    borderColor="#6E767D"
                    maxW={{ base: '80%', lg: '50%' }}
                  >
                    <Input
                      border={'none'}
                      maxLength={validLength}
                      readOnly={false}
                      disabled={false}
                      placeholder={alternateContactNoPlaceholder}
                      _focus={{ border: 'none', boxShadow: 'none' }}
                      onChange={(e) => handleAlternateContactChange(e)}
                    />
                  </Box>
                )}
                {showAlternateContactField && invalidMobile && <Box color={'red'} fontSize={'14px'}>
                  <TextScript
                    text={'* Invalid mobile number'}
                    translationKey={`${LogisticFormtranslationKey.PageName}/AlternateContactNumberErrorMsg`} />
                </Box>}
                {!showAlternateContactField && <Box>{globalState?.state?.alternateContactNumber || '-'}</Box>}
              </Box>}
            </LogisticsAndFulfilment>
          </Box>
        </Box>
        <CancelSrPopup closeCancelPopup={setOpenCancelPopup} openCancelPopup={openCancelPopup} />
      </Box>
      {(isDelivery || isPickupAndReturn) ? (
        <Flex
          direction={{ base: 'column', lg: 'row-reverse' }}
          justifyContent={{ base: 'start', lg: 'space-evenly' }}
          mt={6}
          p={4}
          w="full"
          marginX={'auto'}
          position={{ base: 'relative', lg: 'fixed' }}
          bottom="0"
          bgColor={{ base: '', lg: '#faf9f5' }}
        >
          <>
            {!isAddressConfirm && !changeAddressFlag && !predictiveAddressFlag && (
              <Button
                py={3}
                px={5}
                id={'addressConfirm'}
                colorScheme="primary"
                isDisabled={showAlternateContactField}
                color="white"
                w={{ base: 'full', lg: '8%' }}
                onClick={() => {
                  if ((isTOM || globalState?.state?.isMaxis) && !LFLStock) {
                    setIsAddressConfirm(false);
                    setSessionUpdatedDeliveryDate(shippingMethodResponse?.[0]?.EstimatedDeliveryDate)
                    setChangeDateTimeFlag(false);
                    onConfirmOrNextClick()
                  } else {
                    setIsAddressConfirm(true);
                    setChangeDateTimeFlag(true);
                    if((isUpgrade(incType)) && isUpgradeFlow) {
                      navigate('/fmip', { replace: true });
                    }
                  }
                  if((isTOM || globalState?.state?.isMaxis) && isPostalCodeChanged) callshippingmethodapi() 
                  if (!isNewAddress) setSessionUpdatedDeliveryAddress(find_agreement?.Address);
                }}
              >
                <TextScript
                  text={Scripts.Global.Confirm}
                  translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Confirm}`}
                />
              </Button>
            )}
            {!getInlineChangeAddressFlag(find_agreement?.ClientOffer?.ClientOfferName) && !isAddressConfirm && !changeAddressFlag && !predictiveAddressFlag && (
              <Flex justify={{ base: 'center', lg: 'start' }}>
                <Button
                  colorScheme={'black'}
                  color={accent} //Link Color
                  variant="link"
                  height="48px"
                  as={'u'}
                  fontWeight={700}
                  w={{ base: 'full', lg: 'auto' }}
                  cursor={'pointer'}
                  onClick={() => handleChageDeliveryAddressClick()}
                >
                  <TextScript
                    text={Scripts?.LogisticsScripts?.ChangeAddress}
                    translationKey={`${LogisticsKeys.LogisticsScripts.PageName}/${LogisticsKeys.LogisticsScripts.btnChangeAddress}`}
                  />
                </Button>
              </Flex>
            )}
          </>
          <>
            {!isAddressConfirm && (changeAddressFlag  || predictiveAddressFlag) && (
              <Flex
                w="full"
                direction={{ base: 'column', lg: 'row-reverse' }}
                justifyContent={{ base: 'start', lg: 'space-evenly' }}
              >
                {(showAddUpdateError || addressError) && (
                  <Box fontSize={'sm'} px={4} pb={1} textColor={'red'}>
                    {showAddUpdateError && (
                      <TextScript
                        text={'*Error in updating the address'}
                        translationKey={`${LogisticFormtranslationKey.PageName}/updateAddressErrorMsg`}
                      />
                    )}

                    {addressError && (
                      <TextScript
                        text={'*Please enter valid address'}
                        translationKey={`${LogisticFormtranslationKey.PageName}/invalidAddressErrorMsg`}
                      />
                    )}
                  </Box>
                )}
                <Button
                  py={3}
                  px={5}
                  id={'addressConfirm'}
                  colorScheme="primary"
                  color="white"
                  isDisabled={
                    predictiveAddressFlag
                      ? _.isEmpty(predictiveAddressOption):
                      isAnyRequiredFieldInvalid(fields)
                  }
                  w={{ base: 'full', lg: 'auto' }}
                  onClick={() =>
                    predictiveAddressFlag ? addNewAddressBySearch() : addNewAddress()
                  }
                >
                  <TextScript
                    text={Scripts?.LogisticsScripts?.AddNewAddress}
                    translationKey={`${LogisticsKeys.LogisticsScripts.PageName}/${LogisticsKeys.LogisticsScripts.btnAddNewAddress}`}
                  />
                </Button>
                <Button
                  colorScheme={'black'}
                  color={accent}
                  display={globalState?.state?.isMaxis ? 'none' : 'block'}
                  variant="link"
                  as={'u'}
                  fontWeight={700}
                  height="48px"
                  w={{ base: 'full', lg: '10%' }}
                  m={'10px 0px'}
                  textAlign={'center'}
                  cursor={'pointer'}
                  onClick={() => {
                    if (predictiveAddressFlag) {
                      setPredictiveAddressFlow();
                      setChangesAddressFlag(true);
                    } else {
                      setChangesAddressFlag(false);
                    }
                    setShowAddUpdateError(false);
                    setAddressError(false);
                    clearValidations();
                  }}
                >
                  { predictiveAddressFlag ? (
                    'Change Address Manually'
                  ): (
                    <TextScript
                      text={Scripts?.Global?.Cancel}
                      translationKey={`${LogisticsKeys.Global.PageName}/${LogisticsKeys.Global.Cancel}`}
                    />
                  )}
                </Button>
              </Flex>
            )}
          </>
          {isAddressConfirm && !isDateTimeslotConfirm && (
            <Button
              py={3}
              px={5}
              id={'timeSlotConfirm'}
              colorScheme="primary"
              color="white"
              w={{ base: 'full', lg: '8%' }}
              m="auto"
              isDisabled={!enableDateTimeSlotButton}
              onClick={() => {
                setIsDateTimeslotConfirm(true);
                if (isAddressConfirm) {
                  setChangeDateTimeFlag(false);
                }
              }}
            >
              <TextScript
                text={Scripts.Global.Confirm}
                translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Confirm}`}
              />
            </Button>
          )}
          <>
            {isAddressConfirm && isDateTimeslotConfirm && (
              <Button
                py={3}
                px={5}
                id={'dateAndTimeSlotConfirm'}
                colorScheme="primary"
                color="white"
                w={{ base: 'full', lg: '10%' }}
                onClick={() => {
                  onConfirmOrNextClick();
                  return;
                }}
              >
                <TextScript
                  text={Scripts?.Global?.Confirm}
                  translationKey={`${LogisticsKeys.Global.PageName}/${LogisticsKeys.Global.Confirm}`}
                />
              </Button>
            )}
            {isAddressConfirm && isDateTimeslotConfirm && !changeDateTimeFlag && (
              <Flex justify={{ base: 'center', lg: 'start' }}>
                <Button
                  colorScheme={'black'}
                  color={accent} //Link Color
                  variant="link"
                  as={'u'}
                  fontWeight={700}
                  height="48px"
                  w={{ base: 'full', lg: 'auto' }}
                  onClick={() => {
                    setIsDateTimeslotConfirm(false);
                    setChangeDateTimeFlag(true);
                  }}
                >
                  <TextScript
                    text={Scripts?.LogisticsScripts?.ChangeDeliveryDateTime}
                    translationKey={`${LogisticsKeys.LogisticsScripts.PageName}/${LogisticsKeys.LogisticsScripts.btnChangeDeliveryDateTime}`}
                  />
                </Button>
              </Flex>
            )}
          </>
        </Flex>
      ) : (
        <Flex
          justify="center"
          mt={6}
          p={4}
          w="full"
          position={{ base: 'relative', lg: 'fixed' }}
          bottom="0"
          bgColor={{ base: '', lg: '#faf9f5' }}
        >
          <Button
            py={3}
            px={5}
            id={'walkingStoreDetails'}
            colorScheme="primary"
            isDisabled={!enableWalkingNextButton}
            color="white"
            w={{ base: 'full', lg: '20%' }}
            onClick={() => {
              onConfirmOrNextClick();
            }}
          >
            <TextScript
              text={Scripts.Global.Next}
              translationKey={`${translationsKeys.Global.PageName}/${translationsKeys.Global.Next}`}
            />
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default LogisticsAndFulfilmentContainer;
