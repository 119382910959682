/* eslint-disable no-console */
import {    
    createInquiryV1    
} from '../../services/api';
import { SubHeader, TextScript, useConfig, useSessionStorage } from '@MGPD/myasurion-shared';
import { Box, Button, Flex, Image, Input, Link, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PlusIcon from './../../ClaimComponents/icons/PlusIcon.svg';
import RemoveIcon from './../../ClaimComponents/icons/Remove.svg';
import takePhoto from './../../ClaimComponents/icons/TakePhoto.svg';
import PDF from './../../ClaimComponents/utils/preview-pdf';
import {
    dataURItoBlob,
    getBase64,
    getBase64ForPDF,
    getSignedRequestForUpload,
    getSignedURLForDownload,
} from './../../ClaimComponents/utils/upload-utils';
import { updatedevicedetails } from '../../services/api';
import { deviceRegDataKey } from '../../modules/config/constants';
import { useQueryClient } from '@tanstack/react-query';
import FhotoToolTip from '../../ClaimComponents/pages/AddDevice/FhotoToolTip';
import { getMappedClientNameVal } from '../../ClaimComponents/utils/helper';
import { useCentralStore } from '../../ClaimComponents/store/appContext';
import { uploadToS3BucketBySignedURL } from '@/ClaimComponents/services/api';
import ActionTypes from '@/ClaimComponents/store/actionTypes';

function UploadPurchaseReceipt() {
    const navigate = useNavigate();
    const globalState = useCentralStore();
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;

    const [selectedFile, setFile] = useState<any>(undefined);
    const [selectedSecondFile, setSecondFile] = useState<any>(undefined);
    const [hideUploadBtn, setHideUploadBtn] = useState<boolean>(false);
    const [SecondImage, setSecondImage] = useState<boolean>(false);
    const [showSecondImageAsFirst, setShowSecondImageAsFirst] = useState<boolean>(false);
    const [moreThan10Mb, setMoreThan10Mb] = useState<boolean>(false);
    const [fileError, setFileError] = useState<boolean>(false);
    const [, setUploadStatus] = useState<string>('NONE');
    const [, setGeneratedFileName] = useState('');
    const [previewFile, setPreviewFile] = useState<any>();
    const [previewSecondFile, setPreviewSecondFile] = useState<any>();
    const [pdf, setPdf] = useState(false);
    const [secondpdf, setSecondPdf] = useState(false);
    const [base64String, setBase64String] = useState('');
    const [base64StringSecond, setBase64StringSecond] = useState('');
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const [formData] = useSessionStorage<any>(deviceRegDataKey, '');
    const [isLoading, setLoading] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const getSessionData: any = queryClient.getQueryData(['sessionData']);
    const UploadPurchaseReceiptScripts = {
        header: 'Provide a product receipt',
        Tooltip: 'See Photo Tips',
        subHeader: 'We accept GIF, jpg, pdf, bmp, or png formats under 10MB in size.',
        photoTip: 'See photo tips',
        imageUploadInfo: 'Make sure your receipt is in focus and you can read it.',
        btnPickImage: 'Take a photo of receipt',
        SecondImageHeader: ' Receipt image saved!',
        SecondImageHeader1: ' Confirm your receipt.',
        SecondSubheader:
            'If your receipt is too large, you can save a second image to capture the rest.',
        FileUploadError:
            'Please check your file size should be under 10MB or file format should be GIF, jpg, pdf, bmp, or png.',
        SecondImageText: 'Save second receipt image (optional)',
    };
    const UploadPurchaseReceiptKeys = {
        PageName: 'UploadPurchaseReceipt',
        header: 'header',
        subHeader: 'subHeader',
        photoTip: 'photoTip',
        imageUploadInfo: 'imageUploadInfo',
        btnPickImage: 'btnPickImage',
        SecondImageHeader: 'SecondImageHeader',
        SecondImageHeader1: 'SecondImageHeader1',
        SecondSubheader: 'SecondSubheader',
        FileUploadError: 'FileUploadError',
        SecondImageText: 'SecondImageText',
        FhotoToolTip: 'FhotoToolTip',
    };

    const pageName = UploadPurchaseReceiptKeys.PageName;
    let documents: any = [];

    const isDisabled =
        (selectedFile || selectedSecondFile) && !fileError && !moreThan10Mb !== undefined
            ? false
            : true;

    const onClickPopup = () => {
        setOpenCancelPopup(!openCancelPopup);
    };

    const onCancelClick = () => {};

    const getFilePDF = async (file: any) => {
        const extension: any = file?.name?.split('.').pop();
        let isPdf = extension?.toUpperCase() === 'PDF' ? true : false;

        if (isPdf) {
            setPdf(isPdf);
            const base: any = await getBase64ForPDF(file);
            setBase64String(base);
        }
        return file;
    };

    const getSecondFilePDF = async (file: any) => {
        const extension: any = file?.name?.split('.').pop();
        let isSecondPdf = extension?.toUpperCase() === 'PDF' ? true : false;

        if (isSecondPdf) {
            setSecondPdf(isSecondPdf);
            const base: any = await getBase64ForPDF(file);
            setBase64StringSecond(base);
        }
        return file;
    };

    const handleFileUpload = async (e: any) => {
        setFileError(false);
        const file = e.target.files[0];
        const extension: any = file?.name?.split('.').pop();

        let regex = /.*\.(jpg|png|jpe?g|pdf|bmp)$/i;
        if (regex.test(file.name)) {
            setFileError(false);
            setMoreThan10Mb(false);
            // actions.setImage(true);
            let fileSizeInKB = file.size / 1024;
            if (fileSizeInKB && fileSizeInKB <= 10000) {
                setMoreThan10Mb(false);
                setFile(file);
                if (extension === 'pdf') {
                    setPreviewFile(getFilePDF(file));
                } else {
                    setPreviewFile(file);
                }
                setHideUploadBtn(true);
                setShowSecondImageAsFirst(false);
            } else {
                setMoreThan10Mb(true);
                setFileError(true);
            }
        } else {
            setFileError(true);
        }
    };

    const handleFileUploadSecond = async (e: any) => {
        setFileError(false);
        const file = e.target.files[0];
        const extension: any = file?.name?.split('.').pop();

        let regex = /.*\.(jpg|png|jpe?g|pdf|bmp)$/i;
        if (regex.test(file.name)) {
            setFileError(false);
            setMoreThan10Mb(false);
            // actions.setImage(true);
            let fileSizeInKB = file.size / 1024;
            if (fileSizeInKB && fileSizeInKB <= 10000) {
                setMoreThan10Mb(false);
                setSecondFile(file);
                if (extension === 'pdf') {
                    setPreviewSecondFile(getSecondFilePDF(file));
                } else {
                    setPreviewSecondFile(file);
                }
                setHideUploadBtn(true);
                setSecondImage(true);
            } else {
                setMoreThan10Mb(true);
                setFileError(true);
            }
        } else {
            setFileError(true);
        }
    };

    const removeFile = () => {
        if (SecondImage) {
            setShowSecondImageAsFirst(true);
            setHideUploadBtn(true);
        } else {
            setShowSecondImageAsFirst(false);
            setHideUploadBtn(false);
        }
        setFile(undefined);
        setPdf(false);
    };

    const removeSecondFile = () => {
        setSecondImage(false);
        setSecondFile(undefined);
        setSecondPdf(false);
        setShowSecondImageAsFirst(false);
        if (selectedFile) {
            setHideUploadBtn(true);
        } else {
            setHideUploadBtn(false);
        }
    };

    const handleSubmit = (selectedFile: any, selectedSecondFile: any) => {
        setLoading(true);
        const deviceDetailsPayload = {
            UpdateDeviceDetailsParameters: {
                Make: formData?.brand,
                Model: formData?.model,
                SerialNumber: formData?.serialNumber,
                WarrantyPeriod: formData?.warrantyPeriod.match(/\d+/g).join([]),
                PurchasePrice: formData?.purchasePrice,
                AgreementId:
                    globalState?.state?.selectedEnrolledDevice?.agreementId ??
                    getSessionData?.FindAgreementsResults?.Agreements?.Agreement[0]?.AgreementId,
            },
        };

        updatedevicedetails(deviceDetailsPayload)
            .then((data) => {
                setLoading(false);
                // check if update device is successful
                if(data?.status === 'Error'){
                    goToDeviceRegister();
                    globalState?.dispatch({
                        type: ActionTypes.SET_ERROR_DATA,
                        payload: data?.message,
                      });
                    return;
                }
                    
                navigate('/confirm-registration', { replace: true });
                setUploadStatus('INPROGRESS');
                if (selectedFile && selectedSecondFile) {
                    UploadPurchaseReceipt(selectedFile);
                    UploadPurchaseReceipt(selectedSecondFile);
                } else {
                    UploadPurchaseReceipt(selectedFile);
                }
            })
            .catch((error: any) => {
                setLoading(false);
                console.log('updatedevicedetails:', error);
            });
    };

    const saveGeneratedFileName = (url: any, file: any) => {
        const fileName: any = url.split('?')?.[0]?.split('/')?.pop();
        const extension: any = file?.name?.split('.').pop();
        setGeneratedFileName(`${fileName}.${extension}`);
    };

    // Inquiry Api
    const callInquiryRegisterDeviceApi = (document: object) => {
        setLoading(true);
        const agreementData: any = getSessionData?.FindAgreementsResults?.Agreements?.Agreement[0];
        const MobileDeviceNumber = agreementData?.ContactPoints?.ContactPoint?.filter((a: any) => {
            return a.PhoneNumber;
        });
        const EmailAddress = agreementData?.ContactPoints?.ContactPoint?.filter(
            (a: any) => a.EmailAddress
        );
        const enrolledAssetData = agreementData?.Assets?.Asset?.filter((a: any) => {
            return (
                a?.AssetInstance?.toUpperCase() === 'ENROLLED' ||
                a?.AssetInstance?.toUpperCase() === 'CUSVERIFY'
            );
        });
        const CreateInquiryParams = {
            CreateInquiryParams: {
                MobileDeviceNumber: MobileDeviceNumber?.[0]?.PhoneNumber || '',
                FirstName: agreementData?.Customers?.Customer[0]?.FirstName || '',
                LastName: agreementData?.Customers?.Customer[0]?.LastName || '',
                ContactNumber: MobileDeviceNumber?.[0]?.PhoneNumber || '',
                ClientName: getSessionData?.InitializeResponse?.ClientName,
                InquiryChannel: 'ONLINE',
                InquiryType: 'Device Registration',
                InquirySubType: 'Device Registration',
                InquiryDescription: `Brand=${formData?.brand}|Model=${formData?.model}|WarrantyPeriod=${formData?.warrantyPeriod}|SerialNumber=${formData?.serialNumber}|PurchaseDate=${formData?.purchaseDate}|PurchasePrice=${formData?.purchasePrice}|ClientOfferName=${agreementData?.ClientOffer?.ClientOfferName}`,
                // InquiryDescription: 'Device Registration Inquiry',
                CallDriverCategory: 'Master Data Management',
                CallDriverSubCategory: 'Master Data Management',
                ClientId: getSessionData?.InitializeResponse?.ClientId, // "39EBF36EBC7A11EC953306C1D4AC7E74",
                Email: EmailAddress[0]?.EmailAddress,
                DepartmentType: 'Supply Chain',
                Priority: 'Low',
                ProgramName: agreementData?.ClientOffer?.ClientOfferName,
                AgreementId: globalState?.state?.selectedEnrolledDevice?.agreementId ?? agreementData?.AgreementId,
                isPremiumPlan: true,
                ClientAccountId: agreementData?.ClientAccount?.ClientAccountId,
                ClientChannelId: agreementData?.ClientAccount?.ClientChannelId,
                CustomerId: agreementData?.Customers?.Customer[0]?.CustomerId,
                AssetId: enrolledAssetData?.[0]?.AssetId,
                Documents: document,
                DeviceDetails: {
                    Category: 'Laptop',
                    AssetMake: formData?.brand,
                    AssetModel: formData?.model,
                    AssetAttributes: `SerialNumber=${formData?.serialNumber}|Category=Laptop|Make=${formData?.make}|PurchaseDate=${formData?.purchaseDate}|PurchasePrice=${formData?.purchasePrice}`,
                    PurchaseDate: formData?.purchaseDate,
                    SerialNumber: formData?.serialNumber,
                },
                UpdateDeviceDetails: {
                    AssetCatalogId: formData?.AssetCatalogId,
                    IMEI: formData?.serialNumber,
                    ClientAssetSkuNumber: formData?.model,
                },
            },
        };
        // let inquiryApiVersion =
        //     CreateInquiryParams?.CreateInquiryParams?.InquiryType?.toUpperCase() ===
        //     'ANCHOR DEVICE REGISTRATION'
        //         ? createInquiryV1
        //         : createInquiry;

        createInquiryV1(
            CreateInquiryParams,
            getSessionData?.Interaction?.InteractionLine?.InteractionLineId
        )
            .then(() => {
                setLoading(false);
                setUploadStatus('COMPLETED');
            })
            .catch((error: any) => {
                setLoading(false);
                console.log('uploadPurchaseReceipt:', error);
            });
    };

    //Upload File Api

    const UploadPurchaseReceipt = async (fileToUpload: any) => {
        let AgreementId = globalState?.state?.selectedEnrolledDevice?.agreementId ??
            getSessionData?.FindAgreementsResults?.Agreements?.Agreement[0]?.AgreementId;
        const signedUrl: any = await getSignedRequestForUpload(
            AgreementId,
            getSessionData?.Interaction?.InteractionLine?.InteractionLineId,
            'purchase-receipt',
            getMappedClientNameVal(getSessionData?.InitializeResponse?.ClientName?.toLowerCase()),
            globalState?.state?.findAgreementSessionData
        ).catch((error) => {
            console.log(error);
            return;
        });

        const base64Obj = await getBase64(fileToUpload);
        saveGeneratedFileName(signedUrl, fileToUpload);
        const uploadedFile: any = await uploadToS3BucketBySignedURL(
            signedUrl,
            fileToUpload?.type,
            dataURItoBlob(base64Obj)
        ).catch((error) => {
            console.log(error, '= error');

            return;
        });

        if (uploadedFile && uploadedFile?.status === 200 && uploadedFile.statusText === 'OK') {
            const fileName = signedUrl.split('?')?.[0]?.split('/')?.pop();
            const extension = fileToUpload?.name?.split('.').pop();

            const params = {
                FileDownloadRequest: {
                    AgreementId: AgreementId,
                    FileName: `${fileName}.${extension}`,
                    FolderName: 'purchase-receipt',
                },
            };

            const data: any = await getSignedURLForDownload(
                params,
                getSessionData?.Interaction?.InteractionLine?.InteractionLineId,
                globalState?.state?.findAgreementSessionData
            );
            const { bucketName } = data;

            documents.push({
                BucketName: bucketName,
                Path: `${fileName}.${extension}`,
                Type: fileToUpload?.type,
                Status: 'uploaded',
            });
            if (SecondImage) {
                if (documents?.length > 1) {
                    callInquiryRegisterDeviceApi(documents);
                }
            } else {
                callInquiryRegisterDeviceApi(documents);
            }
        }
    };

    const goToDeviceRegister = () => {
        window.history.back();
    };

    return (
        <Box>
            <Box w={{ base: 'full', lg: '80%' }} margin="auto" pt={'50px'}>
                <Stack
                    position={'relative'}
                    w={'full'}
                    h={'calc(100vh - 50px)'}
                    direction={'column'}
                    spacing={2}
                    alignItems={'stretch'}
                >
                    <SubHeader
                        backText={'Back'}
                        allowBackButtonClick={true}
                        showCancelButton={false}
                        showBackButton={true}
                        onCancelClick={onCancelClick}
                    ></SubHeader>

                    <Box
                        id={'myasurion-profile-avatar'}
                        w={{ base: 'full', lg: '80%' }}
                        margin="0 auto"
                        h={'auto'}
                        p={'1rem'}
                        alignContent={'center'}
                    >
                        {!hideUploadBtn && (
                            <Box
                                opacity={isLoading ? '0.4' : '1'}
                                cursor={isLoading ? 'not-allowed' : 'auto'}
                            >
                                <Text mt={'5'} fontSize={{ base: '2xl', lg: '3xl' }}>
                                    <TextScript
                                        text={UploadPurchaseReceiptScripts.header}
                                        translationKey={`${pageName}/${UploadPurchaseReceiptKeys.header}`}
                                    />
                                </Text>

                                <Text mt={'2'} whiteSpace={'pre-line'}>
                                    <TextScript
                                        text={UploadPurchaseReceiptScripts.subHeader}
                                        translationKey={`${pageName}/${UploadPurchaseReceiptKeys.subHeader}`}
                                    />
                                </Text>
                            </Box>
                        )}
                        {!hideUploadBtn && (
                            <Flex
                              opacity={isLoading ? '0.4' : '1'}
                              cursor={isLoading ? 'not-allowed' : 'auto'}
                              pointerEvents={isLoading ? 'none' : 'auto'}
                              flexDir={'row'} pt={2}>
                                {/* <Image src={help} cursor={'pointer'}></Image> */}
                                <Link
                                    cursor={'pointer'}
                                    ml={'2'}
                                    textDecoration={'underline'}
                                    onClick={() => {
                                        onClickPopup();
                                    }}
                                >
                                    <TextScript
                                        text={UploadPurchaseReceiptScripts.Tooltip}
                                        translationKey={`${pageName}/${UploadPurchaseReceiptKeys.FhotoToolTip}`}
                                    />
                                </Link>
                            </Flex>
                        )}
                        {(hideUploadBtn || showSecondImageAsFirst) && (
                            <Box
                                opacity={isLoading ? '0.4' : '1'}
                                cursor={isLoading ? 'not-allowed' : 'auto'}
                            >
                                <Text mt={'5'} fontSize={'3xl'}>
                                    <TextScript
                                        text={UploadPurchaseReceiptScripts.SecondImageHeader}
                                        translationKey={`${pageName}/${UploadPurchaseReceiptKeys.SecondImageHeader}`}
                                    />
                                </Text>

                                <Text fontSize={'3xl'}>
                                    <TextScript
                                        text={UploadPurchaseReceiptScripts.SecondImageHeader1}
                                        translationKey={`${pageName}/${UploadPurchaseReceiptKeys.SecondImageHeader1}`}
                                    />
                                </Text>

                                <Text mt={'2'} fontSize={{ base: '2xl', lg: '20px' }}>
                                    <TextScript
                                        text={UploadPurchaseReceiptScripts.SecondSubheader}
                                        translationKey={`${pageName}/${UploadPurchaseReceiptKeys.SecondSubheader}`}
                                    />
                                </Text>
                            </Box>
                        )}

                        {fileError && (
                            <Text textAlign={'center'} marginX={'auto'} color={'red'}>
                                <TextScript
                                    text={UploadPurchaseReceiptScripts.FileUploadError}
                                    translationKey={`${pageName}/${UploadPurchaseReceiptKeys.FileUploadError}`}
                                />
                            </Text>
                        )}

                        {!hideUploadBtn && (
                            <Flex
                                opacity={isLoading ? '0.4' : '1'}
                                cursor={isLoading ? 'not-allowed' : 'auto'}
                                pointerEvents={isLoading ? 'none' : 'auto'}
                                mt="8"
                                direction={'column'}
                                alignContent={'center'}
                                border={'1px solid #D5D6DA'}
                                p="4"
                                position={'relative'}
                                marginX={'auto'}
                                width={{ base: '100%', lg: '50%' }}
                            >
                                <Box py={5}>
                                    <Image
                                        my={3}
                                        marginX={'auto'}
                                        src={takePhoto}
                                        alt="your model"
                                    />

                                    <Text width={'60%'} textAlign={'center'} marginX={'auto'}>
                                        <TextScript
                                            text={UploadPurchaseReceiptScripts.imageUploadInfo}
                                            translationKey={`${pageName}/${UploadPurchaseReceiptKeys.imageUploadInfo}`}
                                        />
                                    </Text>

                                    <Button
                                        mt={'3'}
                                        w="full"
                                        colorScheme="primary"
                                        cursor={'pointer'}
                                    >
                                        <TextScript
                                            text={UploadPurchaseReceiptScripts.btnPickImage}
                                            translationKey={`${pageName}/${UploadPurchaseReceiptKeys.btnPickImage}`}
                                        />
                                    </Button>
                                </Box>

                                <Input
                                    type="file"
                                    display={'hidden'}
                                    border={'none'}
                                    height={'100%'}
                                    width={'100%'}
                                    cursor={'pointer'}
                                    position={'absolute'}
                                    accept=".png,.jpg,.jpeg,.bmp,.pdf"
                                    //   placeholder={placeHolder}
                                    onChange={(e) => handleFileUpload(e)}
                                    style={{
                                        opacity: 0,
                                        overflow: 'hidden',
                                        zIndex: 999,
                                    }}
                                />
                            </Flex>
                        )}

                        {((selectedFile?.name && previewFile) || showSecondImageAsFirst) && (
                            <Flex
                                opacity={isLoading ? '0.4' : '1'}
                                cursor={isLoading ? 'not-allowed' : 'auto'}
                                pointerEvents={isLoading ? 'none' : 'auto'}
                                columnGap={'2'}
                                mt={'30px'}
                                justifyContent={'center'}
                            >
                                {selectedFile?.name && previewFile && (
                                    <Box>
                                        <Flex>
                                            <Box
                                                border={'1px solid #C8C8C8'}
                                                borderRadius={'8px'}
                                                width={'155px'}
                                                height={'207px'}
                                                overflow={'auto'}
                                            >
                                                <Image
                                                    src={RemoveIcon}
                                                    onClick={() => removeFile()}
                                                    cursor={'pointer'}
                                                />

                                                {pdf ? (
                                                    base64String != '' && (
                                                        <PDF
                                                            pdfClasses={'previewPdf'}
                                                            base64String={base64String}
                                                        />
                                                    )
                                                ) : (
                                                    <img
                                                        width={'155px'}
                                                        height={'207px'}
                                                        src={URL.createObjectURL(previewFile)}
                                                        alt="PurchaseReceipt"
                                                    />
                                                )}
                                            </Box>
                                        </Flex>
                                    </Box>
                                )}

                                {(SecondImage || showSecondImageAsFirst) && (
                                    <Box>
                                        <Flex>
                                            <Box
                                                border={'1px solid #C8C8C8'}
                                                borderRadius={'8px'}
                                                width={'155px'}
                                                height={'207px'}
                                                overflow={'auto'}
                                            >
                                                <Image
                                                    src={RemoveIcon}
                                                    onClick={() => removeSecondFile()}
                                                    cursor={'pointer'}
                                                />

                                                {secondpdf ? (
                                                    base64StringSecond != '' && (
                                                        <PDF
                                                            pdfClasses={'previewPdf'}
                                                            base64String={base64StringSecond}
                                                        />
                                                    )
                                                ) : (
                                                    <img
                                                        width={'155px'}
                                                        height={'207px'}
                                                        src={URL.createObjectURL(previewSecondFile)}
                                                        alt="PurchaseReceipt"
                                                    />
                                                )}
                                            </Box>
                                        </Flex>
                                    </Box>
                                )}

                                {(!SecondImage || showSecondImageAsFirst) && (
                                    <Box
                                        border={'2px solid #C8C8C8'}
                                        borderRadius={'8px'}
                                        borderStyle={'dotted'}
                                        width={'155px'}
                                        height={'207px'}
                                    >
                                        <Input
                                            type="file"
                                            display={'hidden'}
                                            border={'none'}
                                            width={'155px'}
                                            height={'207px'}
                                            cursor={'pointer'}
                                            position={'absolute'}
                                            accept=".png,.jpg,.jpeg,.bmp,.pdf"
                                            //   placeholder={placeHolder}
                                            onChange={
                                                showSecondImageAsFirst
                                                    ? (e) => handleFileUpload(e)
                                                    : (e) => handleFileUploadSecond(e)
                                            }
                                            style={{
                                                opacity: 0,
                                                overflow: 'hidden',
                                                zIndex: 999,
                                            }}
                                        />

                                        <Box mt={'50px'} marginX={'16px'} textAlign={'center'}>
                                            <Image
                                                my={'2'}
                                                marginX={'auto'}
                                                src={PlusIcon}
                                                alt="your model"
                                            />

                                            <Text mx={'auto'}>
                                                <TextScript
                                                    text={
                                                        UploadPurchaseReceiptScripts.SecondImageText
                                                    }
                                                    translationKey={`${pageName}/${UploadPurchaseReceiptKeys.SecondImageText}`}
                                                />
                                            </Text>
                                        </Box>
                                    </Box>
                                )}
                            </Flex>
                        )}

                        <Box width={{ base: 'full', lg: '50%' }} marginX={'auto'}>
                            <Button
                                isLoading={isLoading}
                                mt={'5'}
                                width={'full'}
                                textAlign={'center'}
                                colorScheme="primary"
                                bg={isDisabled ? 'gray.200' : colors.primary}
                                cursor={isDisabled ? 'not-allowed' : 'pointer'}
                                disabled={isDisabled}
                                onClick={() =>
                                    isDisabled
                                        ? undefined
                                        : handleSubmit(selectedFile, selectedSecondFile)
                                }
                            >
                                <TextScript text={'Continue'} translationKey={'Global/Continue'} />
                            </Button>
                        </Box>
                    </Box>
                </Stack>
                <FhotoToolTip
                    closeCancelPopup={setOpenCancelPopup}
                    openCancelPopup={openCancelPopup}
                />
            </Box>
        </Box>
    );
}

export default UploadPurchaseReceipt;
