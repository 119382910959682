import { AccountInfo } from '@MGPD/myasurion-shared/hooks';
import { v4 as uuid } from 'uuid';
import { carrierMap } from '../modules/config/constants';

export const getChatConfig = (accountInfo: AccountInfo) => {
    return {
        appSyncGraphQL: {
            endpoint: import.meta.env.VITE_MYASURION_CHAT_GRAPHQL_ENDPOINT,
            region: import.meta.env.VITE_MYASURION_CHAT_GRAPHQL_REGION,
            authType: import.meta.env.VITE_MYASURION_CHAT_GRAPHQL_AUTH_TYPE,
        },
        amplifyConfig: {
            cognitoIdentityPoolId: import.meta.env.VITE_MYASURION_CHAT_COGNITO_IDENTITY_POOL_ID,
            cognitoRegion: import.meta.env.VITE_MYASURION_CHAT_COGNITO_REGION,
            cognitoUserPoolId: import.meta.env.VITE_MYASURION_CHAT_COGNITO_USER_POOL_ID,
            cognitoUserPoolWebClientId: import.meta.env
                .VITE_MYASURION_CHAT_COGNITO_USER_POOL_WEBCLIENT_ID,
        },
        chat: {
            baseUrl: import.meta.env.VITE_MYASURION_CHAT_BASE_URL,
            baseEndpoint: import.meta.env.VITE_MYASURION_CHAT_BASE_ENDPOINT,
            apiKey: import.meta.env.VITE_MYASURION_CHAT_API_KEY,
            environment: import.meta.env.VITE_MYASURION_CHAT_ENV,
            clientName: import.meta.env.VITE_MYASURION_CHAT_CLIENT_NAME,
            program: import.meta.env.VITE_MYASURION_CHAT_PROGRAM,
            productName: import.meta.env.VITE_MYASURION_CHAT_PRODUCT_NAME,
        },
        userInfo: {
            name: accountInfo?.firstName || 'User',
            mdn: accountInfo?.enrolledMdn,
            visitorId: uuid(),
        },
        ...(import.meta.env.VITE_MYASURION_CHATBOT_BASE_URL && {
            chatBot: {
                baseUrl: import.meta.env.VITE_MYASURION_CHATBOT_BASE_URL,
                env: import.meta.env.VITE_MYASURION_CHATBOT_ENV,
            },
        }),
    };
};

export const checkIsApplLoadDisabled = (carrier: string = ''): boolean => {
    return carrier?.toLowerCase() === carrierMap.STARHUB?.toLowerCase() || carrier?.toLowerCase() === carrierMap.JBHIFI?.toLowerCase() //useFeatureIsOn('disableApplLoad');;
}

export const isJBHifiCarrier = (carrier: string = ''): boolean => {
    return carrier?.toLowerCase() === carrierMap.JBHIFI?.toLowerCase();
}
