import ActionTypes from '../store/actionTypes';
import { useCentralStore } from '../store/appContext';
import { useScrollBlock } from '@MGPD/myasurion-shared/hooks';
import { Chat, ChatButton } from '@MGPD/myasurion-shared/modules/chat';
import { Box, useBoolean, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';

// import { getChatConfig } from '../../utils/config';

interface ChatOverlayProps {
  question?: string;
  onChatClose: () => void;
}

const ChatOverlay = ({ question, onChatClose }: ChatOverlayProps) => {
  // const [accountInfo] = useAccount<AccountInfo>({ profileId: '', userId: '' });
  // const chatConfig = getChatConfig(accountInfo);
  const globalState = useCentralStore();
  
  const pathName = window.location.pathname
  const fixChatIcon = pathName?.includes('payment') || pathName?.includes('terms') || pathName?.includes('datatransfer') || pathName?.includes('reviewsubmission') || pathName?.includes('deviceselection')
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMinimized, setToMinimize] = useBoolean();
  const [blockScroll, allowScroll] = useScrollBlock();
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (isMobile && isOpen) {
      blockScroll();
    } else {
      allowScroll();
      updateChatWindowStatus();
    }
  }, [isMobile, isOpen]);

  // eslint-disable-next-line no-console
  // console.log('chatConfig', chatConfig);

  useEffect(() => {
    if (question) onOpen();
  }, [question]);

  useEffect(() => {
    if (globalState?.state?.openChatWindow) onOpen();
  }, [globalState?.state?.openChatWindow]);

  const updateChatWindowStatus = () => {
    globalState?.dispatch({
      type: ActionTypes.OPEN_CHAT_WINDOW,
      payload: false,
    }); 
  }

  return (
    <>
      {isOpen && (
        <Box
          position="fixed"
          w={{ base: 'full', lg: '350px' }}
          h={{ base: 'full', xs: '800px' }}
          mt={{ base: 'unset', lg: '30px' }}
          top="56px"
          bottom={{ base: 0, lg: 8 }}
          right={{ base: 0, lg: 8 }}
          zIndex="9999"
          borderRadius="lg"
          boxShadow="lg"
          visibility={isMinimized ? 'hidden' : 'visible'}
          bg="white"
        >
          <Chat
            // config={chatConfig}
            onMinimize={() => {
              setToMinimize.toggle();
            }}
            onClose={() => {
              onClose();
              onChatClose();
            }}
            isLoggedIn={true}
            quickQuestion={question}
          />
        </Box>
      )}

      <ChatButton
        cursor="pointer"
        onClick={isMinimized ? setToMinimize.toggle : onOpen}
        styleProps={{
          position: 'fixed',
          bottom: fixChatIcon ? '80px' : 8,
          right: fixChatIcon ? '20px' : 8,
          zIndex: '9999',
          visibility: isMinimized || !isOpen ? 'visible' : 'hidden',
        }}
      />
    </>
  );
};

export default ChatOverlay;