import { camelize } from "./helper";
import features from '../utils/features.json';

export const getFeaturesByProgrammeName = (programmeName: string, features: any) => {
  return features.features.find((feature: any) => {
    return feature[programmeName];
  })[programmeName];
};

export const showTriageOtherIssuesQuestions = (PerilType: string, programmeName: string, features: any) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.Triage?.[PerilType?.replace(' ', '')]?.showOtherIssues
};

export const showTriageMoreDetailsSection = (PerilType: string, programmeName: string, features: any) => {
  console.log('programmeName', programmeName);
  
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.Triage?.[PerilType?.replace(' ', '')]?.triageMoreDetailsSection || false
};

export const showGadgetRepairTriage = (programmeName: string, features: any) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList?.showGadgetRepairTriage || false
};

export const hasDataTransferFeature = (PerilType: string, programmeName: string, features: any) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.DataTransfer?.[PerilType?.replace(' ', '')]?.SupportDataTransfer
};

export const getOEMWarranty = (programmeName: string, features: any) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.OEMWarranty;
};

export const getDeliveryOptions = (PerilType: string, programmeName: string, features: any) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.DeliveryOptions?.[PerilType?.replaceAll(' ', '')];
};

export const hasIncidentTypeSupport = (incidentType: string, programmeName: string, features: any) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  let incident = camelize(incidentType);
  return featureData.featureList.IncidentTypes?.includes(incident);
};

export const isBackOrderPayFirst = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.BackOrderPayFirst;
};

export const callServiceFee = (programmeName: string) => {
  if(programmeName){
    const featureData = getFeaturesByProgrammeName(programmeName, features);
    return featureData.featureList.CallServiceFee;
  }
};

export const getFulfillmentMethodType = (PerilType: string, programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.FulfillmentMethodType?.[PerilType?.replace(' ', '')]?.Type
};

export const hasMultiDeviceSupport = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.hasMultiDeviceSupport;
};

export const hasQAS = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.QAS;
};

export const getAddressFields = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.AddressForm.Fields || undefined;
};

export const hasAddressFieldsSequence = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.AddressForm.hasAddressFieldsSequence;
};

export const getPostalCodeLength = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.PostalCodeLength;
};

export const callShippingMethodv1 = (PerilType: string, programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.ShippingMethodsAPI?.[PerilType?.replace(' ', '')]?.ShippingMethodsAPIv1 || false
};

export const getAlternateMobileLength = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.alternateMobileLength;
};

export const pickUpDeliveryWindow = (pickupTime: string, programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.DeliveryWindow?.PickUp?.[pickupTime] || undefined
};

export const returnDeliveryWindow = (returnTime: string, programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.DeliveryWindow?.Return?.[returnTime] || undefined
};

export const skipReturnSlotSelection = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.DeliveryWindow?.SkipReturnDeliverySelection || false;
};

export const callUpdateDelivery = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.callUpdateDeliveryAPI
};

export const allowImeiDetailsScreen = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.UpdateDevice?.ImeiDetails || false
};

export const apiCallGetMakeModel = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.UpdateDevice?.apiCallGetMakeModel || false
};

export const hasTCAT = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.TCAT;
};

export const checkIsOnlyRefresh = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.isOnlyRefresh || false;
};

export const hideIWHeaderText = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.hideIWHeaderText || false;
};

export const getTimeBySlot = (programmeName: any, time: any, timeSlotType: string) => {
  const _featureData = getFeaturesByProgrammeName(programmeName, features);
  const featureData = _featureData.featureList.DeliveryWindow?.[timeSlotType]
	return Object.keys(featureData).find(key =>
		featureData[key] === time);
}

export const hideIWButton = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList?.hideIWButton || false;
};

export const serialNbrScreenWithDeviceDetails = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.UpdateDevice?.SerialNbrWithDeviceDetail || false
};

export const hasAddRepresentativeFeature = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.hasAddRepresentativeFeature
};

export const hasBrainTreePaymentGateway = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.hasBrainTreePaymentGateway
};

export const showFAQSectionLink = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.showFAQSectionLink
};

export const showFAQSection = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.showFAQSection
};

export const hasSurveyFeature = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.hasSurveyFeature
};

export const hasEditAlternateNumberFeature = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.hasEditAlternateNumberFeature
};

export const getDefaultLanguage = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.defaultLanguage
};

export const getBatteryWarranty = (programmeName: string, features: any) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.batteryWarranty;
};

export const hideTriageQuestions = (PerilType: string, programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.Triage?.[PerilType?.replace(' ', '')]?.hideTriageQuestion
};

export const hideCancelReasons = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.hideCancelReasons || false
};

export const hideWarranty = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.hideWarranty || false
};

export const getStateByCityFeature = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.getStateByCity || false
};

export const showFMIPScript = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.showFMIPScript;
}

export const getPgmDisplayName = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.programmeName;
}

export const getReplaceScript = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.showReplaceScript;
}

export const getInlineChangeAddressFlag = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.showInlineChangeAddress;
}

export const showCaptureImage = (programmeName: string) => {
  if(programmeName) {
    const featureData = getFeaturesByProgrammeName(programmeName, features);
    return featureData.featureList.isImageCapture || false
  }
};

export const jbRepairQuestion = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.jbRepairQuestion || false
};

export const jbDevicesApiV2 = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.callDevicesApiV2 || false
};

export const redirectToRepair = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.redirectToRepair || false
};

export const jbTnCRepairSection = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.jbTnCRepairSection || false
};

export const showDeviceCategoryFromCode = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.showDeviceCategoryFromCode || false;
}
export const getAddDeviceFields = (programmeName: string, category: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.DeviceRegistration?.[category?.replace(' ', '')]?.Fields;
};

export const isBYODFeature = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.isBYOD || false;
};

export const hasCloudcherrySupport = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.hasCloudcherrySupport;
};

export const allowAnchorDeviceRegistration = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData?.featureList?.allowAnchorDeviceRegistration || false;
}

export const hasTnCLink = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.hasTncLink;
};

export const getTncLink = (PerilType: string, programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.TncLinks?.[PerilType?.replaceAll(' ', '')]
};

export const isJBUpgradeFlow = (programmeName: string= '') => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.isJBUpgradeFlow || false
};

export const hasCheckBalanceFeature = (programmeName: string='') => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.hasCheckBalanceFeature || false
};

export const getDeliverySlot = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList.getDeliverySlot || false;
};

export const hasExtendWarrantyFeature = (programmeName: string) => {
  const featureData = getFeaturesByProgrammeName(programmeName, features);
  return featureData.featureList?.UpdateDevice?.extendWarrantyFeature || false;
};
