import { getCarrierByDomain } from '@MGPD/myasurion-shared';
import { Global } from '@emotion/react';

import ApercuBlack from '/fonts/ApercuPro/Apercu-Black-Pro.ttf';
import ApercuBold from '/fonts/ApercuPro/Apercu-Bold-Pro.ttf';
import ApercuLight from '/fonts/ApercuPro/Apercu-Light-Pro.ttf';
import ApercuRegular from '/fonts/ApercuPro/Apercu-Regular-Pro.ttf';
import ApercuMonoLight from '/fonts/ApercuPro/ApercuMono-Light-Pro.ttf';
import ApercuMonoRegular from '/fonts/ApercuPro/ApercuMono-Regular-Pro.ttf';
import LatoBlack from '/fonts/Lato/Lato-Black.ttf';
import LatoBlackItalic from '/fonts/Lato/Lato-BlackItalic.ttf';
import LatoBold from '/fonts/Lato/Lato-Bold.ttf';
import LatoBoldItalic from '/fonts/Lato/Lato-BoldItalic.ttf';
import LatoItalic from '/fonts/Lato/Lato-Italic.ttf';
import LatoLight from '/fonts/Lato/Lato-Light.ttf';
import LatoLightItalic from '/fonts/Lato/Lato-LightItalic.ttf';
import LatoRegular from '/fonts/Lato/Lato-Regular.ttf';
import LatoThin from '/fonts/Lato/Lato-Thin.ttf';
import LatoThinItalic from '/fonts/Lato/Lato-ThinItalic.ttf';
import MaxisBlack from '/fonts/Maxis/Maxis-Black.otf';
import MaxisBold from '/fonts/Maxis/Maxis-Bold.otf';
import MaxisExtraBold from '/fonts/Maxis/Maxis-ExtraBold.otf';
import MaxisLight from '/fonts/Maxis/Maxis-Light.otf';
import MaxisRegular from '/fonts/Maxis/Maxis-Regular.otf';
import MaxisSemiBold from '/fonts/Maxis/Maxis-Semibold.otf';

const getRequiredFont = (carrier: string) => {
    const font = {
        light: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        lightItalic: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        regular: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        bold: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        boldItalic: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        black: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        blackItalic: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        monolight: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        monoregular: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        semibold: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        extrabold: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        italic: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        thin: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
        thinItalic: { url: 'sans-serif', local: 'sans-serif', family: 'sans-serif' },
    };

    if (carrier === 'maxis') {
        font.light = { url: MaxisLight, local: 'Maxis-Light', family: 'Maxis' };
        font.lightItalic = { url: MaxisLight, local: 'Maxis-Light', family: 'Maxis' };
        font.regular = { url: MaxisRegular, local: 'Maxis-Regular', family: 'Maxis' };
        font.bold = { url: MaxisBold, local: 'Maxis-Bold', family: 'Maxis' };
        font.boldItalic = { url: MaxisBold, local: 'Maxis-Bold', family: 'Maxis' };
        font.black = { url: MaxisBlack, local: 'Maxis-Black', family: 'Maxis' };
        font.blackItalic = { url: MaxisBlack, local: 'Maxis-Black', family: 'Maxis' };
        font.semibold = { url: MaxisSemiBold, local: 'Maxis-Semibold', family: 'Maxis' };
        font.extrabold = { url: MaxisExtraBold, local: 'Maxis-Extrabold', family: 'Maxis' };
        font.italic = { url: MaxisRegular, local: 'Maxis-Regular', family: 'Maxis' };
        font.thin = { url: MaxisLight, local: 'Maxis-Light', family: 'Maxis' };
        font.thinItalic = { url: MaxisLight, local: 'Maxis-Light', family: 'Maxis' };
    } else if (carrier === 'starhub') {
        font.light = { url: LatoLight, local: 'Lato-Light', family: 'Lato' };
        font.lightItalic = { url: LatoLightItalic, local: 'Lato-LightItalic', family: 'Lato' };
        font.regular = { url: LatoRegular, local: 'Lato-Regular', family: 'Lato' };
        font.bold = { url: LatoBold, local: 'Lato-Bold', family: 'Lato' };
        font.boldItalic = { url: LatoBoldItalic, local: 'Lato-BoldItalic', family: 'Lato' };
        font.black = { url: LatoBlack, local: 'Lato-Black', family: 'Lato' };
        font.blackItalic = { url: LatoBlackItalic, local: 'Lato-BlackItalic', family: 'Lato' };
        font.semibold = { url: LatoBold, local: 'Lato-Bold', family: 'Lato' };
        font.extrabold = { url: LatoBold, local: 'Lato-Bold', family: 'Lato' };
        font.italic = { url: LatoItalic, local: 'Lato-Italic', family: 'Lato' };
        font.thin = { url: LatoThin, local: 'Lato-Thin', family: 'Lato' };
        font.thinItalic = { url: LatoThinItalic, local: 'Lato-ThinItalic', family: 'Lato' };
    } else {
        font.light = { url: ApercuLight, local: 'Apercu-Light-Pro', family: 'ApercuPro' };
        font.lightItalic = { url: ApercuLight, local: 'Apercu-Light-Pro', family: 'ApercuPro' };
        font.regular = { url: ApercuRegular, local: 'Apercu-Regular-Pro', family: 'ApercuPro' };
        font.bold = { url: ApercuBold, local: 'Apercu-Bold-Pro', family: 'ApercuPro' };
        font.boldItalic = { url: ApercuBold, local: 'Apercu-Bold-Pro', family: 'ApercuPro' };
        font.black = { url: ApercuBlack, local: 'Apercu-Black-Pro', family: 'ApercuPro' };
        font.blackItalic = { url: ApercuBlack, local: 'Apercu-Black-Pro', family: 'ApercuPro' };
        font.monolight = {
            url: ApercuMonoLight,
            local: 'ApercuMono-Light-Pro',
            family: 'ApercuPro Mono',
        };
        font.monoregular = {
            url: ApercuMonoRegular,
            local: 'ApercuMono-Regular-Pro',
            family: 'ApercuPro Mono',
        };
        font.semibold = { url: ApercuBold, local: 'Apercu-Bold-Pro', family: 'ApercuPro' };
        font.extrabold = { url: ApercuBold, local: 'Apercu-Bold-Pro', family: 'ApercuPro' };
        font.italic = { url: ApercuRegular, local: 'Apercu-Regular-Pro', family: 'ApercuPro' };
        font.thin = { url: ApercuLight, local: 'Apercu-Light-Pro', family: 'ApercuPro' };
        font.thinItalic = { url: ApercuLight, local: 'Apercu-Light-Pro', family: 'ApercuPro' };
    }
  }
const Fonts = () => {
    const carrier = getCarrierByDomain();
    const font: any = getRequiredFont(carrier);

    return (
        <Global
            styles={carrier === 'starhub' || carrier === 'jbhifi'? `
            @font-face {
              font-family: ${carrier === 'jbhifi' ? 'Apercu Pro': 'Lato'};
              src: local(${carrier === 'jbhifi' ? 'Apercu Pro': 'Lato'}), url('/fonts/starhub/Lato-Regular.ttf') format('truetype');
              font-display: block;
              font-weight: 300;
            }
            
            * {
              font-family: ${carrier === 'jbhifi' ? 'Apercu Pro': 'Lato'};
            }`
            :
        `@font-face {
        font-family: "${font ? font.light.family : 'sans-serif'}";
        src: local("${font ? font.light.local : 'sans-serif'}"), url(${
                font ? font.light.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 300;
        font-style: normal;
      }

      @font-face {
        font-family: "${font ? font.lightItalic.family : 'sans-serif'}";
        src: local("${font ? font.lightItalic.local : 'sans-serif'}"), url(${
                font ? font.lightItalic.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 300;
        font-style: italic;
      }

      @font-face {
        font-family: "${font ? font.regular.family : 'sans-serif'}";
        src: local("${font ? font.regular.local : 'sans-serif'}"), url(${
                font ? font.regular.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 500;
        font-style: normal;
      }
      
      @font-face {
        font-family: "${font ? font.bold.family : 'sans-serif'}";
        src: local("${font ? font.bold.local : 'sans-serif'}"), url(${
                font ? font.bold.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: "${font ? font.boldItalic.family : 'sans-serif'}";
        src: local("${font ? font.boldItalic.local : 'sans-serif'}"), url(${
                font ? font.boldItalic.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 700;
        font-style: italic;
      }

      @font-face {
        font-family: "${font ? font.black.family : 'sans-serif'}";
        src: local("${font ? font.black.local : 'sans-serif'}"), url(${
                font ? font.black.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 900;
        font-style: normal;
      }

      @font-face {
        font-family: "${font ? font.blackItalic.family : 'sans-serif'}";
        src: local("${font ? font.blackItalic.local : 'sans-serif'}"), url(${
                font ? font.blackItalic.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 900;
        font-style: italic;
      }

      @font-face {
        font-family: "${font ? font.monolight.family : 'sans-serif'}";
        src: local("${font ? font.monolight.local : 'sans-serif'}"), url(${
                font ? font.monolight.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 300;
      }

      @font-face {
        font-family: "${font ? font.monoregular.family : 'ApercuPro Mono'}";
        src: local("${font ? font.monoregular.local : 'ApercuMono-Regular-Pro'}"), url(${
                font ? font.monoregular.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 500;
      }

      @font-face {
        font-family: "${font ? font.semibold.family : 'sans-serif'}";
        src: local("${font ? font.semibold.local : 'sans-serif'}"), url(${
                font ? font.semibold.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: "${font ? font.extrabold.family : 'sans-serif'}";
        src: local("${font ? font.extrabold.local : 'sans-serif'}"), url(${
                font ? font.extrabold.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: "${font ? font.italic.family : 'sans-serif'}";
        src: local("${font ? font.italic.local : 'sans-serif'}"), url(${
                font ? font.italic.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 500;
        font-style: italic;
      }

      @font-face {
        font-family: "${font ? font.thin.family : 'sans-serif'}";
        src: local("${font ? font.thin.local : 'sans-serif'}"), url(${
                font ? font.thin.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 100;
        font-style: normal;
      }

      @font-face {
        font-family: "${font ? font.thinItalic.family : 'sans-serif'}";
        src: local("${font ? font.thinItalic.local : 'sans-serif'}"), url(${
                font ? font.thinItalic.url : 'sans-serif'
            }) format("truetype");
        font-display: block;
        font-weight: 100;
        font-style: italic;
      }
      `}
        />
        
    );
};

export default Fonts;




// @font-face {
  //   font-family: "${font ? font.light.family : 'ApercuPro'}";
  //   src: local("${font ? font.light.local : 'Apercu-Light-Pro'}"), url(${font ? font.light.url : ApercuLight
  //     }) format("truetype");
  //   font-display: block;
  //   font-weight: 300;
  // }

  // @font-face {
  //   font-family: "${font ? font.regular.family : 'ApercuPro'}";
  //   src: local("${font ? font.regular.local : 'Apercu-Regular-Pro'}"), url(${font ? font.regular.url : ApercuRegular
  //     }) format("truetype");
  //   font-display: block;
  //   font-weight: 500;
  // }
  
  // @font-face {
  //   font-family: "${font ? font.bold.family : 'ApercuPro'}";
  //   src: local("${font ? font.bold.local : 'Apercu-Bold-Pro'}"), url(${font ? font.bold.url : ApercuBold
  //     }) format("truetype");
  //   font-display: block;
  //   font-weight: 700;
  // }

  // @font-face {
  //   font-family: "${font ? font.black.family : 'ApercuPro'}";
  //   src: local("${font ? font.black.local : 'Apercu-Black-Pro'}"), url(${font ? font.black.url : ApercuBlack
  //     }) format("truetype");
  //   font-display: block;
  //   font-weight: 900;
  // }

  // @font-face {
  //   font-family: "${font ? font.monolight.family : 'ApercuPro Mono'}";
  //   src: local("${font ? font.monolight.local : 'ApercuMono-Light-Pro'}"), url(${font ? font.monolight.url : ApercuMonoLight
  //     }) format("truetype");
  //   font-display: block;
  //   font-weight: 300;
  // }

  // @font-face {
  //   font-family: "${font ? font.monoregular.family : 'ApercuPro Mono'}";
  //   src: local("${font ? font.monoregular.local : 'ApercuMono-Regular-Pro'}"), url(${font ? font.monoregular.url : ApercuMonoRegular
  //     }) format("truetype");
  //   font-display: block;
  //   font-weight: 500;
  // }