import { Icon, IconProps } from '@chakra-ui/react';
import * as React from 'react';
import { JSX } from 'react/jsx-runtime';

export const PaymentCCIcon = (
  props: JSX.IntrinsicAttributes &
    Omit<React.SVGProps<SVGSVGElement>, 'as' | 'translate' | keyof IconProps> & {
      htmlTranslate?: 'yes' | 'no' | undefined;
    } & IconProps & { as?: 'svg' | undefined }
) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width={'24px'} height={'24px'} fill="none" {...props}>
    <g id="credit-card-visa">
      <g id="Vector">
        <path
          d="M22 3H2C0.897 3 0 3.896 0 5V19C0 20.103 0.897 21 2 21H22C23.104 21 24 20.103 24 19V5C24 3.896 23.104 3 22 3ZM21.997 19H2V5H22L21.997 19Z"
          fill="black"
        />
        <path
          d="M17.333 13.333H18.667V14.667H20V11.333C20 10.229 19.103 9.333 18 9.333C16.897 9.333 16 10.229 16 11.333V13V14.668H17.333V13.333ZM17.333 11.333C17.333 10.966 17.633 10.667 18 10.667C18.368 10.667 18.667 10.966 18.667 11.333V12H17.333V11.333Z"
          fill="black"
        />
        <path
          d="M10 10.667V13.333H9.333V14.667H14.333C15.253 14.667 16 13.918 16 13C16 12.082 15.253 11.334 14.333 11.334H13.667C13.482 11.334 13.333 11.186 13.333 11C13.333 10.816 13.482 10.668 13.667 10.668H16V9.333H13.667C12.748 9.333 12 10.082 12 11C12 11.919 12.748 12.668 13.667 12.668H14.333C14.519 12.668 14.667 12.816 14.667 13C14.667 13.186 14.519 13.334 14.333 13.334H11.333V10.668H12V9.333H9.333V10.667H10Z"
          fill="black"
        />
        <path
          d="M6.642 14.667H8.014L8 14.613L9.333 9.333H7.962L7.324 11.936L6.667 9.333H4V10.667H5.661L6.642 14.667Z"
          fill="black"
        />
      </g>
    </g>
  </Icon>
);
export default PaymentCCIcon;
